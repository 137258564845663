// cookieHandler.ts

// Explicitly typing the function parameter and return type
export function getCookie(cookieName: string): string | null {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        console.log("Checking cookie:", cookie.split('=')[0]);  // Log each cookie name
        if (cookie.startsWith(cookieName + '=')) {
            return cookie.substring(cookieName.length + 1);
        }
    }
    
    return null;
}


// Typing the function parameter
export function updateMktoCookieExpiration(newExpirationDays: number) {
    console.log("Updating MKTO cookie expiration");
    const cookieName = "_mkto_trk";
    console.log("Current Cookies (before update):", document.cookie); // Log current cookies
  
    const existingCookie = getCookie(cookieName);
    console.log("Existing Cookie:", existingCookie); // Log the specific cookie
    
    if (existingCookie) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + newExpirationDays);

      console.log("New Expiration Date:", currentDate.toUTCString());
      
      document.cookie = `${cookieName}=${existingCookie}; expires=${currentDate.toUTCString()}; path=/; domain=.microsoft.com`;
  
      console.log("Current Cookies (after update):", document.cookie); // Log current cookies again
    }
  }
  
