import { Tour, PageViewBody, TrialClickInfo } from '../types/schema.type'
import { initializeOneDS } from '../common/oneds/oneds'

export const captureTrialClick = (selectedTourTitle: string, clickInfo: TrialClickInfo) => {

    let body: PageViewBody = {
        contentId: clickInfo.clickName,
        contentName: clickInfo.clickTitle,
        sceneStep: clickInfo.stepNumber,
        scene: clickInfo.sectionName,
        productTitle: selectedTourTitle,
        productID: "",
        productSKU: ""
    };

    console.log("capture result: " + capturePageView(body));    
}


export const capturePageView = (bodyData : PageViewBody) : boolean  => {

    const analytics = initializeOneDS();

    const body = {
        behavior: 20,
        content: [
            {
                "contentId": bodyData.contentId,
                "contentName": bodyData.contentName,
                "scnstp": bodyData.sceneStep,
                "scn": bodyData.scene,
            }
        ],
        pageTags: {
            metatags: {
                ProductInfo: {
                    "title": bodyData.productTitle, //Replace by Product Name/Title
                    "id": bodyData.productID, //Replace by Product Id
                    "sku": bodyData.productSKU   //Replace by Sku Id. If not present, keep blank
                }
            }
        }
    };

    try {
        analytics.capturePageView(body);
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}
