import React, { useState, useEffect } from 'react';
import { IAppState } from 'types/appState.type';
import { GetJsllIdPrefix } from 'utils/jsllTaggingUtils';
import { Redirect } from 'react-router';
import { Collection, TourSection, TourStep, Tour } from 'types/schema.type';
import {
	renderBulletListToListItem,
	renderMultilineToParagraphs,
	needsStepDetailsSection,
	canNavigatePrevious,
	canAdvanceNext,
} from 'utils/renderHelpers';
import { ITourNavigation } from 'types/tour.type';
import {
	Breadcrumb,
	IBreadcrumbStyles,
	IBreadcrumbItem,
	DefaultButton,
	Dropdown,
	Icon,
	IDropdownOption,
	IDropdownStyles,
	PrimaryButton,
	IDividerAsProps,
	TooltipHost,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import styles from './LeftPanel.module.scss';


export interface ILeftPanelDispatch {
	setTour(selectedTour: string): void;
	setStep(selectedStep: TourStep): void;
	setSection(selectedSection: TourSection): void;
}

export interface ILeftPanelPropsWithDispatch
	extends ILeftPanelDispatch,
		ITourNavigation,
		IAppState {}

const dropdownStyles: Partial<IDropdownStyles> = {
	dropdown: { width: 300 },
};

const breadcrumbStyles: Partial<IBreadcrumbStyles> = {
	root: { marginTop: 0 },
};



export const LeftPanel = (props: ILeftPanelPropsWithDispatch) => {
	const {
		projectState,
		collectionState,
		tourState,
		sectionState,
		stepState,
		buttonsState,
		localeState,
	} = props;

	const { project } = projectState;
	const { fullscreenMode, nextButton } = buttonsState.currentState;
	const { selectedSection } = sectionState;
	const { selectedStep } = stepState;
	const { selectedLocale } = localeState;
	const [redirect, setRedirect] = useState(false);

	const selectedCollection = collectionState.selectedCollection
		? collectionState.selectedCollection
		: new Collection();

	const selectedTour = tourState.selectedTour
		? tourState.selectedTour
		: new Tour();

	const selectedTourId = selectedTour.id;
	const collectionNamespace = `collections/${selectedCollection.id}`;

	let nameSpaces = [projectNamespace, collectionNamespace];

	let tourNamespace = '';
	if (selectedTour) {
		tourNamespace = `tours/${selectedTourId}`;
		nameSpaces.push(tourNamespace);
	}

	useEffect(() => {
		setRedirect(false);
	}, [redirect]);

	const { t } = useTranslation(nameSpaces);
	const jsllIdPrefix = GetJsllIdPrefix(props, t);

	const generateDropdownOptions = () => {
		let ddSteps: IDropdownOption[] = [];

		selectedTour!.sections.map((sections) =>
			ddSteps.push({
				key: sections.sectionNumber,
				text: t(`${tourNamespace}:${sections.sectionName}`),
			})
		);

		return ddSteps;
	};

	const returnToLanding = () => {
		setRedirect(true);
	};

	if (redirect && selectedCollection !== null) {
		return (
			<Redirect
				push
				to={{
					pathname: `/${selectedLocale}/guidedtour/${selectedCollection.id}/landing`,
				}}
			/>
		);
	}

	const generateBreadcrumbItems = () => {
		let items: IBreadcrumbItem[] = [];

		items.push({
			text: t(`collections/${selectedCollection.id}:${selectedCollection.title}`),
			key: 's0',
			onClick: () => {
				returnToLanding();
			},
		});

		items.push({
			text: t(`${tourNamespace}:${selectedTour!.title}`),
			key: 's1',
			isCurrentItem: true,
			onClick: () => {
				//Grab the initial section for the current tour.
				let firstSection = selectedTour!.sections[0];

				props.updateHistoryForNavigationState(firstSection.sectionNumber, 1);
			},
		});

		return items;
	};

	return (
		<>
			{!fullscreenMode && (
				<div className={styles.leftPane}>
					<Breadcrumb
						id={jsllIdPrefix + '-breadCrumbs'}
						className={styles.breadcrumbs}
						overflowAriaLabel="Breadcrumb button"
						overflowIndex={1}
						ariaLabel="Breadcrumb"
						items={generateBreadcrumbItems()}
						dividerAs={getCustomDivider}
						onRenderOverflowIcon={getCustomOverflowIcon}
						styles={breadcrumbStyles}
					/>
					<Dropdown
						id={jsllIdPrefix + '-title'}
						placeholder="Guided Tour Section Menu"
						aria-label="Dropdown"
						options={generateDropdownOptions()}
						styles={dropdownStyles}
						selectedKey={selectedSection.sectionNumber}
						data-bi-name={'SectionSelector'}
						onChange={(
							item: React.FormEvent<HTMLDivElement>,
							key: IDropdownOption | undefined
						) => {
							if (key !== undefined) {
								let option: number = key.key as number;

								var selectedSection = selectedTour!.sections[option - 1];
								props.updateHistoryForNavigationState(
									selectedSection.sectionNumber,
									1
								);
							}
						}}
					/>
					<main>
						<div>
							<p className={styles.stepTitle}>
								{t(`${tourNamespace}:${selectedSection.sectionName}`)}
							</p>

							<p>
								Step {selectedStep.stepNumber} of {selectedSection.stepCount}
							</p>

							{needsStepDetailsSection(props) && (
								<div className={styles.stepDetails}>
									{selectedStep.stepDetails.length > 0 &&
										renderMultilineToParagraphs(
											selectedStep.stepDetails,
											t,
											tourNamespace
										)}
									{selectedStep.stepList && (
										<ul className={styles.stepList}>
											{renderBulletListToListItem(
												selectedStep.stepList,
												t,
												tourNamespace
											)}
										</ul>
									)}
								</div>
							)}
							{selectedStep.stepInstructions && (
								<p className={styles.stepInstructions}>
									{t(`${tourNamespace}:${selectedStep.stepInstructions}`)}
								</p>
							)}
						</div>
					</main>
					<div ref={nextButton} id="leftPanelButtons">
						{canNavigatePrevious(props) ? (
							<DefaultButton
								id={jsllIdPrefix + '-leftNavPrev'}
								className={styles.prevButton}
								aria-label={t(project.stepNavLabelPrev)}
								text={'< ' + t(project.previousNavLabel)}
								data-bi-name={'PrevButton'}
								onClick={() => {
									props.decrementStep();
								}}
							/>
						) : (
							<></>
						)}
						{canAdvanceNext(props) ? (
							<PrimaryButton
								id={jsllIdPrefix + '-leftNavNext'}
								className={styles.nextButton}
								aria-label={t(project.stepNavLabelNext)}
								text={t(project.nextNavLabel) + ' >'}
								data-bi-name={'NextButton'}
								onClick={() => {
									props.incrementStep();
								}}
							/>
						) : (
							<></>
						)}

						
					</div>
				</div>
			)}
		</>
	);
};

function getCustomOverflowIcon(): JSX.Element {
	return <Icon iconName={'ChevronDown'} />;
}

function getCustomDivider(dividerProps: IDividerAsProps): JSX.Element {
	const tooltipText = dividerProps.item ? dividerProps.item.text : '';
	return (
		<TooltipHost
			content={`Show ${tooltipText} contents`}
			calloutProps={{ gapSpace: 0 }}
		>
			<span aria-hidden="true" style={{ cursor: 'pointer', padding: 5 }}>
				/
			</span>
		</TooltipHost>
	);
}
