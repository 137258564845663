import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Collection } from 'types/schema.type';
import { createAction } from 'typesafe-actions';
import { DataClient } from 'utils/dataClient';

export const setCollection = createAction('setCollection', (action) => {
  return (collection: Collection | null) => action({ collection });
});

export const setSecondaryCollection = createAction(
	'setSecondaryCollection',
	(action) => {
    return (collection: Collection | null) => action({ collection });
	}
);

export const loadCollectionStart = createAction(
  'loadCollection/start',
  (action) => {
    return (collectionId: string) => action({ collectionId });
  }
);

export const loadCollectionFail = createAction(
  'loadCollection/Fail',
  (resolve: any) => {
    return (error: { collectionId: string; e: any }) => {
      return resolve(error);
    };
  }
);

export const loadCollectionSuccess = createAction(
  'loadCollection/Success',
  (resolve: any) => {
    // parse results
		return (results: {
			collectionId: string;
			result: string;
			secondary: boolean;
		}) =>
      resolve({
        collectionId: results.collectionId,
          result: JSON.parse(results.result),
				secondary: results.secondary,
      });
  }
);

export const loadCollection: ActionCreator<
	ThunkAction<Promise<any>, any, void, any>
> =
	(collectionId: string, secondary: boolean) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
  try {
    // API call
    const client = new DataClient();
    dispatch(loadCollectionStart(collectionId));
    const result: any = await client.getFile(collectionId, 'collection');
			return dispatch(
				loadCollectionSuccess({ collectionId, result, secondary })
			);
  } catch (e) {
    return dispatch(loadCollectionFail({ collectionId, e }));
  }
};
