import { connect } from 'react-redux';
import { CollectionLoadingComponent } from './CollectionLoadingComponent';
import { Dispatch } from 'react';

import {
	ICollectionLoaderDispatchProps,
	ICollectionLoaderProps,
} from '../../types/collection.type';
import { IAppState } from '../../types/appState.type';
import { loadCollection, setCollection } from '../../actions/collection.action';
import { Collection } from '../../types/schema.type';

const mapStateToProps = (
	state: IAppState,
	props: ICollectionLoaderProps
): IAppState => {
	return {
		...state,
		...props,
	};
};

const mapDispatchToProps = (
	dispatch: Dispatch<any>
): ICollectionLoaderDispatchProps => {
	return {
		loadCollection: (collectionId: string, secondary: boolean) => {
			dispatch(loadCollection(collectionId, secondary));
		},
		setCollection: (collection: Collection | null) => {
			dispatch(setCollection(collection));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CollectionLoadingComponent);
