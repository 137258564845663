// Initialize Munchkin Telemetry for app
// Ensure cookie consent is available.
// Refer - https://developers.marketo.com/javascript-api/lead-tracking/#embedding_the_code
export function initializeMunchkin() {
	var didInit = false;

	function initMunchkin() {
		if (didInit === false) {
			didInit = true;
			// Modify the Munchkin initialization to set the cookie expiration (e.g., 364 days).
			Munchkin.init('157-GQE-382', { anonymizeIP: true, cookieLifetime: 364 });
		}
	}

	var s = document.createElement('script');
	s.type = 'text/javascript';
	s.async = true;
	s.src = '//munchkin.marketo.net/munchkin.js';
	(s as any).onreadystatechange = function () {
		if (
			(this as any).readyState === 'complete' ||
			(this as any).readyState === 'loaded'
		) {
			initMunchkin();
		}
	};

	s.onload = initMunchkin;

	document.getElementsByTagName('head')[0].appendChild(s);
}

