import React, { useState, useEffect } from 'react';
import { ICallToActionOption } from 'types/telemetry.type';
import { appInsights } from 'store/appStore';
import ModalComponent from 'components/common/ModalComponent';
import { IAppState } from 'types/appState.type';
import { Redirect } from 'react-router';
import { Tour, TrialClickInfo } from 'types/schema.type';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import { getNativeLongFormState } from 'utils/renderHelpers';
import { captureTrialClick } from '../../utils/capturePageView';

export interface IPopupProps {
	setShowCallToAction(showCallToAction: boolean): void;
	setIsLanding(isLanding: boolean): void;
	setHideButtonUIMode(hideButtonUI: boolean): void;
	setFullscreenMode(fullscreenMode: boolean): void;
	clearTour(): void;
}

export interface IPopupWithDispatch extends IPopupProps, IAppState {}

export const PopupComponent = (props: IPopupWithDispatch) => {
	const {
		sectionState,
		stepState,
		collectionState,
		projectState,
		localeState,
		tourState,
		router,
		history,
	} = props;
	const { selectedTourId, overrideCallback, overrideCallbackText } = tourState;
	const selectedTour = tourState.selectedTour
		? tourState.selectedTour
		: new Tour();

	const { selectedLocale, isRTLCulture } = localeState;
	const { selectedCollection } = collectionState;
	const { showCallToAction } = projectState;
	const selectedSectionNumber = sectionState.selectedSection.sectionNumber;
	const selectedStepNumber = stepState.selectedStep.stepNumber;

	const isNativeLongForm = getNativeLongFormState(props);

	const namespaceString = isNativeLongForm ? 'tours' : 'scenarios';
	const tourNamespace = `${namespaceString}/${selectedTourId}`;
	const { t } = useTranslation([projectNamespace, tourNamespace]);

	let location = router.location || { pathname: '' };

	const [prevRoute, setRoute] = useState('');
	const [gotoDemoEnd, setGotoDemoEnd] = useState(false);
	const [gotoCollection, setGotoCollection] = useState(false);
	const [gotoDemoEndRedirect, setGotoDemoEndRedirect] = useState(false);

	useEffect(() => {
		if (prevRoute !== location.pathname) {
			setRoute(location.pathname);
		}
	}, [prevRoute, location]);

	const clearFlagsForReset = () => {
		props.setIsLanding(true);
		props.setHideButtonUIMode(false);
		props.setFullscreenMode(false);
	};

	const callToAction = (ctaOptions: Partial<ICallToActionOption>) => {
		appInsights.trackEvent({
			name: 'Call to Action',
			properties: {
				...ctaOptions,
				selectedLocale,
				selectedTourId,
				selectedSectionNumber,
				selectedStepNumber,
			},
		});

		props.setShowCallToAction(false);

		if (ctaOptions.link === 'landing') {
			props.clearTour();
			clearFlagsForReset();

			history.push(
				`/${selectedLocale}/guidedtour/${selectedCollection!.id}/landing/`
			);
			return;
		}

		window.open(ctaOptions.link, '_blank');
	};

	const closeDialog = (dismissMessage: string, properties?: object): void => {
		props.setShowCallToAction(false);
		clearFlagsForReset();

		if (selectedCollection && selectedCollection.disableDemoEnd) {
			if (selectedCollection.tourEndRedirect) {
				setGotoDemoEndRedirect(true);
			} else {
				setGotoCollection(true);
			}
		} else {
			setGotoDemoEnd(true);
		}
		appInsights.trackEvent({
			name: dismissMessage,
			properties,
		});
	};

	if (location.pathname !== prevRoute) {
		appInsights.trackEvent({
			name: 'Page',
			properties: {
				selectedSectionNumber,
				selectedStepNumber,
				selectedTourId,
				selectedLocale,
			},
		});
	}

	const primaryButtonId = "modal-primary";

	let primaryText = overrideCallbackText
		? overrideCallbackText
		: t(`${tourNamespace}:${selectedTour.callToActionModal.primary}`);

	const endCta = showCallToAction && (
		<ModalComponent
			closeModal={() => {
				closeDialog('Dismiss Call to Action (endScenario)', {
					selectedTourId,
				});
			}}
			title={t(`${tourNamespace}:${selectedTour.callToActionModal.title}`)}
			description={t(
				`${tourNamespace}:${selectedTour.callToActionModal.description}`
			)}
			primaryButtonId={primaryButtonId}
			primaryButtonText={primaryText}
			primaryClick={() => {
				const actionUrl = overrideCallback
					? overrideCallback
					: selectedTour.callToActionModal.primaryAction;

				if (selectedTour.captureTrialClick) {

					const sectionName = 'tourEndCTA';

					const selectedTourTitle = t(
						`${namespaceString}/${selectedTourId}:${selectedTour.title}`
					);

					let trialClickInfo: TrialClickInfo = {
						clickTitle: primaryText,
						clickName: primaryButtonId,
						stepNumber: "0",
						sectionNumber: "0",
						sectionName: sectionName
					};

					captureTrialClick(selectedTourTitle, trialClickInfo);
				}

				callToAction({
					name: 'scenarioEnd',
					link: actionUrl,
					selectedLocale,
					selectedTourId,
					selectedSectionNumber,
					selectedStepNumber,
				});
			}}
			secondaryButtonText={t(
				`${tourNamespace}:${selectedTour.callToActionModal.secondary}`
			)}
			secondaryClick={() =>
				callToAction({
					name: 'scenarioEndSecondary',
					link: selectedTour.callToActionModal.secondaryAction,
					selectedLocale,
					selectedTourId,
					selectedSectionNumber,
					selectedStepNumber,
				})
			}
			isRTLCulture={isRTLCulture}
		/>
	);

	if (gotoDemoEndRedirect) {
		(window.location as any) = selectedCollection!.tourEndRedirect || '';
		return <></>;
	}

	if (gotoDemoEnd) {
		return (
			<Redirect
				push
				to={`/${selectedLocale}/guidedtour/${
					selectedCollection!.id
				}/demo-end/${selectedTourId}`}
			/>
		);
	}

	if (gotoCollection) {
		return (
			<Redirect
				push
				to={`/${selectedLocale}/guidedtour/${selectedCollection!.id}/landing`}
			/>
		);
	}

	return endCta || null;
};
