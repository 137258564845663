import { Collection, ProjectDto } from 'types/schema.type';

export const IsCollectionLoadedFor = (
  id: string,
  collections: Collection[]
): boolean => {
  for (const collection of collections) {
    if (collection.id.toLowerCase() === id.toLowerCase()) {
      return collection.loaded;
    }
  }
  return false;
};

export const mapProject = (projectDto: ProjectDto): Collection[] => {
    const mappedCollections = [];
    for (const collectionId of projectDto.collectionIds) {
        let newCollection = new Collection();
        newCollection.id = collectionId.toLowerCase();
        mappedCollections.push(newCollection);
    }

    return mappedCollections;
};

export const mapSecondaryCollections = (
	collection: Collection
): Collection[] => {
    const mappedCollections = [];
    for (const collectionId of collection.collectionIds) {
        let newCollection = new Collection();
        newCollection.id = collectionId.toLowerCase();
        mappedCollections.push(newCollection);
    }

    return mappedCollections;
};
export const buildCollection = (payload: any): Collection => {
    const newCollection: Collection = payload;
    newCollection.id = newCollection.id.toLowerCase();

    if (!newCollection.isCollectionCollection) {
		newCollection.tourIds = newCollection.tourIds.map((s) => s.toLowerCase());
	} else {
		newCollection.collectionIds = newCollection.collectionIds.map((c) =>
			c.toLowerCase()
        );
    }

    newCollection.loaded = true;
    return newCollection;
};
