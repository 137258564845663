// Initialize OneDS for app
// Ensure cookie consent is available.
export const initializeOneDS = () => {
	if (!window.oneDS) return;

	// 1DS initialization
	const analytics = new window.oneDS.ApplicationInsights();
	const config = {
		instrumentationKey:
			'b95b9ae6881a445cbd0e6679b20d3e72-6e0f84ca-00f8-4b54-b997-73af26b21d66-7630',
		webAnalyticsConfiguration: {
			// Web Analytics Plugin configuration
			autoCapture: {
				scroll: true,
				pageView: true,
				onLoad: true,
				onUnload: true,
				click: true,
				resize: true,
				jsError: true,
			},
		},
	};

	//Initialize SDK
	analytics.initialize(config, []);

	return analytics
}