import { IPropsWithChildren, IAppState } from './appState.type';
import { Collection } from './schema.type';
import { ITourLandingDispatch } from './tour.type';

export interface ICollectionState {
    selectedCollection: Collection | null;
    secondaryCollection: Collection | null;
    isLoading: boolean;
    loadingError: boolean;
    loadingCollections: string[];
    collections: Collection[];
    secondaryCollections: Collection[];
}

export const initCollectionState: ICollectionState = {
    selectedCollection: null,
    secondaryCollection: null,
    isLoading: false,
    loadingError: false,
    loadingCollections: [],
    collections: [],
    secondaryCollections : [],
};

export interface ICollectionDispatch {
    setCollection(collection: Collection | null): void;
    setIsLanding(isLanding: boolean): void;
    setTour(selectedTour: string): void;
}

export interface ICollectionAndTourDispatch extends ICollectionDispatch {
    setSecondaryCollection(collection: Collection | null): void;
}

export interface ICollectionLandingInlineProps {
	isNativeLongForm: boolean;
}

export interface ICollectionLandingProps
    extends ICollectionLandingInlineProps,
    IAppState { }

export interface ICollectionStateWithDispatch
    extends ICollectionDispatch,
    ICollectionLandingProps { }


export interface ICollectionAndTourStateWithDispatch
    extends ICollectionAndTourDispatch,
    ITourLandingDispatch,
    ICollectionLandingProps { }

export interface ICollectionLoaderProps extends ICollectionLandingInlineProps {
    collectionId: string;
    locale: string;
    isBatchLoading: boolean;
}

export interface ICollectionLoaderDispatchProps {
    loadCollection(collectionId: string, secondary: boolean): void;
    setCollection(collection: Collection): void;
}

export interface ICollectionLoaderStateWithDispatch
    extends IPropsWithChildren,
    ICollectionLoaderProps,
    ICollectionLoaderDispatchProps,
    IAppState { }


