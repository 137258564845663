import { getType } from 'typesafe-actions';
import { IStepState, initStepState } from 'types/step.type';
import { setStep } from 'actions/step.action';
import { setSection } from 'actions/section.action';
import { TourStep } from 'types/schema.type';
import { setTour } from 'actions/tour.action';

export default (state: IStepState = initStepState, action: any): IStepState => {
  switch (action.type) {
		case getType(setSection): {
              return {
                  ...state,
                  selectedStep: new TourStep(),
              };
          }
		case getType(setTour): {
              return {
                  ...state,
                  selectedStep: new TourStep(),
              };
          }
		case getType(setStep): {
              return {
                  ...state,
                  selectedStep: action.payload.selectedStep,
              };
          }
  }
  return state;
};
