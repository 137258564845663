import { TFunction } from 'i18next';
import { IAppState } from 'types/appState.type';
import { getNativeLongFormState, fixTourTitle } from './renderHelpers';

export const GetJsllIdPrefix = (
	appState: IAppState,
	translate?: TFunction
): string => {
    const { tourState, sectionState, stepState, projectState } = appState;
    const { selectedTour, selectedTourId } = tourState;
    const selectedSection = sectionState.selectedSection;
    const selectedStep = stepState.selectedStep;
    const isNativeLongForm = getNativeLongFormState(appState);

    let namespaceFolder = 'scenarios';
    if (isNativeLongForm) { 
        namespaceFolder = 'tours';
    }

    const tourNamespace = `${namespaceFolder}/${selectedTourId}`;

    let jsllFriendlyTitle = '';
    if (selectedTour && translate) {
        //Make sure you remove spaces from title string (if any exist)
		jsllFriendlyTitle = fixTourTitle(translate(`${tourNamespace}:${selectedTour.title}`));
    }
    
    return `${jsllFriendlyTitle}-tsk${selectedSection.sectionNumber}-stp${selectedStep.stepNumber}`;
};
