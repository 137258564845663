import React from 'react';
import { Redirect } from 'react-router';

const scenarioMapper = [
	{
		old: 'intelligent-sales-and-marketing',
		new: 'Activate-Digital-Selling',
	},
];

export const ShouldRedirect = (scenarioId: string): boolean => {
	for (const map of scenarioMapper) {
		if (scenarioId.toLowerCase() === map.old.toLowerCase()) {
			return true;
		}
	}
	return false;
};

export const LegacyScenarioIdTransform = (scenarioId: string): string => {
	let scenario = scenarioId;

	for (const map of scenarioMapper) {
		if (scenarioId.toLowerCase() === map.old.toLowerCase()) {
			scenario = map.new.toLowerCase();
		}
	}

	return scenario;
};

export const ScenarioRedirect = (props: {
	selectedLocale: string;
	collectionId: string;
	scenarioId: string;
	isDemoEnd: boolean;
	query: string;
	taskId?: number;
	stepId?: number;
}) => {
	const newScenarioId = LegacyScenarioIdTransform(props.scenarioId);

	return props.isDemoEnd ? (
		<Redirect
			to={`/${props.selectedLocale}/guidedtour/${props.collectionId}/demo-end/${newScenarioId}/1${props.query}`}
			push={true}
		/>
	) : (
		<Redirect
			to={`/${props.selectedLocale}/guidedtour/${props.collectionId}/${newScenarioId}/${props.taskId}/${props.stepId}${props.query}`}
			push={true}
		/>
	);
};

export default ScenarioRedirect;
