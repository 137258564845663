import { createAction } from 'typesafe-actions';

export const setFullscreenMode = createAction('setFullscreenMode', (action) => {
    return (fullscreenModeState: boolean) => action({ fullscreenModeState });
});

export const setHiddenButtonMode = createAction(
	'setHiddenButtonMode',
	(action) => {
    return (hiddenModeState: boolean) => action({ hiddenModeState });
	}
);

export const setCoachmarkClickedMode = createAction(
	'setCoachmarkClickedMode',
	(action) => {
    return (coachmarkClicked: boolean) => action({ coachmarkClicked });
	}
);

export const setHideButtonUIMode = createAction(
	'setHideButtonUIMode',
	(action) => {
    return (hideButtonUI: boolean) => action({ hideButtonUI });
	}
);
