import React, { useEffect } from 'react';
//import { css, StyleSheet } from 'aphrodite/no-important';
import { IAppState } from 'types/appState.type';
import { AnotherTourComponent } from '../selectAnotherTour/AnotherTourComponent';
import { TourEndCtaComponent } from '../secondaryCta/TourEndCtaComponent';
import { TourEndStoryComponent } from '../storyCta/TourEndStoryComponent';
import ProjectComponentContainer from 'components/project/ProjectComponentContainer';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import LocaleComponentContainer from 'components/locale/LocaleComponentContainer';
import CollectionLoadingComponentContainer from 'components/collectionLoading/CollectionLoadingComponentContainer';
import { TourLoadingComponent } from 'components/tourLoading/TourLoadingComponent';
import { ITourEndDispatch } from 'types/tour.type';
import ContactRightRailComponentContainer  from 'components/common/ContactRightRailComponentContainer';
import ScenarioRedirect, {
	ShouldRedirect,
} from 'components/shortComponents/scenarioRedirect/ScenarioRedirect';

import styles from './TourEnd.module.scss';
import { getNativeLongFormState } from '../../../utils/renderHelpers';

export interface ITourEndStateWithDispatch
	extends ITourEndDispatch,
		IAppState {}

//const style = StyleSheet.create({
//    demoEnd: {
//        height: '100vh',
//        display: 'flex',
//        flexDirection: 'column',
//        alignItems: 'stretch',
//        justifyContent: 'flex-start',
//        width: '100vw',
//        overflowY: 'auto',
//        overflowX: 'hidden',
//    },
//});

export const TourEndComponent = (props: ITourEndStateWithDispatch) => {
	const {
		projectState,
		tourState,
		collectionState,
		match,
		localeState,
		setTour,
		setHeaderDarkMode,
	} = props;

	const { darkMode } = projectState;

	const { project, projectLoaded, isLanding } = projectState;
	const { selectedCollection } = collectionState;
	const { tours, selectedTour, selectedTourId } = tourState;
	const { localeHexCode, isRTLCulture, fallbackLocale } = localeState;
	const isNativeLongForm = getNativeLongFormState(props);

	const namespaceString = isNativeLongForm ? 'tours' : 'scenarios';

	useEffect(() => {
		if (isLanding) {
			props.setIsLanding(false);
		}
	}, [isLanding, props]);

	const collectionId = match.params.collectionId
		? match.params.collectionId.toLowerCase()
		: '';
	const selectedLocale = match.params.collectionId
		? match.params.selectedLocale.toLowerCase()
		: '';
	const tourId = match.params.tourId ? match.params.tourId.toLowerCase() : '';
	let tourEndTourId = selectedTourId ? selectedTourId : tourId;

	const DynamicsCollectionID = 'dynamics';

	useEffect(() => {
		if (!selectedTour) {
			// console.log('setting to ' + scenarioId);
			setTour(tourEndTourId);
		}

		return () => {
			// console.log('scenario', scenarioState);
			// console.log('task', taskState);
		};
	}, [selectedTour, setTour, tourEndTourId]);

	useEffect(() => {
		if (!darkMode) {
			setHeaderDarkMode(true);
		}

		return () => {
			setHeaderDarkMode(false);
		};
	}, []);

	const { t } = useTranslation([
		projectNamespace,
		selectedTourId ? `${namespaceString}/${tourEndTourId}` : '',
	]);

	if (ShouldRedirect(tourEndTourId)) {
		return (
			<ScenarioRedirect
				query={document.location.search}
				selectedLocale={selectedLocale}
				collectionId={collectionId}
				scenarioId={tourEndTourId}
				isDemoEnd={true}
			/>
		);
	}

	if (!localeState.selectedLocale || localeState.isLoading) {
		return (
			<LocaleComponentContainer
				locale={selectedLocale}
			></LocaleComponentContainer>
		);
	}

	if (!projectLoaded) {
		return (
			<ProjectComponentContainer
				locale={selectedLocale}
			></ProjectComponentContainer>
		);
	}

	if (
		!selectedCollection ||
		selectedCollection.id !== collectionId ||
		selectedCollection.title.length == 0
	) {
		return (
			<CollectionLoadingComponentContainer
				collectionId={collectionId}
				locale={selectedLocale}
				isNativeLongForm={isNativeLongForm}
				isBatchLoading={false}
			></CollectionLoadingComponentContainer>
		);
	}

	if (!selectedTour || selectedTour.id !== tourEndTourId) {
		return (
			<TourLoadingComponent
				tourId={tourEndTourId}
				tourState={tourState}
				set={true}
				isNativeLongForm={isNativeLongForm}
				loadTour={() => {
					props.loadTour(tourEndTourId, isNativeLongForm, localeHexCode);
				}}
				setTour={(tourId: string) => {
					props.setTour(tourId);
				}}
				localeHexCode={localeHexCode}
			></TourLoadingComponent>
		);
	}

	const notYetLoadedTours: string[] = selectedCollection.tourIds.filter(
		(id) => tours.findIndex((t) => t.id === id && t.loaded && !t.loading) < 0
	);

	if (notYetLoadedTours.length > 0) {
		return (
			<>
				{notYetLoadedTours.map((id, index) => {
					return (
						<TourLoadingComponent
							set={false}
							key={index}
							tourId={id}
							tourState={tourState}
							isNativeLongForm={isNativeLongForm}
							loadTour={() => {
								props.loadTour(id, isNativeLongForm, localeHexCode);
							}}
							setTour={(scenarioId: string) => {
								props.setTour(scenarioId);
							}}
							localeHexCode={localeHexCode}
						></TourLoadingComponent>
					);
				})}
			</>
		);
	}

	let dropdownScenarios = tours.filter(
		(s) => selectedCollection.tourIds.indexOf(s.id) >= 0
	);

	const landmark = selectedTourId ? t(project.tourEndLandmarkLabel) : '';

	return (
		<div
			className={styles.demoEnd}
			role="main"
			aria-label={landmark}
			dir={isRTLCulture ? 'rtl' : 'ltr'}
			lang={fallbackLocale}
		>
			{selectedCollection.id === DynamicsCollectionID && (
				<ContactRightRailComponentContainer {...(props as IAppState)} />
			)}
			<AnotherTourComponent
				{...(props as IAppState)}
				tourId={tourEndTourId}
				loadTour={() => {
					props.loadTour(tourEndTourId, isNativeLongForm, localeHexCode);
				}}
				tours={dropdownScenarios}
				setTour={(tourId: string) => {
					props.setTour(tourId);
				}}
			/>
			{selectedTour &&
				selectedTour.secondaryCTA &&
				selectedTour.secondaryCTA.length > 0 && (
					<TourEndCtaComponent
						{...(props as IAppState)}
						tourId={tourEndTourId}
					/>
				)}
			{selectedCollection.tourEnd &&
				selectedCollection.tourEnd.storyCta &&
				selectedCollection.tourEnd.storyCta.title && (
					<TourEndStoryComponent
						{...(props as IAppState)}
						tourId={tourEndTourId}
					/>
				)}
		</div>
	);
};
