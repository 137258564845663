import React, { useCallback, useEffect } from 'react';
import {
	IConsentValues,
	ICookieBannerStateWithDispatch,
} from 'types/cookieBanner.type';
import { addTelemetryScripts, addOneDSScripts } from '../common';

declare global {
	interface Window {
		awa: any;
		WcpConsent: any;
		siteConsent: any;
		appInsights: any;
		oneDS: any;
	}
}

export const CookieConsentBanner = (props: ICookieBannerStateWithDispatch) => {
	const locale = props.locale;
	const cookieBannerPlaceholderId = 'cookie-banner';
	const { appInsights, WcpConsent, oneDS } = window;

	const onConsentChanged = useCallback(
		(consentValues: IConsentValues): void => {
			window.location.reload();

			const homebody = document.querySelector('body, html');

			if (homebody) homebody.scrollTop = 0;
		},
		[]
	);

	const loadTelemetryScripts = useCallback((siteConsent: any): void => {
		if (!siteConsent) return;

		const { isConsentRequired } = siteConsent;
		if (isConsentRequired) {
			// Consent Required.
			const userConsents: IConsentValues = siteConsent.getConsent();

			const { Advertising, Analytics } = userConsents;

			if (Analytics && Advertising) {
				addTelemetryScripts();
				addOneDSScripts();
			}
		} else {
			// No consent is required in this case.
			addTelemetryScripts();
			addOneDSScripts();
		}
	}, []);
	useEffect(() => {
		/* WCP-cookie consent code */
		//TODO For dynamic geo location homeState.selectedLocale has to be used
		WcpConsent &&
			WcpConsent.init(
				locale,
				cookieBannerPlaceholderId,
				(err: any, _siteConsent: any) => {
					if (typeof err !== 'undefined') {
						if (appInsights && appInsights.trackException) {
							appInsights.trackException({
								exception: err,
							});
						}
						return;
					} else {
						window.siteConsent = _siteConsent;
						const { isConsentRequired } = _siteConsent;

						props.setConsentValues({
							..._siteConsent.getConsent(),
							isConsentRequired,
						});

						console.log('Loading telemetry scripts');
						loadTelemetryScripts(window.siteConsent);
					}
				},
				onConsentChanged
			);
	}, [WcpConsent, cookieBannerPlaceholderId, locale, onConsentChanged, loadTelemetryScripts, appInsights, oneDS]);

	return <div id="cookie-banner"></div>;
};
