import { ButtonsState } from './schema.type';


export interface IButtonsState {
	currentState: ButtonsState;
}

export const initButtonsState: IButtonsState = {
    currentState: new ButtonsState(),
};

export interface IButtonsDispatch {
    setFullscreenMode(fullscreenModeState: boolean): void;
    setHiddenButtonMode(hiddenModeState: boolean): void;
}

export interface IButtonHistoryState {
	fullScreenButton: boolean;
	hiddenModeToggled: boolean;
	hideUIModeToggled: boolean;
}

export interface ITargetState {
	target: React.RefObject<HTMLDivElement>;
}

