import { connect } from 'react-redux';
import { LeftPanel, ILeftPanelDispatch } from './LeftPanel';
import { IAppState } from 'types/appState.type';
import { TourSection, TourStep } from 'types/schema.type';
import { setSection } from 'actions/section.action';
import { setStep } from 'actions/step.action';
import { setTour } from 'actions/tour.action';

const mapStateToProps = (state: IAppState): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any): ILeftPanelDispatch => {
	return {
		setSection: (newSection: TourSection) => {
			dispatch(setSection(newSection));
		},
		setStep: (selectedStep: TourStep) => {
			dispatch(setStep(selectedStep));
		},
		setTour: (selectedTour: string) => {
			dispatch(setTour(selectedTour));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel);
