import { connect } from 'react-redux';
import { TourLandingComponent } from './TourLandingComponent';
import { IAppState } from 'types/appState.type';
import { loadTour, setTour } from 'actions/tour.action';
import { Collection, Tour } from 'types/schema.type';
import { setSection } from 'actions/section.action';
import { setStep } from 'actions/step.action';
import { setCollection } from 'actions/collection.action';
import { withRouter } from 'react-router-dom';
import { setIsLanding } from 'actions/project.action';
import { ITourLandingDispatch } from 'types/tour.type';

const mapStateToProps = (state: IAppState): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any): ITourLandingDispatch => {
	return {
		loadTour: (
			tourId: string,
			isNativeLongForm: boolean,
			localeHexCode: string
		) => {
			dispatch(loadTour(tourId, isNativeLongForm, localeHexCode));
		},
		setIsLanding: (isLanding: boolean) => {
			dispatch(setIsLanding(isLanding));
		},
		setTour: (selectedTour: string) => {
			dispatch(setTour(selectedTour));
		},
		setTourObj: (selectedTour: Tour) => {
			dispatch(setTour(selectedTour.id));
			dispatch(setSection(selectedTour.sections[0]));
			dispatch(setStep(selectedTour.sections[0].steps[0]));
		},
		setCollection: (collection: Collection | null) => {
			dispatch(setCollection(collection));
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TourLandingComponent)
);
