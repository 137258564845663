import React from 'react';
//import { css, StyleSheet } from 'aphrodite/no-important';
import { Tour, TourSection, TaskSection } from 'types/schema.type';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import { ITaskSectionGroupingWithDispatch } from 'types/taskGrouping.type';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { GetJsllIdPrefix } from 'utils/jsllTaggingUtils';
import { IFocusElement } from 'types/project.type';

import styles from './TaskSectionGrouping.module.scss';

//const styles = StyleSheet.create({
//    //section: {
//    //    borderLeft: '2px solid',
//    //    borderColor: '#1C57EC',
//    //    paddingLeft: '20px',
//    //    margin: '40px 0'
//    //},
//    //sectionTitle: {
//    //    color: '#1C57EC',
//    //    fontWeight: 700,
//    //    textDecoration: 'none',
//    //},
//    //notFocused: {
//    //    opacity: 0.5,
//    //},
//    //focused: {
//    //    opacity: 1.0,
//    //}
//});

export const TaskSectionGrouping = (
	props: ITaskSectionGroupingWithDispatch
) => {
	const { localeState, collectionState, tourState, sectionState } = props;
	const { selectedTourId } = tourState;
	const { selectedSection } = sectionState;
	const selectedScenario = tourState.selectedTour
		? tourState.selectedTour
		: new Tour();

	const { selectedCollection } = collectionState;
	const { selectedLocale } = localeState;

	const selectedTaskId = selectedSection.sectionNumber;

	const { t } = useTranslation([
		projectNamespace,
		`scenarios/${selectedTourId}`,
	]);

	const focusElementActionFunction = props.setFocusElementActionFunction;

	const jsllIdPrefix = GetJsllIdPrefix(props, t);

	//Pass in main render method so we don't need to recreate it here.
	const getTaskContentOrLink = props.linkOrContentFunction;

	//Quick check to see if we are in range for the given Task/Section Grouping.
	const isCurrentTaskSection = (
		taskSectionStartIndex: number,
		taskSectionEndIndex: number
	) => {
		return _.inRange(
			selectedTaskId,
			taskSectionStartIndex + 1,
			taskSectionEndIndex + 1
		);
	};

	//Render the Welcome Section as it should render the same.
	const renderWelcome = () => {
		return getTaskContentOrLink(selectedScenario.sections[0].sectionName, 0);
	};

	//Render the Summary Section as it should render the same.
	const renderSummary = () => {
		var lastTaskId = selectedScenario.sections.length - 1;
		return getTaskContentOrLink(
			selectedScenario.sections[lastTaskId].sectionName,
			lastTaskId
		);
	};

	//Render the sections that belong to a given Grouping.
	const addGroupedSections = (startIndex: number, endIndex: number) => {
		return selectedScenario.sections.map(
			(taskEntry: TourSection, index: number) => {
				if (index >= startIndex && index < endIndex) {
					return getTaskContentOrLink(taskEntry.sectionName, index);
				}
			}
		);
	};

	//Handle the middle sections and style accordingly.
	const processTourSections = () => {
		var sections = selectedScenario.scenarioTaskGrouping.taskSections;

		//If we don't have the proper sections we are supposed to - fallback on old rendering.
		if (!sections) {
			var lastTaskIndex = selectedScenario.sections.length - 1;
			return selectedScenario.sections.map(
				(taskEntry: TourSection, index: number) => {
					if (index != 0 && index != lastTaskIndex) {
						return getTaskContentOrLink(taskEntry.sectionName, index);
					}
				}
			);
		} else {
			//Loop over the section groupings we have, render the given grouping with the contained tasks/sections.
			return sections.map((section: TaskSection, index: number) => {
				var start = section.sectionStartIndex;
				var end = section.sectionEndIndex;
				var inSection = isCurrentTaskSection(start, end);

				return (
					<div
						className={`${styles.section} ${
							inSection ? '' : styles.notFocused
						}`}
						key={index}
					>
						<Link
							id={index.toString()}
							data-bi-name={`${jsllIdPrefix}-TaskSectionGrouping${index}`}
							tabIndex={index}
							key={index}
							to={`/${selectedLocale}/guidedtour/${
								selectedCollection!.id
							}/${selectedTourId}/${section.sectionStartIndex + 1}/1`}
							className={`${styles.sectionTitle}`}
							onClick={() => {
								!selectedScenario.sections[
									section.sectionStartIndex
								].steps[0].isDefaultCoordinates()
									? focusElementActionFunction(IFocusElement.Bubble)
									: focusElementActionFunction(IFocusElement.Next);
							}}
						>
							<h2 className={`${styles.sectionTitle}`}>
								{t(`scenarios/${selectedTourId}:${section.sectionTitle}`)}
							</h2>
						</Link>
						{addGroupedSections(start, end)}
					</div>
				);
			});
		}
	};

	//Keep the render return simple and move the complexity to methods.
	return (
		<div>
			{renderWelcome()}
			{processTourSections()}
			{renderSummary()}
		</div>
	);
};
