import React from 'react';
import { IAppState } from 'types/appState.type';
import { getNativeLongFormState } from 'utils/renderHelpers';
import DemoComponentContainer from '../shortTour/DemoComponentContainer';
import LongFormTourContainer from '../longform/LongFormTourContainer';

export interface ITourTypeSelectorProps extends IAppState {}

export const TourTypeSelector = (props: ITourTypeSelectorProps) => {
	const isNativeLongForm = getNativeLongFormState(props);

	if (!isNativeLongForm) {
		return <DemoComponentContainer {...props} />;
	} else {
		return <LongFormTourContainer {...props} />;
	}
};
