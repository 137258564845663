import { connect } from 'react-redux';
import { LongFormTour } from './LongFormTour';
import { IAppState } from 'types/appState.type';
import { ITourDispatch } from 'types/tour.type';
import { TourSection, TourStep } from 'types/schema.type';
import { loadTour, setTour } from 'actions/tour.action';
import { setSection } from 'actions/section.action';
import { setStep } from 'actions/step.action';
import { setIsLanding, setShowCallToAction } from 'actions/project.action';
import {
	setFullscreenMode,
	setHiddenButtonMode,
	setHideButtonUIMode,
} from 'actions/buttons.action';

const mapStateToProps = (state: IAppState): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any): ITourDispatch => {
	return {
		loadTour: (
			tourId: string,
			isNativeLongForm: boolean,
			localeHexCode: string
		) => {
			dispatch(loadTour(tourId, isNativeLongForm, localeHexCode));
		},
		setIsLanding: (isLanding: boolean) => {
			dispatch(setIsLanding(isLanding));
		},
		setTour: (selectedTour: string) => {
			dispatch(setTour(selectedTour));
		},
		setSection: (newSection: TourSection) => {
			dispatch(setSection(newSection));
		},
		setStep: (selectedStep: TourStep) => {
			dispatch(setStep(selectedStep));
		},
		setFullscreenMode: (fullscreenModeState: boolean) => {
			dispatch(setFullscreenMode(fullscreenModeState));
		},
		setHiddenButtonMode: (hiddenModeState: boolean) => {
			dispatch(setHiddenButtonMode(hiddenModeState));
		},
		setShowCallToAction: (showCallToAction: boolean) => {
			dispatch(setShowCallToAction(showCallToAction));
		},
		setHideButtonUIMode: (hideButtonUI: boolean) => {
			dispatch(setHideButtonUIMode(hideButtonUI));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LongFormTour);
