import { connect } from 'react-redux';
import { TourEndComponent } from './TourEndComponent';
import { IAppState } from 'types/appState.type';
import { withRouter } from 'react-router-dom';
import { loadTour, setTour } from 'actions/tour.action';
import { ITourEndDispatch } from 'types/tour.type';
import { setHeaderMode, setIsLanding } from 'actions/project.action';

const mapStateToProps = (state: IAppState, props: any) => ({ ...state });

const mapDispatchToProps = (dispatch: any): ITourEndDispatch => {
	return {
		loadTour: (
			tourId: string,
			isNativeLongForm: boolean,
			localeHexCode: string
		) => {
			dispatch(loadTour(tourId, isNativeLongForm, localeHexCode));
		},
		setIsLanding: (isLanding: boolean) => {
			dispatch(setIsLanding(isLanding));
		},
		setTour: (selectedTour: string) => {
			dispatch(setTour(selectedTour));
		},
		setHeaderDarkMode: (setHeaderModeValue: boolean) => {
			dispatch(setHeaderMode(setHeaderModeValue));
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TourEndComponent)
);
