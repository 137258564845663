import { setLocale, loadLocaleInfo } from 'actions/locale.action';
import { connect } from 'react-redux';
import { IAppState } from 'types/appState.type';
import { LocaleComponent } from './LocaleComponent';
import {
	ILocaleDispatchProps,
	ILocaleProps,
	ILocaleStateProps,
} from 'types/locale.type';
import { Dispatch } from 'react';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (
	state: IAppState,
	props: ILocaleProps
): ILocaleStateProps => {
	return {
		...state,
		...props,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ILocaleDispatchProps => {
	return {
		selectLocale: (locale: string) => {
			dispatch(setLocale(locale));
			dispatch(loadLocaleInfo(locale));
		},
		loadLocaleInfo: (selectedLocale: string) => {
			dispatch(loadLocaleInfo(selectedLocale));
		},
	};
};

//Expicitly avoiding type checking to pass in inline param...
export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(LocaleComponent)
);
