import { getType } from 'typesafe-actions';
import { ITourState, initTourState } from 'types/tour.type';
import {
  loadTourFail,
  loadTourStart,
  loadTourSuccess,
  setTour,
  setTourImagesLoaded,
  setTourCallback,
  setTourCallbackText,
	setTourRunning
} from 'actions/tour.action';
import { loadProjectSuccess } from 'actions/project.action';
import { Tour } from 'types/schema.type';
import { buildTour } from 'utils/tourUtil';

export default (state: ITourState = initTourState, action: any): ITourState => {
  let newTours: Tour[] = [];
  switch (action.type) {
		case getType(loadTourSuccess): {
			const newTour = buildTour(
				action.payload.result,
				action.payload.localeHexCode as string,
				action.payload.useLocaleImages as boolean
			);
              newTours = [...state.tours];

              let existingTourIndex = newTours.findIndex(
                  (t) =>
					t.id.toLowerCase() === (action.payload.tourId as string).toLowerCase()
              );

              if (existingTourIndex < 0) {
                  newTours.push(newTour);
              } else {
                  newTours[existingTourIndex] = newTour;
              }
              return {
                  ...state,
                  tours: newTours,
                  isLoading: false,
                  loadingError: false,
                  loadingTours: state.loadingTours.filter(
                      (tourId: string) =>
                          tourId.toLowerCase() !== action.payload.tourId.toLowerCase()
                  ),
              };
          }
		case getType(setTour): {
              const newTourId = action.payload.tourId.toLowerCase();
              const selectedTour = state.tours.find(
                  (tour) => tour.id.toLowerCase() === newTourId
              );
              return {
                  ...state,
                  selectedTourId: newTourId,
                  selectedTour: selectedTour || null,
              };
          }
		case getType(setTourImagesLoaded): {
              const imageLoadedTourId = action.payload.tourId.toLowerCase();
              let selectedTour = state.selectedTour;

              //Simple check to make sure we have the right tour.
			if (!selectedTour || selectedTour.id !== imageLoadedTourId) return state;

              //Updated tour to record images are loaded.
              selectedTour.imagesLoaded = true;

              return {
                  ...state,
                  selectedTour: selectedTour || null,
              };
          }
		case getType(setTourRunning): {
			const tourRunning = action.payload.tourRunning;
			return {
				...state,
				isTourRunning: tourRunning,
			};
		}
		case getType(loadTourStart): {
              let loadingTours = [...state.loadingTours];
              loadingTours.push(action.payload.tourId.toLowerCase());
              return {
                  ...state,
                  isLoading: true,
                  loadingTours,
              };
          }
		case getType(loadTourFail): {
              return {
                  ...state,
                  isLoading: false,
                  loadingError: true,
                  loadingTours: state.loadingTours.filter(
                      (tourId: string) =>
                          tourId.toLowerCase() !== action.payload.tourId.toLowerCase()
                  ),

              };
          }
      case getType(setTourCallback):
          return {
              ...state,
              overrideCallback: action.payload.callback,
          };
      case getType(setTourCallbackText):
          return {
              ...state,
              overrideCallbackText: action.payload.callbackText,
          };

      case getType(loadProjectSuccess):
          return {
              ...state,
              selectedTour: null,
          };
  }
  return state;
};
