import React, { Component, createRef, RefObject } from 'react';
import ReactDOM from 'react-dom';
import {
	Overlay,
	Icon,
	FocusTrapZone,
	IconButton,
	PrimaryButton,
} from '@fluentui/react';
import { PrimaryButtonStyling } from '../../globalStyling'

import styles from './Modal.module.scss';
import generalStyles from 'styles/generalStyles.module.scss';

interface IModal {
	closeModal: () => void;
	title: string;
	isRTLCulture: boolean;
	description: string;
	primaryButtonId: string;
	primaryButtonText: string;
	secondaryButtonText: string;
	primaryClick: () => void;
	secondaryClick: () => void;
}

export default class ModalComponent extends Component<IModal, {}> {
	private el: any;
	private modalRoot: any;
	private node: RefObject<HTMLDivElement>;

	constructor(props: IModal) {
		super(props);
		this.el = document.createElement('div');
		this.modalRoot = document.getElementById('modal-root');
		this.node = createRef();
	}

	componentDidMount() {
		this.modalRoot.appendChild(this.el);
		if (this.node && this.node.current) {
			this.node.current.focus({
				preventScroll: true,
			});
		}
	}

	componentWillUnmount() {
		this.modalRoot.removeChild(this.el);
	}

	keydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (
			event.key === 'Escape' ||
			event.key === 'ArrowLeft' ||
			event.key === 'ArrowRight'
		) {
			this.props.closeModal();
		}
	};

	renderContainer = () => {
		const {
			title,
			description,
			closeModal,
			primaryButtonId,
			primaryButtonText,
			primaryClick,
			secondaryButtonText,
			secondaryClick,
			isRTLCulture,
		} = this.props;

		const iconStyle = {
			icon: {
				fontSize: 12,
			},
		};

		const desc = description
			.replace(/&nbsp;/gi, ' ')
			.replace(/&nbsp/g, ' ')
			.replace(/&nbsp;/g, ' ')
			.replace(/%A0/gi, ' ')
			.replace(/ /gi, ' ')
			.replace(/(?:^[\s\u00a0]+)|(?:[\s\u00a0]+$)/g, ' ');

		// Need this to override typescript typing for adding name attribute (for jsll)
		const secondaryButtonAttributes = {
			id: 'modal-secondary',
			name: secondaryButtonText,
			className: styles.link,
			onClick: secondaryClick,
			tabIndex: 0,
		};

		return (
			<FocusTrapZone>
				<div
					className={`${styles.modalWrapper}`}
					dir={isRTLCulture ? 'rtl' : 'ltr'}
				>
					<div
						className={styles.content}
						ref={this.node}
						tabIndex={-1}
						onKeyDown={this.keydown}
						role="dialog"
					>
						<div className={`${styles.close}`}>
							<IconButton
								data-bi-name="close"
								iconProps={{ iconName: 'ChromeClose' }}
								styles={iconStyle}
								title="Close"
								ariaLabel="Close"
								onClick={closeModal}
							/>
						</div>
						<h2 className="modal-title ms-fontWeight-semibold ms-fontSize-24">
							{title}
						</h2>
						<div
							dangerouslySetInnerHTML={{
								__html: desc,
							}}
						/>
						<div className={`${styles.linkRow}`}>
							<PrimaryButton
								id={primaryButtonId}
								name={primaryButtonText}
								styles={PrimaryButtonStyling}
								allowDisabledFocus
								text={primaryButtonText}
								onClick={primaryClick}
							>
								<Icon
									iconName={
										isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'
									}
									className={`${generalStyles.ChevronStyle}`}
								></Icon>
							</PrimaryButton>
							{secondaryButtonText && (
								<a {...secondaryButtonAttributes}>
									{secondaryButtonText}
									<Icon
										iconName={
											isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'
										}
										className={`${generalStyles.ChevronStyle}`}
									></Icon>
								</a>
							)}
						</div>
					</div>
					<Overlay isDarkThemed={true} onClick={closeModal} />
				</div>
			</FocusTrapZone>
		);
	};

	render() {
		return ReactDOM.createPortal(this.renderContainer(), this.el);
	}
}
