import { IAppState } from './appState.type';
import { Tour, TourSection, TourStep, Collection } from './schema.type';
import { IButtonsDispatch } from 'types/buttons.type';

export interface ITourState {
    selectedTourId: string;
    selectedTour: Tour | null;
    overrideCallback: string;
    overrideCallbackText: string;
    isLoading: boolean;
	isTourRunning: boolean;
    loadingError: boolean;
    loadingTours: string[];
    tours: Tour[];
}

export const initTourState: ITourState = {
    selectedTourId: '',
    selectedTour: null,
    overrideCallback: '',
    overrideCallbackText: '',
    isLoading: false,
	isTourRunning:false,
    loadingError: false,
    loadingTours: [],
    tours: [],
};

export interface ICanLoadTour {
    setTour(selectedTour: string): void;
	loadTour(
		tourId: string,
		isNativeLongForm: boolean,
		localeHexCode: string
	): void;
}

export interface ITourNavigation {
	updateHistoryForNavigationState(
		currentSection: number,
		currentStep: number
	): void;
    incrementStep(): void;
    decrementStep(): void;
}

export interface IOldTourDispatch extends ICanLoadTour {
    setIsLanding(isLanding: boolean): void;
    setSection(selectedSection: TourSection): void;
    setStep(selectedStep: TourStep): void;
    setTourCallback(callback: string, callbackText: string): void;
	setTourImagesLoaded(selectedTour: string): void;
	setTourRunning(tourRunning: boolean): void;
}

export interface ITourDispatch extends ICanLoadTour, IButtonsDispatch {
    setIsLanding(isLanding: boolean): void;
    setShowCallToAction(showCallToAction:boolean): void;
    setSection(selectedSection: TourSection): void;
    setStep(selectedStep: TourStep): void;
    setHideButtonUIMode(hideButtonUI: boolean): void;
}

export interface ITourLandingDispatch extends ICanLoadTour {
    setTourObj(selectedTour: Tour): void;
    setIsLanding(isLanding: boolean): void;
    setCollection(collection: Collection | null): void;
}

export interface ITourEndDispatch extends ICanLoadTour {
    setHeaderDarkMode(setHeaderMode: boolean): void;
    setIsLanding(isLanding: boolean): void;
}

export interface ITourStateWithDispatch extends ITourDispatch, IAppState {}

export interface IOldTourStateWithDispatch
    extends IOldTourDispatch,
    IAppState { }

export interface ITourLoaderProps {
    tourState: ITourState;
    tourId: string;
    localeHexCode: string;
    isNativeLongForm: boolean;
    set: boolean;
}

export interface ITourLoaderDispatchProps {
    loadTour(tourId: string, isNativeLongForm:boolean, locale: string): void;
    setTour(tourId: string): void;
}

export interface ITourLoaderStateWithDispatch
    extends ITourLoaderProps,
    ITourLoaderDispatchProps { }
