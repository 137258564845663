import { connect } from 'react-redux';
import { IAppState } from 'types/appState.type';
import { IStepIndicatorProps, StepIndicator } from './StepIndicator';
import {
	setShowCallToAction,
	setFocusElementAction,
} from 'actions/project.action';
import { IFocusElement } from 'types/project.type';

const mapStateToProps = (state: IAppState): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any): IStepIndicatorProps => {
	return {
		setShowCallToAction: (showCallToAction: boolean) => {
			dispatch(setShowCallToAction(showCallToAction));
		},
		setFocusElementAction: (focusElement: IFocusElement) => {
			dispatch(setFocusElementAction(focusElement));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StepIndicator);
