import React, { useState, useEffect, useRef } from 'react';
import { PrimaryButton, getTheme, Icon, Spinner } from '@fluentui/react';
import { IAppState } from 'types/appState.type';
import { appInsights } from 'store/appStore';
import { Collection, Tour, Image, TourSection } from 'types/schema.type';
import { Redirect } from 'react-router';
import { LandingButtonStyling } from '../../globalStyling';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import { TourLoadingComponent } from 'components/tourLoading/TourLoadingComponent';
import ProjectComponentContainer from 'components/project/ProjectComponentContainer';
import CollectionLoadingComponentContainer from 'components/collectionLoading/CollectionLoadingComponentContainer';
import { ITourLandingDispatch } from 'types/tour.type';
import ItemDropdown from 'components/common/ItemDropdown';
import LocaleComponentContainer from 'components/locale/LocaleComponentContainer';
import { globalPaths } from 'utils/dataClient';
//import { ContactRail } from '../../components/common/ContactRightRailComponent';

import generalStyles from 'styles/generalStyles.module.scss';
import styles from './TourLanding.module.scss';
import { getNativeLongFormState } from '../../utils/renderHelpers';
import customerServiceImage from './landingImages/customerService.jpg';

export interface ITourLandingPropsWithDispatch
	extends ITourLandingDispatch,
		IAppState {}

export const TourLandingComponent = (props: ITourLandingPropsWithDispatch) => {
	const {
		projectState,
		tourState,
		localeState,
		sectionState,
		stepState,
		collectionState,
		match,
	} = props;

	const collectionId = match.params.collectionId
		? match.params.collectionId.toLowerCase()
		: '';
	const selectedLocale = match.params.collectionId
		? match.params.selectedLocale.toLowerCase()
		: '';

	const { project, projectLoaded, isLanding } = projectState;
	const { selectedSection } = sectionState;
	const { selectedStep } = stepState;
	const { selectedTour, tours } = tourState;
	const { selectedCollection } = collectionState;
	const { localeHexCode, isRTLCulture, fallbackLocale } = localeState;

	const isNativeLongForm = getNativeLongFormState(props);
    const namespaceString = isNativeLongForm ? 'tours' : 'scenarios';

	const imgRef = useRef<HTMLImageElement>(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [orientation, setOrientation] = useState('horizontal');
	const [imageState, setImageState] = useState<Image>({ path: '', alt: '' });

	const [redirect, setRedirect] = useState(false);

    const { t } = useTranslation([projectNamespace]);

	useEffect(() => {
		const img = imgRef.current;
		if (img) {
			const handleLoad = () => {
				setOrientation(img.naturalWidth > img.naturalHeight ? 'horizontal' : 'vertical');
				setIsLoaded(true);
			};
	
			const handleError = () => {
				console.error("Failed to load image:", img.src);
				setIsLoaded(false);
			};
	
			img.addEventListener('load', handleLoad);
			img.addEventListener('error', handleError);
	
			if (img.complete) {
				if (img.naturalHeight === 0) {
					handleError();
				} else {
					handleLoad();
				}
			}
	
			return () => {
				img.removeEventListener('load', handleLoad);
				img.removeEventListener('error', handleError);
			};
		}
	}, [imageState.path]);
	
	
	useEffect(() => {
		if (!isLanding) {
			props.setIsLanding(true);
		}
	}, [isLanding, props]);

	useEffect(() => {
		setRedirect(false);
	}, [redirect]);

	useEffect(() => {
        if (selectedCollection && selectedCollection.title.length === 0) {
            const newCollection = collectionState.collections.find(
                col => col.id === selectedCollection.id
            );
            if (newCollection) {
                props.setCollection(newCollection);
            }
        }
    }, [collectionState.collections, selectedCollection, props]);

	useEffect(() => {
		if (!selectedCollection) return;
	
		let newPath = '', newAlt = '';
		if (!selectedTour) {
			newPath = `/collections/${selectedCollection.id}/landing.jpg`;
			newAlt = t(`${namespaceString}:${selectedCollection.title}`);
		} else {
			const currentSection = getShowcasedSection();
			const currentStep = getShowcasedStep(currentSection);
			const imageSpacer = isNativeLongForm ? '_' : '.';
			const imgExt = isNativeLongForm ? 'PNG' : 'png';
			newPath = `/${namespaceString}/${selectedTour.id}/${currentSection.sectionNumber}${imageSpacer}${currentStep.stepNumber}.${imgExt}`;
			newAlt = t(`${namespaceString}/${selectedTour.id}:${currentSection.sectionName}`);
		}
		setImageState({ path: newPath, alt: newAlt });
	}, [selectedTour, selectedCollection, namespaceString, isNativeLongForm, t]);	

	let nameSpaces = [projectNamespace];
	let collectionNamespace: string = '';

	if (selectedCollection) {
		collectionNamespace = `collections/${selectedCollection.id}`;
		nameSpaces.push(collectionNamespace);
		for (const id of selectedCollection.tourIds) {
			nameSpaces.push(`${namespaceString}/${id}`);
		}
	}
	
	if (!localeState.selectedLocale || localeState.isLoading) {
		return (
			<LocaleComponentContainer
				locale={selectedLocale}
			></LocaleComponentContainer>
		);
	}

	if (!projectLoaded) {
		return (
			<ProjectComponentContainer
				locale={selectedLocale}
			></ProjectComponentContainer>
		);
	}

	if (!selectedCollection || selectedCollection.id !== collectionId) {
		return (
			<CollectionLoadingComponentContainer
				collectionId={collectionId}
				locale={selectedLocale}
				isNativeLongForm={isNativeLongForm}
				isBatchLoading={false}
			></CollectionLoadingComponentContainer>
		);
	}

	let heading: string;
	let description: string;

	if (!selectedTour) {
		heading = t(`${collectionNamespace}:${selectedCollection.title}`);
		description = t(`${collectionNamespace}:${selectedCollection.description}`);
	} else {
		const tourNamespace: string = `${namespaceString}/${selectedTour.id}`;
		heading = t(`${tourNamespace}:${selectedTour.title}`);
		description = t(`${tourNamespace}:${selectedTour.description}`);
	}

	const selectedTourId = selectedTour ? selectedTour.id : '';
	const url = `/${selectedLocale}/guidedtour/${selectedCollection.id}/${selectedTourId}/1/1`;

	if (redirect && selectedTour !== null) {
		return (
			<Redirect
				push
				to={{
					pathname: url,
				}}
			/>
		);
	}

	const startTestDrive = () => {
		appInsights.trackEvent({
			name: 'Start testdrive',
			properties: { selectedTourId },
		});
		setRedirect(true);
	};

	const launchText = t(`${projectNamespace}:${project.launchText}`);
	const dropdownPlaceholder = t(`${projectNamespace}:${project.tourDropdown}`);

	let dropdownTours = tours.filter(
		(s) => selectedCollection.tourIds.indexOf(s.id) > -1
	);

	const getShowcasedSection = () => {
		if (selectedSection && selectedSection.sectionNumber != 1) {
			return selectedSection;
		}

		return selectedTour!.sections[1];
	};

	const getShowcasedStep = (section: TourSection) => {
		if (section == selectedSection) {
			return selectedStep;
		}

		return section.steps[0];
	};

	const landmarkLabel = project.landingLandmarkLabel;
	return (
		<div
			className={styles.page}
			role="main"
			aria-label={project.sectionLandmarkLabel}
		>
			{selectedCollection.tourIds.map((id, index) => {
				return (
					<TourLoadingComponent
						set={false}
						key={index}
						tourId={id}
						tourState={tourState}
						isNativeLongForm={isNativeLongForm}
						loadTour={() => {
							props.loadTour(id, isNativeLongForm, localeHexCode);
						}}
						setTour={(tourId: string) => {
							props.setTour(tourId);
						}}
						localeHexCode={localeHexCode}
					></TourLoadingComponent>
				);
			})}

			<div
				className={styles.pageContent}
				dir={isRTLCulture ? 'rtl' : 'ltr'}
				lang={fallbackLocale}
			>
				<div className={`${styles.imageContainer} ${styles[orientation]}`}>
				{
				!isLoaded && <div className={styles.loadingContainer}><Spinner /></div>
				}
				<img
				ref={imgRef}
				className={styles.image}
				src={
					selectedTour && selectedTour.id === 'customer-service'
						? customerServiceImage
						: `${globalPaths.baseUrl}/${imageState.path}`
				}
				alt={imageState.alt}
				onLoad={() => setIsLoaded(true)}
				onError={() => setIsLoaded(false)}
				style={{ display: isLoaded ? 'block' : 'none' }}
				/>
				</div>
				<div className={styles.landingPageContentContainer}>
					<div
						className={styles.landingPageContent}
						role="main"
						aria-label={landmarkLabel}
					>
						<h1 className={styles.heading}>{heading}</h1>
						<p className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></p>
						<ItemDropdown
							large={false}
							selectedItemId={selectedTour ? selectedTour.id : ''}
							items={dropdownTours}
							itemType={namespaceString}
							placeholder={dropdownPlaceholder}
							selectFocusable={true}
							setItem={(tour: Tour) => {
								props.setTourObj(tour);
							}}
						/>

						<PrimaryButton
							id={'landing-launch'}
							name={launchText}
							className={styles.button}
							target="_blank"
							tabIndex={0}
							styles={LandingButtonStyling}
							disabled={selectedTour === null}
							theme={getTheme()}
							onClick={() => {
								startTestDrive();
							}}
							text={launchText}
						>
							<Icon
								iconName={
									isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'
								}
								className={generalStyles.ChevronStyle}
							></Icon>
						</PrimaryButton>
					</div>
				</div>
			</div>
		</div>
	);
};
