import { connect } from 'react-redux';
import { DemoComponent } from './DemoComponent';
import { IAppState } from 'types/appState.type';
import {
	loadTour,
	setTour,
	setTourCallback,
	setTourCallbackText,
	setTourImagesLoaded,
	setTourRunning
} from 'actions/tour.action';
import { setStep } from 'actions/step.action';
import { setSection } from 'actions/section.action';
import { TourSection, TourStep } from 'types/schema.type';
import { IOldTourDispatch } from 'types/tour.type';
import { withRouter } from 'react-router-dom';
import { setIsLanding } from 'actions/project.action';

const mapStateToProps = (state: IAppState): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any): IOldTourDispatch => {
	return {
		loadTour: (
			tourId: string,
			isNativeLongForm: boolean,
			localeHexCode: string
		) => {
			dispatch(loadTour(tourId, isNativeLongForm, localeHexCode));
		},
		setIsLanding: (isLanding: boolean) => {
			dispatch(setIsLanding(isLanding));
		},
		setTour: (selectedTour: string) => {
			dispatch(setTour(selectedTour));
		},
		setSection: (selectedSection: TourSection) => {
			dispatch(setSection(selectedSection));
		},
		setStep: (selectedStep: TourStep) => {
			dispatch(setStep(selectedStep));
		},
		setTourCallback: (callback: string, callbackText: string) => {
			dispatch(setTourCallback(callback));
			dispatch(setTourCallbackText(callbackText));
		},
		setTourImagesLoaded: (selectedTour: string) => {
			dispatch(setTourImagesLoaded(selectedTour));
		},
		setTourRunning: (tourRunning: boolean) => {
			dispatch(setTourRunning(tourRunning));
		}
	};
};

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(DemoComponent)
);
