//StepIndicator.tsx
import React, { useEffect, useRef, useState } from 'react';
import { getTheme } from '@fluentui/react/lib/Styling';
import { Link } from 'react-router-dom';
import { appInsights } from 'store/appStore';
import { IAppState } from 'types/appState.type';
import { IFocusElement } from 'types/project.type';
import { GetJsllIdPrefix } from 'utils/jsllTaggingUtils';
import { useTranslation } from 'react-i18next';
import { Tour } from 'types/schema.type';

import styles from './StepIndicator.module.scss';

export interface IStepIndicatorProps {
	setShowCallToAction(showCallToAction: boolean): void;
	setFocusElementAction(focusElementAction: IFocusElement): void;
}

export interface IStepIndicatorWithDispatch
	extends IStepIndicatorProps,
		IAppState {}

export const StepIndicator = (props: IStepIndicatorWithDispatch) => {
	const {
		stepState,
		projectState,
		localeState,
		collectionState,
		tourState,
		sectionState,
	} = props;
	const [beakStyle, setBeakStyle] = useState({});
	const { selectedLocale, isRTLCulture } = localeState;
	const { selectedCollection } = collectionState;
	const { focusElement, project } = projectState;
	const { selectedTourId } = tourState;

	const selectedTour = tourState.selectedTour
		? tourState.selectedTour
		: new Tour();
	const { t } = useTranslation([`scenarios/${selectedTour.id}`]);

	const { selectedSection } = sectionState;
	const { selectedStep } = stepState;
	const selectedSectionId = selectedSection.sectionNumber;
	const selectedStepId = selectedStep.stepNumber;

	const { coachmarkCoordinates } = selectedStep;

	const {beakDirection} = selectedStep

	const ref: React.MutableRefObject<any> = useRef(null);

	let topPercentage = coachmarkCoordinates[1] == -1 ? 95 : coachmarkCoordinates[1];
	let leftPercentage = coachmarkCoordinates[0] == -1 ? 95 : coachmarkCoordinates[0];
	let rightPercentage = coachmarkCoordinates[0] == -1 ? -95 : -1 * (98 - coachmarkCoordinates[0]);
	
	if (!selectedCollection) {
		return <></>;
	}

	const theme = getTheme();

	const defaultAdjustment = -1.5; // The amount to adjust the top position for default coordinates

	// Check if the coordinates are the default values
	const isDefaultPosition = (coachmarkCoordinates[0] === 0 && coachmarkCoordinates[1] === 0) || 
							  (coachmarkCoordinates[0] === -1 && coachmarkCoordinates[1] === -1);
	
	// Adjust topPercentage if in default position
	const adjustedTopPercentage = isDefaultPosition ? topPercentage + defaultAdjustment : topPercentage;
	
	// Then use adjustedTopPercentage for setting the style
	const coachmarkStyle = {
		left: `${leftPercentage}%`,
		top: `${adjustedTopPercentage}%`, // Use adjusted top percentage here
	};
	
	const coachmarkStyleRTL = {
		left: `${rightPercentage}%`,
		top: `${adjustedTopPercentage}%`, // Use adjusted top percentage here for RTL as well
	};
	
	const totalSections = selectedTour.sections.length;
	const totalSteps = selectedSection.steps.length;
	const nextStepId = selectedStepId + 1;
	const nextSectionId = selectedSectionId + 1;
	const isLastStep = nextStepId > totalSteps;
	const isLastSection = nextSectionId > totalSections;

	const linkSectionId =
		isLastStep && !isLastSection ? nextSectionId : selectedSectionId;

	const linkStepId = isLastStep
		? isLastSection
			? selectedStepId
			: 1
		: nextStepId;

	if (ref) {
		try {
			(ref.current! as HTMLElement)!.focus({
				preventScroll: true,
			});
		} catch (error) {}
	}

	setTimeout(() => {
		if (focusElement === IFocusElement.Bubble && ref.current) {
			ref.current.focus();
		}
	}, 1);

	function trigger() {
		appInsights.trackEvent({
			name: 'IndicatorClick',
		});
		if (isLastStep && isLastSection) {
			props.setShowCallToAction(true);
		}
	}

	const jsllIdPrefix = GetJsllIdPrefix(props, t);

	const indicatorId = `${jsllIdPrefix}-bluedot`;

	return topPercentage > 0 && leftPercentage > 0 && !isDefaultPosition ? (
		<div className={`${styles.stepIndicatorContainer}`}>
			<Link
				id={indicatorId}
				data-bi-name={indicatorId}
				className={`${styles.stepIndication}`}
				style={isRTLCulture ? coachmarkStyleRTL : coachmarkStyle}
				ref={ref}
				onClick={trigger}
				onKeyPress={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						trigger();
					}
				}}
				aria-label={project.stepNavLabelNext}
				to={`/${selectedLocale}/guidedtour/${
					selectedCollection!.id
				}/${selectedTourId}/${linkSectionId}/${linkStepId}`}
			></Link>
		</div>
	) : null;
};

//ADD THIS LINE BELOW <LINK> for Beak
{/* <div className={`${styles.beak}`} style={beakStyle}></div> */}


	//ADJUSTBALE BEAK
	// useEffect(() => {
	// 	const updateBeakPosition = () => {
	// 		let baseTop = coachmarkCoordinates[1] === -1 ? 95 : coachmarkCoordinates[1];
	// 		let baseLeft = coachmarkCoordinates[0] === -1 ? 95 : coachmarkCoordinates[0];
		
	// 		const isDefaultPosition = (coachmarkCoordinates[0] === 0 && coachmarkCoordinates[1] === 0) || 
	// 								  (coachmarkCoordinates[0] === -1 && coachmarkCoordinates[1] === -1);

	// 		const defaultAdjustment = isDefaultPosition ? -6 : 0;
	// 		let adjustedTopPercentage = baseTop + defaultAdjustment;
	
	// 		let rotationDegrees;
	// 		let offsetX = '0px'; // Initialize offsetX as a string to use in the calc() CSS function
	// 		let offsetY = '12px'; // Initialize offsetY as a string to use in the calc() CSS function
			
	// 		switch (beakDirection) {
	// 			case 'top':
	// 				rotationDegrees = 0; // No rotation needed
	// 				offsetY = '13px';
	// 				offsetX = '-12px';
	// 				break;
	// 			case 'bottom':
	// 				rotationDegrees = 180; // Rotate beak to point downwards
	// 				offsetY = '-27px'; // Move beak below the Step Indicator
	// 				offsetX = '-12px';
	// 				break;
	// 			case 'left':
	// 				rotationDegrees = 270; // Rotate beak to point left
	// 				offsetX = '8px'; // Move beak to the left of the Step Indicator
	// 				offsetY = '-8px'; // No vertical offset
	// 				break;
	// 			case 'right':
	// 				rotationDegrees = 90; // Rotate beak to point right
	// 				offsetX = '-32px'; // Move beak to the right of the Step Indicator
	// 				offsetY = '-8px'; // No vertical offset
	// 				break;
	// 			default:
	// 				rotationDegrees = 0; // Default orientation
	// 				break;
	// 		}
	
	// 		// Apply the adjusted position and rotation to the beak
	// 		const beakPosition = {
	// 			left: `calc(${baseLeft}% + ${offsetX})`, // Adjust left position based on offsetX
	// 			top: `calc(${adjustedTopPercentage}% + ${offsetY})`, // Adjust top position based on offsetY
	// 			transform: `rotate(${rotationDegrees}deg)`,
	// 		};
	
	// 		setBeakStyle(beakPosition);
	// 	};
	
	// 	updateBeakPosition();
	// }, [coachmarkCoordinates, beakDirection, selectedStep]); // Rerun when dependencies change