import { IAppState } from './appState.type';
import { TourSection, TourStep } from './schema.type';

export interface ISectionState {
    selectedSection: TourSection;
}

export const initSectionState: ISectionState = {
    selectedSection: new TourSection(),
};

export interface ISectionStateWithDispatch extends IAppState {
    setSection(selectedSection: TourSection): void;
    setStep(selectedStep: TourStep): void;
}
