import React from 'react';
import { Dropdown, IDropdownStyles, IStyleFunctionOrObject, IDropdownStyleProps } from '@fluentui/react';
import { IDropdownValue } from 'types/schema.type';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';

interface IDropdown {
  styles?: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
	selectedItemId: string | null;
	selectFocusable: boolean;
	large: boolean;
	sortItems?: boolean;
	placeholder: string;
	itemType: string;
	items: IDropdownValue[];
	setItem(selectedItem: IDropdownValue): void;
}

const ItemDropdown = ({
	placeholder,
	items,
	itemType,
	large,
	sortItems,
	selectFocusable,
	selectedItemId,
	setItem,
}: IDropdown) => {
	let nameSpaces = [projectNamespace];
	for (const item of items) {
		nameSpaces.push(`${itemType}/${item.id}`);
	}
	const { t } = useTranslation(nameSpaces);

	const unsortedItems = items.map((item) => ({
		key: item.id,
		text: t(`${itemType}/${item.id}:${item.title}`),
	}));

	const options =
		sortItems != undefined && !sortItems
			? unsortedItems
			: unsortedItems.sort((a, b) => a.text.localeCompare(b.text));

	const height = large ? 40 : 32;
	const width = large ? 400 : 300;

	const heightStyle = `${height}px !important`;
	const dropdownHeightStyle = {
		height: heightStyle,
		maxHeight: heightStyle,
		lineHeight: heightStyle,
	};

	const dropdownStyle: Partial<IDropdownStyles> = {
		root: {
			width,
			height,
			selectors: {
				':focus-within': {
					outline: 'solid rgb(41, 128, 196) 3px !important',
				},
				':focus': {
					outline: 'solid rgb(41, 128, 196) 3px !important',
				},
				':active': {
					outline: 'solid rgb(41, 128, 196) 3px !important',
				},
			},
		},
		dropdown: dropdownHeightStyle,
		dropdownItem: dropdownHeightStyle,
		title: dropdownHeightStyle,
		caretDownWrapper: dropdownHeightStyle,
		dropdownOptionText: dropdownHeightStyle,
		dropdownItemSelected: {
			backgroundColor: '#75757A',
			color: 'white',
			selectors: {
				':hover': {
					color: 'white',
				},
				':active': {
					color: 'white',
				},
			},
		},
	};

	const selectedId = selectedItemId ? selectedItemId : '';
	return (
		<Dropdown
			ariaLabel={placeholder}
			placeholder={placeholder}
			selectedKey={selectedId}
			tabIndex={selectFocusable ? 0 : -1}
			options={options}
			styles={dropdownStyle}
			onChange={(e, option) => {
				if (!option) {
					return;
				}
				const selectedId = option.key as string;
				const selectedTour = items.find(
					(item: IDropdownValue) => item.id === selectedId
				);
				setItem(selectedTour!);
			}}
		/>
	);
};

export default ItemDropdown;
