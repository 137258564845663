import { IPropsWithChildren, IAppState } from './appState.type';

export interface ILocaleData {
    [type: string]: string;
}
export class Locale {
    name: string = '';
    localeData: ILocaleData = {};
    isLoaded(type: string): boolean {
        for (const dataType in this.localeData) {
            if (type === dataType) {
                return true;
            }
        }
        return false;
    }
}

export interface ILocaleState {
    selectedLocale: string;
    localeHexCode: string;
    isRTLCulture: boolean;
    fallbackLocale: string;
    isLoading: boolean;
    loadingError: boolean;
}

export const initLocaleState: ILocaleState = {
    selectedLocale: '',
    localeHexCode: '',
    fallbackLocale: '',
    isRTLCulture: false,
    isLoading: false,
    loadingError: false,
};

export interface ILocaleStateProps extends IAppState { }

export interface ILocaleProps {
    locale: string;
}

export interface ILocaleDispatchProps {
    selectLocale(locale: string): void;
    loadLocaleInfo(selectedLocale: string): void;
}

export interface ILocaleInfo {
    localeHexCode: string;
    isRTLCulture: boolean;
    fallbackLocale: string;
}

export interface ILocaleStateWithDispatch
    extends IPropsWithChildren,
    ILocaleProps,
    ILocaleDispatchProps,
    ILocaleStateProps { }
