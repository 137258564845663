import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createAppReducers from 'reducers/reducersCombined';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// Declare the Redux DevTools compose function
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const history = createBrowserHistory();
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_KEY || '',
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

const middlewareList = [thunkMiddleware, routerMiddleware(history)];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = applyMiddleware(...middlewareList);

// Apply the Redux DevTools compose function to the store creation
export const store = createStore(
  createAppReducers(history),
  composeEnhancers(middleware)
);