import { Spinner } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { IAppState } from 'types/appState.type';
import styles from './SiteLoader.module.scss';

export interface ISiteLoaderProps extends IAppState {}

export const SiteLoader = (
	props: React.PropsWithChildren<ISiteLoaderProps>
) => {
	const { projectState, collectionState, tourState, children } = props;
	const [loading, setloading] = useState(false);

	useEffect(() => {
		if (
			projectState.isLoading ||
			collectionState.isLoading ||
			tourState.isLoading
		) {
			setloading(true);
		} else if (loading) {
			setloading(false);
		}
	}, [
		projectState.isLoading,
		collectionState.isLoading,
		tourState.isLoading,
		loading,
	]);

	return (
		<>
			{loading && (
				<div className={styles.loader}>
					<Spinner label="Loading..." />
				</div>
			)}
			{children}
		</>
	);
};
