import React, { useCallback } from 'react';
import { IAppState } from '../../types/appState.type';
import { globalPaths } from 'utils/dataClient';
import { DefaultButton, Toggle, IIconProps, Icon, Link } from '@fluentui/react';
import { captureTrialClick } from 'utils/capturePageView';
import { IButtonsDispatch } from 'types/buttons.type';
import CookieConsentBannerContainer from 'common/cookie-banner/CookieConsentBannerContainer';
import { Collection, Tour, SiteLogo, PageViewBody, TrialClickInfo, TourSection } from 'types/schema.type';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import { appInsights } from 'store/appStore';
import { setHideButtonUIMode } from 'actions/buttons.action';
import {
	getUrlForSectionAndStep,
	getNativeLongFormState,
} from 'utils/renderHelpers';
import { GetJsllIdPrefix } from 'utils/jsllTaggingUtils';

import styles from './Header.module.scss';
import generalStyles from 'styles/generalStyles.module.scss';
import microsoftLogoLight from './img/microsoftLogoLight.svg';
// LOGO here.

const screenExpandIcon: IIconProps = { iconName: 'FullScreen' };
const screenCollapseIcon: IIconProps = { iconName: 'BackToWindow' };

export interface IHeaderDispatch extends IButtonsDispatch {
	setIsLanding(isLanding: boolean): void;
}

export interface IHeaderPropsWithDispatch extends IHeaderDispatch, IAppState {}

export const HeaderComponent = (props: IHeaderPropsWithDispatch) => {
	const {
		tourState,
		collectionState,
		projectState,
		localeState,
		sectionState,
		stepState,
		buttonsState,
		history,
		router,
	} = props;

	const { darkMode, isLanding, project } = projectState;
	const { location } = router;
	const { fullscreenMode, hiddenButtonMode, hideButtonUI } =
		buttonsState.currentState;
	const { overrideCallback, overrideCallbackText } = tourState;
	const { selectedLocale, isRTLCulture, fallbackLocale } = localeState;
	const selectedSection = sectionState.selectedSection ? sectionState.selectedSection : new TourSection();
	const selectedSectionNumber = selectedSection.sectionNumber;
	const selectedStepNumber = stepState.selectedStep.stepNumber;

	const selectedCollection = collectionState.selectedCollection
		? collectionState.selectedCollection
		: new Collection();

	const selectedTourId = tourState.selectedTourId;
	const isNativeLongForm = getNativeLongFormState(props);

	const collectionNamespace = `collections/${selectedCollection.id}`;

	let namespaceString = isNativeLongForm ? 'tours' : 'scenarios';
	let nameSpaces = [projectNamespace];

	if (selectedCollection && selectedCollection.id) {
		nameSpaces.push(collectionNamespace);
	}

	let tourNamespace: any = undefined;

	if (selectedTourId) {
		tourNamespace = `${namespaceString}/${selectedTourId}`;
		nameSpaces.push(tourNamespace);
	}

	const { t } = useTranslation(nameSpaces);

	const selectedTour = tourState.selectedTour
		? tourState.selectedTour
		: new Tour();

	let siteLogo: SiteLogo = selectedCollection.siteLogo;
	let siteLogoText = t(`${collectionNamespace}:${siteLogo.text}`);
	if (!siteLogo.image) {
		siteLogo = project.siteLogo;
		siteLogoText = t(`${siteLogo.text}`);
	}

	const tourTitle = tourNamespace
		? t(`${tourNamespace}:${selectedTour.title}`)
		: '';
	const titleSuffix = t(`${project.tourTitleSuffix}`);

	const getJsllPrefixCallback = useCallback((props, t): string => {
		return GetJsllIdPrefix(props, t);
	}, []);

	const screenModeToggle = () => {
		var hideUI = hideButtonUI;
		if (fullscreenMode) {
			props.setFullscreenMode(false);
			props.setIsLanding(true);
			hideUI = false;
		} else {
			hideUI = true;
			props.setFullscreenMode(true);
		}

		//If we are changing button State - store a new history entry to store new state.
		var newButtonState = {
			fullScreenButton: !fullscreenMode,
			hiddenModeToggled: hiddenButtonMode,
			hideUIModeToggled: hideUI,
		};
		history.push(
			getUrlForSectionAndStep(
				location?.pathname!,
				selectedSectionNumber,
				selectedStepNumber
			),
			newButtonState
		);
	};

	const hiddenButtonToggle = (
		event: React.MouseEvent<HTMLElement>,
		checked?: boolean
	) => {
		var updatedMode = !hiddenButtonMode;
		var hideUI = hideButtonUI;
		if (fullscreenMode) {
			props.setHiddenButtonMode(updatedMode);
			hideUI = true;
		} else {
			//Should be possible to click when not in FullScreen...
			updatedMode = false;
			props.setHiddenButtonMode(false);
			hideUI = false;
		}

		//If we are changing button State - store a new history entry to store new state.
		var newButtonState = {
			fullScreenButton: fullscreenMode,
			hiddenModeToggled: updatedMode,
			hideUIModeToggled: hideUI,
		};
		history.push(
			getUrlForSectionAndStep(
				location?.pathname!,
				selectedSectionNumber,
				selectedStepNumber
			),
			newButtonState
		);
	};

	const headerOnMouseEnter = () => {
		if (fullscreenMode) {
			setHideButtonUIMode(false);
		}
	};

	const getShortFormHeader = () => {
		let siteLogo: SiteLogo = selectedCollection.siteLogo;

		console.log("Current siteLogo:", siteLogo);

		let siteLogoText = t(`${collectionNamespace}:${siteLogo.text}`);

		console.log("Current siteLogoText:", siteLogoText);
		
		if (!siteLogo.image) {
			siteLogo = project.siteLogo;
			siteLogoText = t(`${siteLogo.text}`);
		}

		const actionUrl = overrideCallback
			? overrideCallback
			: selectedTour.actionUri!;

		const headerButtonId = "header-action-button";

		const buttonContent = overrideCallbackText
			? overrideCallbackText
			: tourNamespace
				? t(`${tourNamespace}:${selectedTour.actionButton}`)
				: '';

		function clickEvent() {

			if (selectedTour.captureTrialClick) {

				const sectionName = t(
					`${namespaceString}/${selectedTourId}:${selectedSection.sectionName}`
				);

				const selectedTourTitle = t(
					`${namespaceString}/${selectedTourId}:${selectedTour.title}`
				);

				let trialClickInfo: TrialClickInfo = {
					clickTitle: buttonContent,
					clickName: headerButtonId,
					stepNumber: selectedStepNumber.toString(),
					sectionNumber: selectedSectionNumber.toString(),
					sectionName: sectionName
				};

				captureTrialClick(selectedTourTitle, trialClickInfo);
            }

			appInsights.trackEvent({
				name: 'Call to Action',
				properties: {
					name: 'topRightCta',
					link: actionUrl,
					selectedLocale,
					selectedTourId,
					selectedSectionNumber,
					selectedStepNumber,
				},
			});
		}



		return (
			<>
				<CookieConsentBannerContainer locale={selectedLocale} />
				<header
					className={`${styles.headerShort} ${
						darkMode ? styles.headerShortDark : ''
					}`}
					dir={isRTLCulture ? 'rtl' : 'ltr'}
					lang={fallbackLocale}
				>
					<div className={`${styles.logoContainer}`}>
						<a
							className={`${styles.imageContainer}`}
							href={siteLogo.link}
							target="_blank"
							tabIndex={0}
							rel="noopener noreferrer"
							aria-label={siteLogoText}
						>
							<img
								alt={siteLogoText}
								className={`${styles.image}`}
								src={tourTitle === "Microsoft 365 Copilot for Service"
									? microsoftLogoLight
									: `${globalPaths.baseUrl}/${darkMode ? siteLogo.imageDark : siteLogo.image}`}
							/>
						</a>
						{selectedTour && (
                            <h1
								role="heading" aria-level={1}
								className={`${styles.demoTitle} ${
									darkMode ? styles.titleDark : styles.titleLight
								}`}
                            >{`${tourTitle} ${titleSuffix}`}</h1>
						)}
					</div>
					{selectedTour && selectedTour.actionButton && !isLanding && (
						<div className={`${styles.buttonContainer}`}>
							<Link
								id={headerButtonId}
								name={buttonContent}
								tabIndex={0}
								href={actionUrl}
								onClick={clickEvent}
								className={`${styles.button} ${
									darkMode ? styles.buttonDark : styles.buttonLight
								}`}
								target="_blank"
							>
								{buttonContent}
								<Icon
									iconName={
										isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'
									}
									className={`${generalStyles.ChevronStyle}`}
								></Icon>
							</Link>
						</div>
					)}
				</header>
			</>
		);
	};

	const getLongFormHeader = () => {
		return (

			<div
				className={`
                ${fullscreenMode ? styles.tourFullScreen : ''} 
                ${fullscreenMode && hideButtonUI && selectedTourId !== 'virtual-appointments' ? styles.tourUIHidden : ''} 
                `}

				onMouseEnter={() => headerOnMouseEnter()}
			>
				<CookieConsentBannerContainer locale={selectedLocale} />
				<div
					className={styles.header}
					onMouseEnter={() => headerOnMouseEnter()}
				>
					{fullscreenMode ? (
						<div
							className={`${styles.titleContainer} ${styles.emptyContainer}`}
						/>
					) : (
						<div className={styles.titleContainer}>
							<img
								alt={siteLogoText}
								className={styles.logoIcon}
								src={`${globalPaths.baseUrl}/${
									darkMode ? siteLogo.imageDark : siteLogo.image
								}`}
							/>
							<div className={styles.title}>
								{tourTitle} {titleSuffix}
							</div>
						</div>
					)}
					{!projectState.isLanding && (
						<div className={styles.buttons}>
							{fullscreenMode && (
								<Toggle
									id={getJsllPrefixCallback(props, t) + '-demoMode-toggled'}
									className={styles.demoModeToggle}
									data-bi-name={'DemoModeToggle'}
									offText={t(project.demoToggleOffLabel)}
									onText={t(project.demoToggleOnLabel)}
									checked={hiddenButtonMode}
									disabled={selectedTourId == 'virtual-appointments' ? false : hideButtonUI}
									onClick={(
										event: React.MouseEvent<HTMLElement>,
										checked?: boolean
									) => {
										hiddenButtonToggle(event, checked);
									}}
									onMouseEnter={() => headerOnMouseEnter()}
								/>
							)}

									{/* <button>CTA!!</button> */}

							<DefaultButton
								id={
									getJsllPrefixCallback(props, t) +
									'-screenMode-' +
									(fullscreenMode ? 'standard-click' : 'fullscreen-click')
								}
								className={styles.displayModeButton}
								text={
									fullscreenMode
										? t(project.standardModeLabel)
										: t(project.fullscreenModeLabel)
								}
								data-bi-name={'ScreenModeButton'}
								onClick={screenModeToggle}
								disabled={selectedTourId == 'virtual-appointments' ? false : hideButtonUI}
								allowDisabledFocus
								iconProps={
									fullscreenMode ? screenCollapseIcon : screenExpandIcon
								}
								onMouseEnter={() => headerOnMouseEnter()}
							/>
						</div>
					)}
				</div>
			</div>
		);
	};

	const generateAppropriateHeader = () => {
		if (isNativeLongForm) {
			return getLongFormHeader();
		} else {
			return getShortFormHeader();
		}
	};

	return generateAppropriateHeader();
};
