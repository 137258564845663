import React from 'react';
import { Spinner } from '@fluentui/react';
import styles from './Loading.module.scss';

export const LoadingComponent = () => {
	return (
		<div className={styles.loader}>
			<Spinner label="Loading..." />
		</div>
	);
};
