import React, { Component } from 'react';
import shareGroupStyles from './ShareGroup.module.scss';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { DefaultButton, Icon, Link } from '@fluentui/react';


interface IShareGroupProps {
    buttonText: string;
    emailEntryText: string;
    emailSubjectText: string;
    returnButtonClick: () => void;
    collectionName: string;
    collectionButtonClick: () => void;
}

export default class ShareGroupComponent extends Component<IShareGroupProps, {}> {
    private showGroup: boolean = true;

    constructor(props: IShareGroupProps) {
        super(props);
    }



    generateTourURL() {
        return window.location.href.split('/').slice(0, -2).join('/');
    }

    generateHref(emailSubject: string) {
        const message = encodeURIComponent(emailSubject);
        return `mailto:?subject=${message}&body=${this.generateTourURL()}`;
    }

    render() {

        const
            {
                buttonText,
                returnButtonClick,
                emailEntryText,
                emailSubjectText,
                collectionButtonClick,
                collectionName
            }
                = this.props;

        let shareId = "guidedTourShareButton"

        let emailLinkId = "guidedTourEmailLink"


        let menuProps: IContextualMenuProps = {
            shouldFocusOnMount: true,
            items: [
                {
                    id: emailLinkId,
                    key: 'emailClick',
                    name: emailEntryText,
                    href: this.generateHref(emailSubjectText),
                    target: '_blank',                    
                },
            ],
        };

        return this.showGroup && (
            <div className={`${shareGroupStyles.buttonGroupStyle}`}>
                <Link onClick={returnButtonClick}>
                    <Icon
                        iconName={`HomeSolid`}
                        className={`${shareGroupStyles.backButtonIconStyle}`}
                        aria-label='Return to Home'
                    ></Icon>
                </Link>
                <span>|</span>
                <Link onClick={collectionButtonClick}
                    className={`${shareGroupStyles.collectionButtonStyle}`}
                >
                    { collectionName }
                </Link>
                <DefaultButton
                    className={`${shareGroupStyles.shareButtonStyle}`}
                    menuProps={menuProps}
                    text={buttonText}
                    id={shareId}
                    onRenderMenuIcon={() => null} //Prevent dropdown chevron being added.
                    ariaLabel='Guided Tour Collection Home'
                >
                    <Icon
                        iconName={`ShareIOS`}
                        className={`${shareGroupStyles.shareButtonIconStyle}`}
                    ></Icon>
                </DefaultButton>
            </div>
        );
    }
};
