import React from 'react';
//import { css, StyleSheet } from 'aphrodite/no-important';
import { IAppState } from 'types/appState.type';
import { Icon, Link } from '@fluentui/react';
import { globalPaths } from 'utils/dataClient';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import generalStyles from 'styles/generalStyles.module.scss';

import styles from './TourEndCta.module.scss';

//const styles = StyleSheet.create({
//    endDemoCtaContainer: {
//        flex: 1,
//        display: 'flex',
//        flexDirection: 'row',
//        justifyContent: 'center',
//        alignItems: 'flex-start',
//        paddingBottom: '79px',
//        height: 'auto',
//        flexWrap: 'wrap',
//    },
//    endDemoCta: {
//        display: 'flex',
//        flexDirection: 'column',
//        justifyContent: 'flex-start',
//        alignItems: 'center',
//        marginTop: '88px',
//        marginBottom: '',
//        marginLeft: '15px',
//        marginRight: '15px',
//        cursor: 'pointer',
//        color: 'black',
//        width: '410px',
//    },
//    icon: {
//        marginBottom: '16px',
//        height: '48px',
//    },
//    link: {
//        textDecoration: 'none',
//        fontWeight: 'bold',
//        padding: '0',
//        fontSize: '16px',
//        lineHeight: '19px',
//        cursor: 'pointer',
//    },
//});

export interface ITourEndCta extends IAppState {
	tourId: string;
}

export const TourEndCtaComponent = (props: ITourEndCta) => {
	const { tourState, localeState } = props;
	const { selectedTour } = tourState;
	const { isRTLCulture } = localeState;

	const selectedScenarioId = props.tourId
		? props.tourId
		: tourState.selectedTourId;
	const scenarioNamespace = `scenarios/${selectedScenarioId}`;
	const { t } = useTranslation([projectNamespace, scenarioNamespace]);

	return selectedTour && selectedTour.secondaryCTA.length > 0 ? (
		<div className={styles.endDemoCtaContainer}>
			{selectedTour.secondaryCTA.map((cta, index) => {
				const jsllId = `demoend-cta${index}`;
				const ctaText = t(`${scenarioNamespace}:${cta.cta}`);

				return (
					<Link
						id={jsllId}
						data-bi-name={jsllId}
						className={`${styles.endDemoCta} ${styles.link}`}
						key={index}
						tabIndex={0}
						target="_blank"
						href={cta.ctaAction}
					>
						<img
							className={styles.icon}
							src={`${globalPaths.baseUrl}/${cta.icon}`}
							alt={ctaText}
						/>
						<div className={styles.link}>
							{ctaText}
							<Icon
								iconName={
									isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'
								}
								className={generalStyles.ChevronStyle}
							></Icon>
						</div>
					</Link>
				);
			})}
		</div>
	) : (
		<></>
	);
};
