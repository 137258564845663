import { parse } from 'query-string';
import { IQueryObj } from 'types/query.type';
import { Location } from 'history';

export const GetQueryParameters = (str: string): any => {
  const splitStr = str.split('?');
  let query = '';
  for (let i = 1; i < splitStr.length; i++) {
    query += '?' + splitStr[i];
  }

  if (!query || query.length === 0) {
    return;
  }

  // @ts-ignore
  return (query || document.location.search)
    .replace(/(^\?)/, '')
    .split('&')
    .map(
      // @ts-ignore
      function (n) {
        const splitN = n.split('=');
        let iN = '';
        for (let i = 1; i < splitN.length; i++) {
          iN += (i > 1 ? '=' : '') + splitN[i];
        }
        // @ts-ignore
        return (n = splitN), (this[n[0]] = iN), this;
      }.bind({})
    )[0];
};

export const GetCallbackUrl = (
	location: Location,
	localeHexCode?: string
): string => {
  let actionUrl = '';

  if (location) {
    const parsedParams: any = parse(location!.search);
    const queryObj = parsedParams as IQueryObj;
    if (queryObj && queryObj.callback) {
        actionUrl = decodeURI(queryObj.callback);

        if (localeHexCode !== '' && actionUrl.endsWith('&clcid=')) {
            actionUrl += localeHexCode;
        }
    }
  }

  return actionUrl;
};

export const GetCallbackText = (location: Location): string => {
  let actionUrl = '';

  if (location) {
    const parsedParams: any = parse(location!.search);
    const queryObj = parsedParams as IQueryObj;
    if (queryObj && queryObj.callback) {
        actionUrl = decodeURI(queryObj.callbackText);
    }
  }

  return actionUrl;
};
