import { initializeMunchkin } from './munchkin/munchkin';
import { initializeOneDS } from './oneds/oneds';
// load all non essential telemetry scripts within this function.
export function addTelemetryScripts() {
	initializeMunchkin();
}
// load OneDS scripts within this function.
export function addOneDSScripts() {
	initializeOneDS();
}

export const replaceNBSP = (value: string): string => {
	return value.replace(/\u00a0/g, ' ');
};
