import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createAction } from 'typesafe-actions';
import { DataClient } from '../utils/dataClient';

export const setTour = createAction('setTour', (action) => {
	return (tourId: string) => action({ tourId });
});

export const setTourCallback = createAction('setTourCallback', (action) => {
        return (callback: string) =>
            action({ callback: decodeURIComponent(callback) });
});

export const setTourCallbackText = createAction(
    'setTourCallbackText',
    (action) => {
        return (callbackText: string) => action({ callbackText });
    }
);

export const setTourImagesLoaded = createAction(
	'setTourImagesLoaded',
  (action) => {
      return (tourId: string) => action({ tourId });
  }
);

export const setTourRunning = createAction(
	'setTourRunning',
	(action) => {
		return (tourRunning: boolean) => action({ tourRunning });
	}
);

export const loadTourStart = createAction('loadTour/start', (action) => {
	return (tourId: string) => action({ tourId });
});

export const loadTourFail = createAction('loadTour/Fail', (resolve: any) => {
    return (error: { tourId: string; e: any }) => {
      return resolve(error);
    };
});

export const loadTourSuccess = createAction(
  'loadTour/Success',
  (resolve: any) => {
    // parse results
		return (results: {
			tourId: string;
			result: string;
			localeHexCode: string;
			useLocaleImages: boolean;
		}) =>
      resolve({
          tourId: results.tourId,
          result: JSON.parse(results.result),
          localeHexCode: results.localeHexCode,
          useLocaleImages: results.useLocaleImages,
      });
  }
);

export const loadTour: ActionCreator<
  ThunkAction<Promise<any>, any, void, any>
> =
	(tourId: string, isNativeLongForm: boolean, localeHexCode: string) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
  try {
    // API call
    const client = new DataClient();
      dispatch(loadTourStart(tourId));

			let fileType = 'scenario';
      if (isNativeLongForm) {
          fileType = 'tour';
      }

      var useLocaleImages = false;
      try {
				const localeImages: any = await client.hasLocaleImages(
					fileType,
					tourId,
					localeHexCode
				);

          if (localeImages) {
              useLocaleImages = true;
          }
			} catch (e) {
          //Catch error and fallback to 
      }
      
      const result: any = await client.getFile(tourId, fileType);
			return dispatch(
				loadTourSuccess({ tourId, result, localeHexCode, useLocaleImages })
			);
  } catch (e) {
      return dispatch(loadTourFail({ tourId, e }));
  }
};
