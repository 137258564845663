import { connect } from 'react-redux';
import { loadProject } from 'actions/project.action';
import { IAppState } from 'types/appState.type';
import { ProjectComponent } from './ProjectComponent';
import {
	IProjectDispatchProps,
	IProjectProps,
	IProjectStateProps,
} from 'types/project.type';
import { Dispatch } from 'react';

const mapStateToProps = (
	state: IAppState,
	props: IProjectProps
): IProjectStateProps => {
	return {
		...state,
		...props,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<any>): IProjectDispatchProps => {
	return {
		loadProject: (isNativeLongForm: boolean) => {
			dispatch(loadProject(isNativeLongForm));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectComponent);
