import { connect } from 'react-redux';
import { LegacyComponent } from './LegacyComponent';
import { IAppState } from 'types/appState.type';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state: IAppState): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LegacyComponent)
);
