import React, { useEffect } from 'react';
import { ITourStateWithDispatch } from 'types/tour.type';
import { IButtonHistoryState } from 'types/buttons.type';
import { useTranslation } from 'react-i18next';
import {
	getUrlForSectionAndStep,
	getNativeLongFormState,
} from 'utils/renderHelpers';
import LeftPanelContainer from 'components/leftpanel/LeftPanelContainer';
import RightPanelContainer from 'components/rightpanel/RightPanelContainer';
import ProjectComponentContainer from 'components/project/ProjectComponentContainer';
import CollectionLoadingComponentContainer from 'components/collectionLoading/CollectionLoadingComponentContainer';
import LocaleComponentContainer from 'components/locale/LocaleComponentContainer';
import PopupComponentContainer from 'components/popup/PopupComponentContainer';
import { TourLoadingComponent } from 'components/tourLoading/TourLoadingComponent';

import styles from './LongFormTour.module.scss';
import { initializeIcons } from '@fluentui/react/lib/Icons';

// Fixes missing chevron in dropdown menu
initializeIcons(/* optional base url */);

export const LongFormTour = (props: ITourStateWithDispatch) => {
	const {
		projectState,
		collectionState,
		localeState,
		tourState,
		stepState,
		sectionState,
		match,
		history,
		router,
		buttonsState,
	} = props;

	const { sectionId, stepId } = match.params;
	const { location } = router;
	const collectionId = match.params.collectionId
		? match.params.collectionId.toLowerCase()
		: '';
	const selectedLocale = match.params.collectionId
		? match.params.selectedLocale.toLowerCase()
		: '';
	const tourId = match.params.tourId ? match.params.tourId.toLowerCase() : '';

	const { isLoading, selectedTour } = tourState;
	const { fullscreenMode, hiddenButtonMode, hideButtonUI } =
		buttonsState.currentState;
	const { project, projectLoaded, isLanding } = projectState;
	const { selectedStep } = stepState;
	const { selectedSection } = sectionState;
	const { selectedCollection } = collectionState;
	const { localeHexCode } = localeState;

	const isNativeLongForm = getNativeLongFormState(props);

	const { t } = useTranslation();

	useEffect(() => {
		if (isLanding) {
			props.setIsLanding(false);
		}
	}, [isLanding, props]);

	useEffect(() => {
		if (location && location.state !== undefined) {
			let buttonHistory = location.state as IButtonHistoryState;

			props.setHiddenButtonMode(buttonHistory.hiddenModeToggled);
			props.setFullscreenMode(buttonHistory.fullScreenButton);
			props.setHideButtonUIMode(buttonHistory.hideUIModeToggled);
		}
	}, [location]);

	useEffect(() => {
		if (
			localeState.selectedLocale &&
			projectLoaded &&
			selectedCollection &&
			selectedTour
		) {
			let urlSectionId = parseInt(sectionId);
			let urlStepId = parseInt(stepId);

			if (!isLoading && selectedTour) {
				if (
					urlSectionId !== undefined &&
					urlSectionId !== null &&
					sectionState.selectedSection &&
					urlSectionId !== sectionState.selectedSection.sectionNumber &&
					selectedTour.sections[urlSectionId - 1]
				) {
					props.setSection(selectedTour.sections[urlSectionId - 1]);
				}

				if (
					urlStepId !== undefined &&
					urlStepId !== null &&
					urlStepId !== selectedStep.stepNumber &&
					selectedTour.sections[urlSectionId - 1] &&
					selectedTour.sections[urlSectionId - 1].steps[urlStepId - 1]
				) {
					props.setStep(
						selectedTour.sections[urlSectionId - 1].steps[urlStepId - 1]
					);
				}
			}
		}
	}, [
		props,
		projectLoaded,
		isLoading,
		selectedTour,
		localeState.selectedLocale,
		selectedCollection,
		sectionId,
		stepId,
		sectionState.selectedSection,
		selectedStep.stepNumber,
	]);

	const getButtonState = (): IButtonHistoryState => {
		return {
			fullScreenButton: fullscreenMode,
			hiddenModeToggled: hiddenButtonMode,
			hideUIModeToggled: hideButtonUI,
		};
	};

	const incrementStep = () => {
		let currentStepNumber = selectedStep.stepNumber;
		let currentSectionNumber = selectedSection.sectionNumber;

		if (currentSectionNumber == 0 || currentStepNumber == 0) {
			return;
		}

		//Prevent index outside of array.
		if (currentStepNumber >= selectedSection.stepCount) {
			if (currentSectionNumber < selectedTour!.sectionCount) {
				let nextSection = selectedTour!.sections[currentSectionNumber];
				props.setSection(nextSection);

				//Have to add 1 to sectionNumber (as URL isn't 0-based indexing for readability)
				history.push(
					getUrlForSectionAndStep(
						location?.pathname!,
						currentSectionNumber + 1,
						1
					),
					getButtonState()
				);
			} else {
				props.setShowCallToAction(true);
			}

			return;
		}

		let nextStep = selectedSection.steps[currentStepNumber];
		props.setStep(nextStep);

		//Have to add 1 to currentStepNumber (as URL isn't 0-based indexing for readability)
		history.push(
			getUrlForSectionAndStep(
				location?.pathname!,
				currentSectionNumber,
				currentStepNumber + 1
			),
			getButtonState()
		);
	};

	const decrementStep = () => {
		let currentStepNumber = selectedStep.stepNumber;
		let currentSectionNumber = selectedSection.sectionNumber;

		if (currentSectionNumber == 0 || currentStepNumber == 0) {
			return;
		}

		//Prevent negative numbers.
		if (currentStepNumber - 2 < 0) {
			if (currentSectionNumber >= 2) {
				//Reload for last step of previous section.
				let lastSection = selectedTour!.sections[currentSectionNumber - 2];
				let lastStep = lastSection.steps[lastSection.stepCount - 1];

				props.setSection(lastSection);
				props.setStep(lastStep);

				//Only subtract 1 from sectionNumber and use raw StepCount (as URL isn't 0-based indexing for readability)
				history.push(
					getUrlForSectionAndStep(
						location?.pathname!,
						currentSectionNumber - 1,
						lastSection.stepCount
					),
					getButtonState()
				);
			}
			return;
		}

		let prevStep = selectedSection.steps[currentStepNumber - 2];

		//Have to subtract 1 from currentStepNumber (as URL isn't 0-based indexing for readability)
		history.push(
			getUrlForSectionAndStep(
				location?.pathname!,
				currentSectionNumber,
				currentStepNumber - 1
			),
			getButtonState()
		);

		props.setStep(prevStep);
	};

	if (!localeState.selectedLocale) {
		return (
			<LocaleComponentContainer
				locale={selectedLocale}
			></LocaleComponentContainer>
		);
	}

	if (!projectLoaded) {
		return (
			<>
				<ProjectComponentContainer
					locale={selectedLocale}
				></ProjectComponentContainer>
			</>
		);
	}

	if (
		!selectedCollection ||
		selectedCollection.id !== collectionId ||
		!selectedCollection.loaded
	) {
		return (
			<>
				<CollectionLoadingComponentContainer
					collectionId={collectionId}
					locale={selectedLocale}
					isNativeLongForm={isNativeLongForm}
					isBatchLoading={false}
				></CollectionLoadingComponentContainer>
			</>
		);
	}

	if (!selectedTour || selectedTour.id !== tourId) {
		return (
			<>
				<TourLoadingComponent
					tourId={tourId}
					tourState={tourState}
					isNativeLongForm={isNativeLongForm}
					set={true}
					loadTour={() => {
						props.loadTour(tourId, isNativeLongForm, localeHexCode);
					}}
					setTour={(tourId: string) => {
						props.setTour(tourId);
					}}
					localeHexCode={localeHexCode}
				></TourLoadingComponent>
			</>
		);
	}

	document.title = t(project.title);

	let urlSectionId = parseInt(sectionId);
	let urlStepId = parseInt(stepId);

	if (!isLoading && selectedTour) {
		if (
			urlSectionId !== undefined &&
			urlSectionId !== null &&
			sectionState.selectedSection &&
			urlSectionId !== sectionState.selectedSection.sectionNumber &&
			selectedTour.sections[urlSectionId - 1]
		) {
			return <></>;
		}

		if (
			urlStepId !== undefined &&
			urlStepId !== null &&
			urlStepId !== selectedStep.stepNumber &&
			selectedTour.sections[urlSectionId - 1] &&
			selectedTour.sections[urlSectionId - 1].steps[urlStepId - 1]
		) {
			return <></>;
		}
	}

	const updateHistoryForNavigationState = (
		currentSection: number,
		currentStep: number
	) => {
		if (currentSection == 0 || currentStep == 0) {
			return;
		}

		//Update url after section selection change.
		history.push(
			getUrlForSectionAndStep(location?.pathname!, currentSection, currentStep),
			getButtonState()
		);
	};

	return (
		<div
			className={`
                ${fullscreenMode ? styles.tourFullScreen : ''} 
                ${fullscreenMode && hideButtonUI ? styles.tourUIHidden : ''}
                `}
		>
			<div
				className={styles.tourContainer}
				onMouseEnter={() => {
					if (fullscreenMode) {
						props.setHideButtonUIMode(false);
					}
				}}
			>
				<div className={styles.body}>
					<LeftPanelContainer
						incrementStep={incrementStep}
						decrementStep={decrementStep}
						updateHistoryForNavigationState={updateHistoryForNavigationState}
					/>
					<RightPanelContainer
						incrementStep={incrementStep}
						decrementStep={decrementStep}
						updateHistoryForNavigationState={updateHistoryForNavigationState}
					/>
				</div>
				<PopupComponentContainer />
			</div>
		</div>
	);
};
