import { Tour, TaskGrouping, TourSection, TourStep } from 'types/schema.type';

export const IsTourLoadedFor = (id: string, tours: Tour[]): boolean => {
    for (const tour of tours) {
        if (tour.id.toLowerCase() === id.toLowerCase()) {
            return tour.loaded;
        }
    }
    return false;
};

export const buildTour = (
	payload: any,
	localeHexCode: string,
	useLocaleImages: boolean
): Tour => {
    const newTour: Tour = AddLocaleToLinks(localeHexCode, payload);

    //Makes sure we set TaskGrouping to default for all those not using it.
	newTour.scenarioTaskGrouping =
		newTour.scenarioTaskGrouping != undefined
			? newTour.scenarioTaskGrouping
			: new TaskGrouping();
    newTour.loaded = true;
    newTour.loading = false;
    newTour.useLocaleImages = useLocaleImages;
    newTour.sections = buildSections(newTour.sections);

    return newTour;
};

export const buildSections = (sections: TourSection[]): TourSection[] => {
    return sections.map((section, index) => {
        return {
            ...section,
            steps: buildSteps(section.steps),
        };
    });
};

export const buildSteps = (steps: TourStep[]): TourStep[] => {
    return steps.map((step, index) => {
        return Object.assign(new TourStep(), step);
    });
};

export const AddLocaleToLinks = (
	localeHexCode: string,
	newScenario: Tour
): Tour => {
    //If we don't have enough to proceed - just dump out.
	if (!localeHexCode || !newScenario) return newScenario;

    //If ActionURI ends with clcid - add hexCode to the link.
	if (newScenario.actionUri && newScenario.actionUri.endsWith('&clcid=')) {
        newScenario.actionUri += localeHexCode;
    }

    //If we have modal - check actions.
    if (newScenario.callToActionModal) {

        //If PrimaryAction ends with clcid  - add hexCode to the link.
		if (
			newScenario.callToActionModal.primaryAction &&
			newScenario.callToActionModal.primaryAction.endsWith('&clcid=')
		) {
            newScenario.callToActionModal.primaryAction += localeHexCode;
        }

        //Not required yet - but rigging up SecondaryAction anyway.
		if (
			newScenario.callToActionModal.secondaryAction &&
			newScenario.callToActionModal.secondaryAction.endsWith('&clcid=')
		) {
            newScenario.callToActionModal.secondaryAction += localeHexCode;
        }
    }

    //If we have SecondaryCTA - check for clcid links.
    if (newScenario.secondaryCTA && newScenario.secondaryCTA.length > 0) {

        for (const cta of newScenario.secondaryCTA) {
            //If any secondaryCTA action ends with clcid - append hexCode.
			if (cta.ctaAction.endsWith('&clcid=')) {
                cta.ctaAction += localeHexCode;
            }
        }
    }

    //Return Scenario with CLCID code added to any appropriate links.
    return newScenario;
};
