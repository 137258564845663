import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { IAppState } from 'types/appState.type';
import {
	ICoachmarkProps,
	COACHMARK_PROXIMITY_OFFSET,
	TEACHINGBUBBLE_PROXIMITY_OFFSET,
} from 'types/coachmark.type';
import { TourStep, TourSection, Tour, CoachmarkState } from 'types/schema.type';
import { GetJsllIdPrefix } from 'utils/jsllTaggingUtils';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import { ITourNavigation } from 'types/tour.type';
import {
	canAdvanceNext,
	canNavigatePrevious,
	renderBulletListToListItem,
	renderMultilineToParagraphs,
} from 'utils/renderHelpers';
import { appInsights } from 'store/appStore';
import {
	DirectionalHint,
	RectangleEdge,
	TeachingBubble,
	IButtonProps,
} from '@fluentui/react';
import { Beak } from 'office-ui-fabric-react/lib/components/Coachmark/Beak/Beak';
import styles from './Coachmark.module.scss';

export interface ICoachmarkDispatch {
	setTour(selectedTour: string): void;
	setStep(selectedStep: TourStep): void;
	setSection(selectedSection: TourSection): void;
	setCoachmarkClicked(coachmarkClicked: boolean): void;
	setHideButtonUIMode(hideButtonUI: boolean): void;
}

export interface ICoachmarkPropsWithDispatch
	extends ICoachmarkDispatch,
		ICoachmarkProps,
		ITourNavigation,
		IAppState {}

const beakColor = 'rgb(0, 120, 212)';

export const Coachmark = (props: ICoachmarkPropsWithDispatch) => {
	const {
		projectState,
		tourState,
		stepState,
		sectionState,
		buttonsState,
		getTargetButton,
		beakDirection,
	} = props;

	const [coachState, setCoachState] = useState(new CoachmarkState());
	const { project } = projectState;
	const { fullscreenMode, hiddenButtonMode, coachmarkClicked, hideButtonUI } =
		buttonsState.currentState;
	const { selectedStep } = stepState;
	const { selectedSection } = sectionState;

	const selectedTour = tourState.selectedTour
		? tourState.selectedTour
		: new Tour();

	const selectedTourId = selectedTour.id;

	let nameSpaces = [projectNamespace];

	let tourNamespace = '';
	if (selectedTourId) {
		tourNamespace = `tours/${selectedTourId}`;
		nameSpaces.push(tourNamespace);
	}
	const { t } = useTranslation(nameSpaces);

	const showPrev = canNavigatePrevious(props);
	const showNext = canAdvanceNext(props);
	let targetButton = getTargetButton();

	//Used for setTargetElementRect callback.
	const callbackState = useRef<CoachmarkState>();
	callbackState.current = coachState;

	//Set explicitly from AppState (local state wasn't persisting properly).
	coachState.showBubbleClick = coachmarkClicked;

	//Verify if TeachingBubble should be visible.
	const checkShowBubble = useCallback((): boolean => {
		//Only allow Bubble to be shown if one of the Bubble flags is set AND we are in FullScreen mode.
		return (
			(coachState.showBubbleClick || coachState.showBubbleHover) &&
			buttonsState.currentState.fullscreenMode
		);
	}, [buttonsState.currentState, coachState]);

	const registerMouseProximity = () => {
		//Save function so we can cleanup later.
		coachState.mouseProximityState.mouseMovementFunc = mouseFunc;

		//Add listener for MouseMove using function.
		window.document.addEventListener(
			'mousemove',
			coachState.mouseProximityState.mouseMovementFunc
		);

		//Delay slightly before grabbing current rect. Also, register for resize to update boundary.
		setTimeout(function () {
			setTargetElementRect();
			// When the window resizes we want to
			// get the bounding client rectangle.
			// Every time the event is triggered we want to
			// setTimeout and then clear any previous instances
			// of setTimeout.
			window.document.addEventListener('resize', updateBoundaryOnResize);
		}, 10);
	};

	const deregisterMouseProximity = () => {
		window.document.removeEventListener('resize', updateBoundaryOnResize);
		window.document.removeEventListener(
			'mousemove',
			coachState.mouseProximityState.mouseMovementFunc
		);
	};

	useEffect(() => {
		//On creation register for Proximty updates.
		registerMouseProximity();
		return () => {
			//On destruction clean them up.
			deregisterMouseProximity();
		};
	}, [deregisterMouseProximity, registerMouseProximity]);

	const determineCoachMarkAction = (e: any) => {
		//Hide Bubble since we clicked dot.
		setCoachState({
			...coachState,
			showBubbleClick: false,
			showBubbleHover: false,
		});

		//Record the dotClick event.
		appInsights.trackEvent({
			name: 'IndicatorClick',
		});

		//Advanced to the next Tour step.
		props.incrementStep();

		//Stop processing further handlers.
		e.preventDefault();
	};

	const getHintForBeakDirection = (): DirectionalHint => {
		//Bubble hinted in opposite direction of Coachmark Beak - so both beaks point the same way.
		switch (beakDirection) {
			default:
			case RectangleEdge.top: {
				return DirectionalHint.bottomCenter;
			}
			case RectangleEdge.bottom: {
				return DirectionalHint.topCenter;
			}
			case RectangleEdge.right: {
				return DirectionalHint.leftCenter;
			}
			case RectangleEdge.left: {
				return DirectionalHint.rightCenter;
			}
		}
	};

	const getClassNameForBeakDirection = (): string => {
		switch (beakDirection) {
			default:
			case RectangleEdge.bottom: {
				return styles.bubbleCalloutBottom;
			}
			case RectangleEdge.top: {
				return styles.bubbleCalloutTop;
			}
			case RectangleEdge.left: {
				return styles.bubbleCalloutLeft;
			}
			case RectangleEdge.right: {
				return styles.bubbleCalloutRight;
			}
		}
	};

	const genBeak = (): React.ComponentElement<any, Beak> => {
		var left = 0;
		var top = 0;

		//Beak direction set via Step JSON.
		var pointDirection = beakDirection;
		switch (beakDirection) {
			default:
			case RectangleEdge.bottom: {
				left = 6;
				top = 17;

				pointDirection = RectangleEdge.bottom;
				break;
			}
			case RectangleEdge.top: {
				left = 6;
				top = 3;

				//Handle Next Button special case.
				if (targetButton && targetButton.id === 'leftPanelButtons') {
					left += 40;

					//Handle Prev button placement.
					if (showPrev && showNext) {
						left += 110;
					}
				}

				pointDirection = RectangleEdge.top;
				break;
			}
			case RectangleEdge.left: {
				left = 3;
				top = 6;

				pointDirection = RectangleEdge.left;
				break;
			}
			case RectangleEdge.right: {
				left = 17;
				top = 6;

				pointDirection = RectangleEdge.right;
				break;
			}
		}

		return React.createElement(Beak, {
			left: left + 'px',
			top: top + 'px',
			right: undefined,
			bottom: undefined,
			direction: pointDirection,
			color: beakColor,
		});
	};

	//const triggerProgramaticTagClick = (tagName: string, advance: boolean) => {

	//    if (coachState.triggerAnchorRef.current) {

	//        let triggerRef = coachState.triggerAnchorRef.current;

	//        const clickHandler = () => {
	//            if (advance) {
	//                props.incrementStep();
	//            }
	//            else {
	//                props.decrementStep();
	//            }
	//        };
	//        triggerRef.onclick = clickHandler;

	//        triggerRef.setAttribute("data-bi-name", tagName);

	//        var evt = document.createEvent("MouseEvents");
	//        evt.initMouseEvent("click", true, true, window,
	//            0, 0, 0, 0, 0, false, false, false, false, 0, null);
	//        var allowDefault = triggerRef.dispatchEvent(evt);
	//    }
	//}

	const getTeachingBubble = () => {
		const step = selectedStep;
		const currentTourSection = selectedSection;

		const jsllPrefix = GetJsllIdPrefix(props, t);

		const prevNavJsllId = jsllPrefix + '-bubblePrevNav';
		const nextNavJsllId = jsllPrefix + '-bubbleNextNav';

		const bubbleOnMouseEnter = () => {
			if (fullscreenMode) {
				props.setHideButtonUIMode(true);
			}
		};

		const bubbleOnClick = () => {
			//If bubble clicked, set sticky mode.
			if (fullscreenMode) {
				props.setCoachmarkClicked(true);
				setCoachState({
					...coachState,
					showBubbleClick: true,
					showBubbleHover: false,
				});
			}
		};

		const prevNavLabel = t(project.previousNavLabel);
		const nextNavLabel = t(project.nextNavLabel);

		const prevButtonProps: IButtonProps = {
			id: prevNavJsllId,
			text: '< ' + prevNavLabel,
			elementRef: coachState.prevNavButton,
			onClick: () => {
				//triggerProgramaticTagClick(prevNavJsllId,false);
				props.decrementStep();
			},
		};

		const nextButtonProps: IButtonProps = {
			id: nextNavJsllId,
			text: nextNavLabel + ' >',
			elementRef: coachState.nextNavButton,
			onClick: () => {
				//triggerProgramaticTagClick(nextNavJsllId, true);
				props.incrementStep();
			},
		};

		var teachingBubble = <div></div>;

		//Only generate the TeachingBubble if we meet the needed conditions.
		if (checkShowBubble()) {
			teachingBubble = (
				<TeachingBubble
					headline={t(`${tourNamespace}:${selectedSection.sectionName}`)}
					secondaryButtonProps={showPrev ? prevButtonProps : undefined}
					primaryButtonProps={showNext ? nextButtonProps : undefined}
					isWide={false}
					hasSmallHeadline={true}
					hasCloseButton={true}
					onDismiss={(e) => onDismissTeachingBubble(e)}
					target="#markerButton"
					ref={coachState.mouseProximityState.teachingBubbleDivRef}
					calloutProps={{
						className: getClassNameForBeakDirection(),
						directionalHint: getHintForBeakDirection(),
						calloutMaxHeight: 300,
					}}
				>
					<p>
						Step {step.stepNumber} of {currentTourSection.stepCount}
					</p>
					<div className={styles.scrollableDetails}>
						{renderMultilineToParagraphs(step.stepDetails, t, tourNamespace)}

						{step.stepList && (
							<ul className={styles.bubbleStepList}>
								{renderBulletListToListItem(step.stepList, t, tourNamespace)}
							</ul>
						)}
					</div>
					{step.stepInstructions && (
						<p className={styles.stepInstructions}>
							{t(`${tourNamespace}:${selectedStep.stepInstructions}`)}
						</p>
					)}
				</TeachingBubble>
			);

			//Ensure Tracking Attribute set if necessary.
			if (showPrev && coachState.prevNavButton.current) {
				coachState.prevNavButton.current.setAttribute(
					'data-bi-name',
					'PrevButton'
				);
			}

			//Ensure Tracking Attribute set if necessary.
			if (showNext && coachState.nextNavButton.current) {
				coachState.nextNavButton.current.setAttribute(
					'data-bi-name',
					'NextButton'
				);
			}

			if (coachState.mouseProximityState.teachingBubbleDivRef.current) {
				var rootElement =
					coachState.mouseProximityState.teachingBubbleDivRef.current
						.rootElement.current;

				if (rootElement) {
					if (hideButtonUI) {
						rootElement.onmouseenter = bubbleOnMouseEnter;
					}

					rootElement.onclick = bubbleOnClick;
				}
			}
		}

		return teachingBubble;
	};

	const onDismissTeachingBubble = (event: any): void => {
		//Clear All bubble flags when intentionally dismissed.
		setCoachState({
			...coachState,
			showBubbleClick: false,
			showBubbleHover: false,
		});

		props.setCoachmarkClicked(false);

		//Reset Proximity state when we explicitly hide the bubble to ensure proper hover behavior.
		setCoachState((prevState) => {
			let updatedState = Object.assign({}, prevState);
			updatedState.mouseProximityState.currentProximityState = false;
			updatedState.mouseProximityState.lastProximityState = false;
			updatedState.mouseProximityState.mouseProximityOffset =
				COACHMARK_PROXIMITY_OFFSET;
			return updatedState;
		});
	};

	const getCoachmarkDot = () => {
		var classnames = styles.coachmarkPulsingDot;

		//Add class for Next Button styling.
		if (targetButton && targetButton.id === 'leftPanelButtons') {
			classnames += ' ' + styles.nextButtonPadding;
			//Add extra padding to handle Prev button (if exists)
			if (canAdvanceNext(props) && canNavigatePrevious(props)) {
				classnames += ' ' + styles.prevButtonPadding;
			}
		}

		//By default - show Pulsing-Coachmark.
		var coachmarkDot = (
			<a
				role="button"
				id={GetJsllIdPrefix(props, t) + '-bluedot'}
				aria-label="Coachmark"
				ref={coachState.mouseProximityState.coachmarkDotRef}
				href="/"
				onClick={(e) => determineCoachMarkAction(e)}
				className={classnames}
				data-bi-name={'blueDot'}
			>
				<span />
			</a>
		);

		if (checkShowBubble()) {
			//If clicked - change to non-pulsing Coachmark.
			coachmarkDot = (
				<a
					role="button"
					id={GetJsllIdPrefix(props, t) + '-bluedot'}
					href="/"
					aria-label="Coachmark"
					ref={coachState.mouseProximityState.coachmarkDotRef}
					onClick={(e) => determineCoachMarkAction(e)}
					data-bi-name={'blueDot'}
					className={styles.coachmarkDot}
				>
					<span />
				</a>
			);
		}

		return coachmarkDot;
	};

	//const getTriggerRef = () => {

	//    return <a id="tagClick" aria-label="tagging-surrogate" ref={coachState.triggerAnchorRef} />;
	//}

	const renderFinalResult = () => {
		var finalResult = <div></div>;
		//We only show if we aren't in Hidden mode and we have a target to "portal" on.

		var latestTarget = getTargetButton();
		if (latestTarget && !hiddenButtonMode && canAdvanceNext(props)) {
			finalResult = (
				<div className={styles.coachmarkDiv}>
					<div className={styles.beakAbsolute}>{genBeak()}</div>
					{getCoachmarkDot()}
					{getTeachingBubble()}
					{/*{getTriggerRef()}*/}
				</div>
			);

			finalResult = ReactDOM.createPortal(finalResult, latestTarget);
		}

		return finalResult;
	};

	/////////////////////////////////////////////////////////////////////
	// Repurposed Proximity Handling from FluentUI Coachmark component.//
	/////////////////////////////////////////////////////////////////////

	const toggleMouseHover = (): void => {
		var newOffset = coachState.mouseProximityState.mouseProximityOffset;
		//Only use different offset if going to showBubbleHover state. i.e. we currently aren't in it.
		if (!coachState.showBubbleHover) {
			newOffset = TEACHINGBUBBLE_PROXIMITY_OFFSET;
		}

		//Update showBubbleHover status to whatever new state we are switching to.
		setCoachState({
			...coachState,
			showBubbleHover: coachState.mouseProximityState.currentProximityState,
			showBubbleClick: coachState.showBubbleClick,
		});

		//Update the offset - allowing for larger offset for TeachingBubble state.
		setCoachState((prevState) => {
			let updatedState = Object.assign({}, prevState);
			updatedState.mouseProximityState.mouseProximityOffset = newOffset;
			return updatedState;
		});
	};

	const isInsideElement = (
		mouseX: number,
		mouseY: number,
		mouseProximityOffset: number
	): boolean => {
		if (mouseProximityOffset === undefined) {
			mouseProximityOffset = 0;
		}

		//If we don't have a bounding rect to work with - early exit.
		if (!coachState.mouseProximityState.targetElementRect) {
			return false;
		}

		//Depending on state - either the CoachmarkDot or TeachingBubble bounding rectangle.
		var targetRect = coachState.mouseProximityState.targetElementRect;

		//Check if the mouse pointer is inside the selected bounding rectangle (factoring in offset padding).
		return (
			mouseX > targetRect.left - mouseProximityOffset &&
			mouseX < targetRect.left + targetRect.width + mouseProximityOffset &&
			mouseY > targetRect.top - mouseProximityOffset &&
			mouseY < targetRect.top + targetRect.height + mouseProximityOffset
		);
	};

	const mouseFunc = useCallback(
		(e: MouseEvent) => {
			// Only take action if we are in proper mode.
			if (buttonsState.currentState.fullscreenMode) {
				var mouseY = e.clientY;
				var mouseX = e.clientX;
				setTargetElementRect();

				//Check if mouse is inside the proximity zone or not.
				var isMouseInProximity = isInsideElement(
					mouseX,
					mouseY,
					coachState.mouseProximityState.mouseProximityOffset
				);

				//If inside state changes - toggle Hover mode.
				if (
					isMouseInProximity !=
					coachState.mouseProximityState.currentProximityState
				) {
					coachState.mouseProximityState.currentProximityState =
						isMouseInProximity;
					toggleMouseHover();

					//Remove listerner after state change and re-add after timeout. Try to minimize flashing from rapid on/off toggling.
					window.document.removeEventListener(
						'mousemove',
						coachState.mouseProximityState.mouseMovementFunc
					);
					setTimeout(function () {
						window.document.addEventListener(
							'mousemove',
							coachState.mouseProximityState.mouseMovementFunc
						);
					}, 250);
				}
			}
		},
		[
			buttonsState.currentState.fullscreenMode,
			coachState.mouseProximityState.currentProximityState,
			coachState.mouseProximityState.mouseMovementFunc,
			coachState.mouseProximityState.mouseProximityOffset,
			isInsideElement,
			toggleMouseHover,
		]
	);

	const setTargetElementRect = () => {
		var localState = callbackState.current;

		if (!localState) return;

		//If we haven't enabled hover - then use the Coachmark Dot as the reference bounding rectangle for mouse proximity.
		if (
			!localState.showBubbleHover &&
			localState.mouseProximityState.coachmarkDotRef &&
			localState.mouseProximityState.coachmarkDotRef.current
		) {
			var rect =
				localState.mouseProximityState.coachmarkDotRef.current.getBoundingClientRect();
			setCoachState((prevState) => {
				let updatedState = Object.assign({}, prevState);
				updatedState.mouseProximityState.targetElementRect = rect;
				return updatedState;
			});
		} //Otherwise, use the opened Teaching Bubble as the reference bounding rectangle for mouse proximity.
		else if (
			localState.showBubbleHover &&
			localState.mouseProximityState.teachingBubbleDivRef &&
			localState.mouseProximityState.teachingBubbleDivRef.current
		) {
			var teachRect =
				localState.mouseProximityState.teachingBubbleDivRef.current.rootElement.current?.getBoundingClientRect();

			setCoachState((prevState) => {
				let updatedState = Object.assign({}, prevState);
				updatedState.mouseProximityState.targetElementRect = teachRect;
				return updatedState;
			});
		}
	};

	const updateBoundaryOnResize = (): void => {
		//Clean up any previous setTimeout methods created.
		coachState.mouseProximityState.timeoutIds.forEach(function (value: any) {
			clearTimeout(value);
		});

		//Create new setTimeout method to update the targetted rect after resizing.
		coachState.mouseProximityState.timeoutIds.push(
			setTimeout(function () {
				setTargetElementRect();
			}, 100)
		);
	};

	return renderFinalResult();
};
