import { connect } from 'react-redux';
import { IAppState } from 'types/appState.type';
import { StepItem, IStepItemProps } from './StepItem';
import { setFocusElementAction } from 'actions/project.action';
import { IFocusElement } from 'types/project.type';

const mapStateToProps = (state: IAppState): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any): IStepItemProps => {
	return {
		setFocusElementAction: (focusElement: IFocusElement) => {
			dispatch(setFocusElementAction(focusElement));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StepItem);
