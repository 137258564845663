import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createAction } from 'typesafe-actions';
import { DataClient } from 'utils/dataClient';
import { ILocaleInfo } from '../types/locale.type';

export const setLocale = createAction('setLocale', (action) => {
    return (locale: string) => action({ locale });
});

export const loadLocaleInfoStart = createAction(
    'loadLocaleInfo/start',
    (action) => {
        return (locale: string) => action({ locale });
    }
);

export const loadLocaleInfoFail = createAction(
    'loadLocaleInfo/Fail',
    (resolve: any) => {
        return (error: { locale: string; e: any }) => {
            return resolve(error);
        };
    }
);

export const loadLocaleInfoSuccess = createAction(
    'loadLocaleInfo/Success',
    (resolve: any) => {
        // parse results
        return (results: { locale: string; localeInfo: ILocaleInfo }) =>
            resolve({
                locale: results.locale,
                localeInfo: results.localeInfo,
            });
    }
);


export const loadLocaleInfo: ActionCreator<
    ThunkAction<Promise<any>, any, void, any>
> =
	(locale: string) =>
	async (dispatch: Dispatch<any>): Promise<any> => {
    try {
        // API call
        const client = new DataClient();

			//Handle false locale case.
			if (locale == 'guidedtour') {
				locale = 'en-US';
            }

			dispatch(loadLocaleInfoStart(locale));

        const localeInfo: ILocaleInfo = await client.getLocaleInfo(locale);

        return dispatch(loadLocaleInfoSuccess({ locale, localeInfo }));

    } catch (e) {
        return dispatch(loadLocaleInfoFail({ locale, e }));
    }
	};
