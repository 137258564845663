import React from 'react';
import { IMouseProximityState, initProximityState } from 'types/coachmark.type';

export class Image {
    path: string = '';
    alt: string = '';
}

export class Modal {
    title: string = '';
    description: string = '';
    primary: string = '';
    primaryAction: string = '';
    secondary: string = '';
    secondaryAction: string = '';
}

export class TaskSection {
    sectionTitle: string = 'Task Section Title';
    sectionStartIndex: number = -1;
    sectionEndIndex: number = -1;
}

export class TaskGrouping {
    scenarioGroupsTasks: boolean = false;
    groupingTitle: string = '';
    taskSections: TaskSection[] = [];
}


export class Tour {
    id: string = '';
    title: string = '';    
    description: string = '';
    actionButton?: string = '';
    actionUri?: string = '';
    sectionCount: number = 1;
    sections: TourSection[] = [];
    loaded: boolean = false;
    loading: boolean = false;
    imagesLoaded: boolean = false;
    showDemoEndCTA: boolean = true;
    callToActionModal: Modal = new Modal();
    secondaryCTA: Cta[] = [];
    isNativeLongForm: boolean = true;
    useLocaleImages: boolean = false;
    scenarioTaskGrouping: TaskGrouping = new TaskGrouping();
	captureTrialClick: boolean = false;

    constructor(id?: string) {
        this.id = id ? id.toLowerCase() : '';
    }
}

export class TrialClickInfo {
	stepNumber: string = '';
	sectionName: string = '';
	sectionNumber: string = '';
	clickName: string = '';
	clickTitle: string = '';
}

export class PageViewBody {
	contentId: string = '';
	contentName: string = '';
	sceneStep: string = '';
	scene: string = '';
	productTitle: string = '';
	productID: string = '';
	productSKU: string = '';
}

export class ButtonsState {
    fullscreenMode: boolean = false;
    hiddenButtonMode: boolean = false;
    coachmarkClicked: boolean = false;
    hideButtonUI: boolean = false;
	markerButton: React.RefObject<HTMLDivElement> =
		React.createRef<HTMLDivElement>();
	nextButton: React.RefObject<HTMLDivElement> =
		React.createRef<HTMLDivElement>();
	imageButton: React.RefObject<HTMLAnchorElement> =
		React.createRef<HTMLAnchorElement>();
	imageRef: React.RefObject<HTMLImageElement> =
		React.createRef<HTMLImageElement>();
}


export class CoachmarkState  {
    showBubbleClick: boolean = false;
    showBubbleHover: boolean = false;
	nextNavButton: React.RefObject<HTMLButtonElement> =
		React.createRef<HTMLButtonElement>();
	prevNavButton: React.RefObject<HTMLButtonElement> =
		React.createRef<HTMLButtonElement>();
    mouseProximityState: IMouseProximityState = initProximityState;
}

export class TourStep {
    stepNumber: number = 0;
    sectionName: string = '';
    sectionNumber: number = 0;
    stepDetails: string[] = [];
    coachmarkCoordinates: number[] = [];
    beakDirection?: string = undefined;
    stepInstructions?: string = undefined;
    stepList?: string[] = undefined;

    isDefaultCoordinates(): boolean {
		if (
			this.coachmarkCoordinates &&
			(this.coachmarkCoordinates[0] == -1 ||
				this.coachmarkCoordinates[0] == -1.0) &&
			(this.coachmarkCoordinates[1] == -1 ||
				this.coachmarkCoordinates[1] == -1.0)
		) {
            return true;
        }

        return false;
    }
}

export class TourSection {
    sectionName: string = '';
    sectionNumber: number = 0;
    stepCount: number = 0;
    steps: TourStep[] = [];    
}

export class ConsentValues {
  Required = true;
  Analytics = false;
  SocialMedia = false;
  Advertising = false;
  isConsentRequired = false;
}

export class SiteLogo {
    text: string = '';
    link: string = '';
    image: string = '';
    imageDark: string = '';
}

export interface IDropdownValue {
    id: string;
    title: string;
}

export class StoryCta {
    title: string = '';
    cta: string = '';
    ctaAction: string = '';
}

export class Cta {
    icon: string = '';
    cta: string = '';
    ctaAction: string = '';
}


export class TourEnd {
    title: string = '';
    tourDropdownLabel: string = '';
    cta: string = '';
    ctaAction: string = '';
    storyCta: StoryCta = new StoryCta();
}

export class Collection implements IDropdownValue {
    id: string = '';
    title: string = '';
    heading: string = '';
    description: string = '';
    disableDemoEnd?: boolean = false;
	tourEndRedirect?: string = '';
    loaded: boolean = false;
    isNativeLongForm: boolean = false;
    isCollectionCollection: boolean = false;
    siteLogo: SiteLogo = new SiteLogo();
    tourEnd: TourEnd = new TourEnd();
    tourIds: string[] = [];
    collectionIds: string[] = [];
}

export class ProjectDto {
    title: string = '';
    launchText: string = '';
    tourLaunchText: string = '';
    tourTitleSuffix: string = '';
    heading: string = '';
    description: string = '';
    selectCollection: string = '';
    collectionDropdown: string = '';
    secondaryCollectionDropdown: string = '';
    tourDropdown: string = '';
    stepNavLabelPrev: string = '';
    stepNavLabelNext: string = '';
    nextNavLabel: string = '';
    previousNavLabel: string = '';
    stepProgress: string = '';
    demoToggleOffLabel: string = '';
    demoToggleOnLabel: string = '';
    standardModeLabel: string = '';
    fullscreenModeLabel: string = '';
    landingLandmarkLabel: string = '';
    sectionLandmarkLabel: string = '';
    tourEndLandmarkLabel: string = '';
	shareButtonText: string = '';
	emailOptionText: string = '';
	emailSubjectText: string = '';
    siteLogo: SiteLogo = new SiteLogo();
    contactRail: ContactRail = new ContactRail();
	livePersonMitigationEnabled: boolean = false;
    collectionIds: string[] = [];
}

export class Project {
    title: string = '';
    launchText: string = '';
    tourLaunchText: string = '';
    tourTitleSuffix: string = '';
    heading: string = '';
    description: string = '';
    selectCollection: string = '';
    collectionDropdown: string = '';
    secondaryCollectionDropdown: string = '';
    tourDropdown: string = '';
    stepNavLabelPrev: string = '';
    stepNavLabelNext: string = '';
    nextNavLabel: string = '';
    previousNavLabel: string = '';
    stepProgress: string = '';
    demoToggleOffLabel: string = '';
    demoToggleOnLabel: string = '';
    standardModeLabel: string = '';
    fullscreenModeLabel: string = '';
    landingLandmarkLabel: string = '';
    sectionLandmarkLabel: string = '';
    tourEndLandmarkLabel: string = '';
	shareButtonText: string = '';
	emailOptionText: string = '';
	emailSubjectText: string = '';
    siteLogo: SiteLogo = new SiteLogo();
    contactRail: ContactRail = new ContactRail();
	livePersonMitigationEnabled: boolean = false;
}

// This class is for Contact us right rail .

export class ContactRail {
  contactUs: string = '';
  phoneAvailability: string = '';
  close: string = '';
  chatAlt: string = '';
  contactUsAlt: string = '';
  callAlt: string = '';
  chatAriaLabel: string = '';
  contactUsAriaLabel: string = '';
  callAriaLabel: string = '';
  closeAriaLabel: string = '';
  widgetCollapseAriaLabel: string = '';
  widgetExpandAriaLabel: string = '';

	//Live Person fields.
	chatModalAria: string = '';
	chatNow: string = '';
	chatNowAria: string = '';
	iFrameChat: string = '';
	modalCloseButton: string = '';
	needHelp: string = '';
	noThanks: string = '';
	noThanksAria: string = '';
	sectionText: string = '';
}

export class ContactRailSetting {
	Slug: string = '';
	IsChatModeEnabled: boolean = false;
	IsCallModeEnabled: boolean = false;
	IsRequestModeEnabled: boolean = true;
	IsFreeTrialModeEnabled: boolean = true;
	PhoneNo: string = '';
	ChatLeadgen: string = '';
	ChatMarketImage: string = '';
	constructor(
		slug: string,
		isChatModeEnabled: boolean,
		isCallModeEnabled: boolean,
		isRequestModeEnabled: boolean,
		isFreeTrialModeEnabled: boolean,
		phoneNo: string,
		chatLeadgen: string,
	);
	constructor(
		slug: string,
		isChatModeEnabled: boolean,
		isCallModeEnabled: boolean,
		isRequestModeEnabled: boolean,
		isFreeTrialModeEnabled: boolean,
		phoneNo: string,
		chatLeadgen: string,
		chatMarketImage?: string
	) {
		this.Slug = slug;
		this.IsChatModeEnabled = isChatModeEnabled;
		this.IsCallModeEnabled = isCallModeEnabled;
		this.IsRequestModeEnabled = isRequestModeEnabled;
		this.IsFreeTrialModeEnabled = isFreeTrialModeEnabled;
		this.PhoneNo = phoneNo;
		this.ChatLeadgen = chatLeadgen;
		if (chatMarketImage) {
			this.ChatMarketImage = chatMarketImage;
		}
	
    }
}

export class LivePersonConfig {
	livePersonPath: string = '';
	livePersonDomain: string = '';
	livePersonSiteID: string = '';
}
