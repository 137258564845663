import { getType } from 'typesafe-actions';
import { setConsentValues } from 'actions/cookieBanner.action';
import { ICookieBannerState, initStepState } from 'types/cookieBanner.type';

export default (
	state: ICookieBannerState = initStepState,
	action: any
): any => {
  switch (action.type) {
    case getType(setConsentValues):
      return {
        ...state,
        consentValues: action.payload.consentValues,
      };
  }
  return state;
};
