import { connect } from 'react-redux';
import { IHeaderDispatch, HeaderComponent } from './HeaderComponent';
import { IAppState } from 'types/appState.type';
import {
	setFullscreenMode,
	setHiddenButtonMode,
	setHideButtonUIMode,
} from 'actions/buttons.action';
import { setIsLanding } from 'actions/project.action';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state: IAppState): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any): IHeaderDispatch => {
	return {
		setFullscreenMode: (fullscreenModeState: boolean) => {
			dispatch(setFullscreenMode(fullscreenModeState));

			if (fullscreenModeState) {
				dispatch(setHideButtonUIMode(true));
			} else {
				dispatch(setHideButtonUIMode(false));
			}
		},
		setHiddenButtonMode: (hiddenModeState: boolean) => {
			dispatch(setHiddenButtonMode(hiddenModeState));
		},
		setIsLanding: (isLanding: boolean) => {
			dispatch(setIsLanding(isLanding));
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
);
