import React, { useCallback } from 'react';
import HeaderComponentContainer from '../common/HeaderComponentContainer';
import { IAppState } from '../../types/appState.type';
import { getNativeLongFormState } from 'utils/renderHelpers';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import { FooterComponent }  from '../common/FooterComponent';
import ShareGroupComponent from '../common/ShareGroupComponent';

export interface ILayoutDispatch { }

export interface ILayoutPropsWithDispatch extends ILayoutDispatch, IAppState { }

export const Layout = (props: React.PropsWithChildren<ILayoutPropsWithDispatch>) => {
	const { children, history, localeState, projectState, collectionState, tourState } = props;
	const { project } = projectState;
	const { selectedLocale } = localeState;
	const { selectedCollection, collections } = collectionState;
	let nameSpaces = [projectNamespace];
	for (const collection of collections) {
		nameSpaces.push(`collections/${collection.id}`);
	}

	const { t } = useTranslation(nameSpaces);

	let collectionName: string;
	if (!selectedCollection) {
		collectionName = t(`${projectNamespace}:${project.heading}`);
	} else {
		collectionName = t(
			`collections/${selectedCollection.id}:${selectedCollection.title}`
		);
	}
	const selectedCollectionId = selectedCollection ? selectedCollection.id : '';

	const shareButtonText = t(project.shareButtonText);
	const emailOptionText = t(project.emailOptionText);
	const emailSubjectText = t(project.emailSubjectText);

	const returnToStartMethod = useCallback(() => {
		history.push(`/${selectedLocale}/guidedtour/`);
	}, [history, selectedLocale]);

	const returnToCollectionMethod = useCallback(() => {
		history.push(`/${selectedLocale}/guidedtour/${selectedCollectionId}`);
	}, [history, selectedLocale, selectedCollection]);

	const isNativeLongForm = getNativeLongFormState(props);
	return (
		//Show Header and Children - but only show footer if we have a valid value.
		//Should help prevent showing the footer during loading.
		//Adding ShareGroup here - as it ensures it always shows just above the footer.
		<>
			<HeaderComponentContainer {...(props as IAppState)} />
			{children}
			{tourState.isTourRunning && (isNativeLongForm != undefined &&  !isNativeLongForm) && <ShareGroupComponent
				buttonText={shareButtonText}
				emailEntryText={emailOptionText}
				emailSubjectText={emailSubjectText}
				returnButtonClick={returnToStartMethod}
				collectionButtonClick={returnToCollectionMethod}
				collectionName={collectionName}
			/>} 
			{isNativeLongForm != undefined && !isNativeLongForm && <FooterComponent />}
		</>
	);
};
