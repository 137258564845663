import { getType } from 'typesafe-actions';
import { ISectionState, initSectionState } from 'types/section.type';
import { setSection } from 'actions/section.action';
import { setTour } from 'actions/tour.action';
import { TourSection } from 'types/schema.type';

export default (
	state: ISectionState = initSectionState,
	action: any
): ISectionState => {
  switch (action.type) {
		case getType(setTour): {
              return {
                  ...state,
                  selectedSection: new TourSection(),
              };
          }
		case getType(setSection): {
              return {
                  ...state,
                  selectedSection: action.payload.selectedSection,
              };
          }
  }
  return state;
};
