//ContactRightRailComponentContainer
import { connect } from 'react-redux';
import { IAppState } from 'types/appState.type';
import { setShowProactiveChat, setShowCallToAction } from 'actions/project.action';
import { withRouter } from 'react-router-dom';
import { ContactRightRailComponent, IContactRailProps } from './ContactRightRailComponent';

const mapStateToProps = (state: IAppState) => ({
	...state,  // Spread the entire state
	selectedCollection: state.collectionState.selectedCollection,  // Explicitly add selectedCollection
});

const mapDispatchToProps = (dispatch: any, ownProps: any): IContactRailProps => ({
	setShowCallToAction: (showCallToAction: boolean) => {
		dispatch(setShowCallToAction(showCallToAction));
	},
	setShowProactiveChat: (showProactiveChat: boolean) => {
		dispatch(setShowProactiveChat(showProactiveChat));
	},
	selectedCollection: ownProps.selectedCollection,  // Make sure selectedCollection is passed here
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ContactRightRailComponent)
);
