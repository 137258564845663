import { getType } from 'typesafe-actions';
import {
	setLocale,
	loadLocaleInfoSuccess,
	loadLocaleInfoStart,
	loadLocaleInfoFail,
} from 'actions/locale.action';
import { ILocaleState, initLocaleState, ILocaleInfo } from 'types/locale.type';

export default (
    state: ILocaleState = initLocaleState,
    action: any
): ILocaleState => {
    switch (action.type) {
        case getType(setLocale):
            return {
                ...state,
                selectedLocale: action.payload.locale.toLowerCase(),
            };

		case getType(loadLocaleInfoSuccess): {
                let info = action.payload.localeInfo as ILocaleInfo;

                console.log(action.payload.localeInfo);
                return {
                    ...state,
                    localeHexCode: info.localeHexCode,
                    isRTLCulture: info.isRTLCulture,
                    fallbackLocale: info.fallbackLocale,
                    isLoading: false,
                    loadingError: false,
                };
            }
		case getType(loadLocaleInfoStart): {
                return {
                    ...state,
                    isLoading: true,
                };
            }
        case getType(loadLocaleInfoFail):
            return {
                ...state,
                isLoading: false,
                loadingError: true,
            };
    }
    return state;
};
