import React, { useEffect } from 'react';
import { ICollectionLoaderStateWithDispatch } from '../../types/collection.type';
import { Collection } from '../../types/schema.type';
import { IsCollectionLoadedFor } from '../../utils/collectionUtil';

export const CollectionLoadingComponent = (
	props: ICollectionLoaderStateWithDispatch
) => {
	const {
		collectionState,
		loadCollection,
		setCollection,
		collectionId,
		isBatchLoading,
	} = props;
	const {
		collections,
		isLoading,
		loadingError,
		selectedCollection,
		secondaryCollection,
		secondaryCollections,
	} = collectionState;

	useEffect(() => {
		if (!isLoading && !loadingError) {
			if (!secondaryCollection) {
				const loaded = IsCollectionLoadedFor(collectionId, collections);

				if (!loaded) {
					loadCollection(collectionId, false);
				}

				if (!isBatchLoading) {
					let newCollection = collections.find(
						(collection: Collection) => collection.id === collectionId
					);
					if (newCollection) {
						setCollection(newCollection);
					}
				}
			} else {
				const loaded = IsCollectionLoadedFor(
					collectionId,
					secondaryCollections
				);

				if (!loaded) {
					loadCollection(collectionId, true);
				}
			}
		}
	}, [
		collections,
		collectionId,
		isLoading,
		loadingError,
		selectedCollection,
		setCollection,
		loadCollection,
		secondaryCollection,
		isBatchLoading,
		secondaryCollections,
	]);

	if (isLoading) {
		return <></>;
	}

	if (loadingError) {
		return <>error loading collection</>;
	}

	return <></>;
};
