import { getType } from 'typesafe-actions';
import { Collection } from 'types/schema.type';
import {
	buildCollection,
	mapProject,
	mapSecondaryCollections,
} from 'utils/collectionUtil';
import { loadProjectSuccess } from 'actions/project.action';
import { ICollectionState, initCollectionState } from 'types/collection.type';
import {
    loadCollectionFail,
    loadCollectionStart,
    loadCollectionSuccess,
    setCollection,
    setSecondaryCollection,
} from 'actions/collection.action';


export default (
    state: ICollectionState = initCollectionState,
    action: any
): ICollectionState => {
    switch (action.type) {
        case getType(loadCollectionSuccess):

            const newCollection = buildCollection(action.payload.result);
			if (!action.payload.secondary) {
                let newCollections: Collection[] = [...state.collections];
                let existingCollectionIndex = newCollections.findIndex(
                    (collection) =>
                        collection.id.toLowerCase() ===
                        (action.payload.collectionId as string).toLowerCase()
                );
                if (existingCollectionIndex < 0) {
                    newCollections.push(newCollection);
                } else {
                    newCollections[existingCollectionIndex] = newCollection;
                }

                return {
                    ...state,
                    collections: newCollections,
                    isLoading: false,
                    loadingError: false,
                    loadingCollections: state.loadingCollections.filter(
                        (collectionId: string) =>
                            collectionId.toLowerCase() !==
                            action.payload.collectionId.toLowerCase()
                    ),

                };
			} else {
                let newCollections: Collection[] = [...state.secondaryCollections];
                let existingCollectionIndex = newCollections.findIndex(
                    (collection) =>
                        collection.id.toLowerCase() ===
                        (action.payload.collectionId as string).toLowerCase()
                );
                if (existingCollectionIndex < 0) {
                    newCollections.push(newCollection);
                } else {
                    newCollections[existingCollectionIndex] = newCollection;
                }

                return {
                    ...state,
                    secondaryCollections: newCollections,
                    isLoading: false,
                    loadingError: false,
                    loadingCollections: state.loadingCollections.filter(
                        (collectionId: string) =>
                            collectionId.toLowerCase() !==
                            action.payload.collectionId.toLowerCase()
                    ),

                };

            }
        case getType(setCollection):

            if (state.secondaryCollections.length > 0) {

                const selectedCollection = state.secondaryCollections.find(
                    (collection) =>
                        collection.id.toLowerCase() ===
                        action.payload.collection.id.toLowerCase()
                );
                return {
                    ...state,
                    selectedCollection: selectedCollection || null,
                };
			} else {
				const selectedCollection =
					action.payload.collection != null
						? state.collections.find(
                    (collection) =>
                        collection.id.toLowerCase() ===
                        action.payload.collection.id.toLowerCase()
						  )
						: null;
                return {
                    ...state,
                    selectedCollection: selectedCollection || null,
                };
            }
		case getType(setSecondaryCollection): {
			const secondaryCollection =
				action.payload.collection != null
					? state.collections.find(
							(collection) =>
                    collection.id.toLowerCase() ===
								action.payload.collection.id.toLowerCase()
					  )
					: null;

                return {
                    ...state,
                    secondaryCollection: secondaryCollection || null,
				secondaryCollections:
					secondaryCollection != undefined
						? mapSecondaryCollections(secondaryCollection)
						: [],
			};
            }

        case getType(loadCollectionStart):
            let loadingCollections = [...state.loadingCollections];
            loadingCollections.push(action.payload.collectionId.toLowerCase());
            return {
                ...state,
                isLoading: true,
                loadingCollections,
            };
		case getType(loadCollectionFail): {
                return {
                    ...state,
                    isLoading: false,
                    loadingError: true,
                    loadingCollections: state.loadingCollections.filter(
                        (collectionId: string) =>
                            collectionId.toLowerCase() !==
                            action.payload.collectionId.toLowerCase()
                    ),
                };
            }
		case getType(loadProjectSuccess): {
                let isNativeLongForm = action.payload.isNativeLongForm;
                let collections: Collection[] = [];
                if (!isNativeLongForm) {
                    collections = mapProject(action.payload.results);
			} else {
                    //action.payload is ProjectDto - so collections to load couldd be set there.
                    //but hardcoding only one collection right now.
                    let collection = new Collection();
                    collection.id = 'healthcare-longform';

                    collections.push(collection);
                }

                return {
                    ...state,
                    collections: collections,
                };
            }
    }
    return state;
};
