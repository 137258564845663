import React, { useState, useEffect, useRef } from 'react';
import { PrimaryButton, getTheme, Icon, Spinner } from '@fluentui/react';
import { appInsights } from 'store/appStore';
import { Collection, Image, Tour, TourSection } from 'types/schema.type';
import { Redirect } from 'react-router';
import ProjectComponentContainer from 'components/project/ProjectComponentContainer';
import { useTranslation } from 'react-i18next';
import CollectionLoadingComponentContainer from 'components/collectionLoading/CollectionLoadingComponentContainer';
import { ICollectionAndTourStateWithDispatch } from 'types/collection.type';
import { projectNamespace } from 'i18n';
import LocaleComponentContainer from 'components/locale/LocaleComponentContainer';
import ItemDropdown from 'components/common/ItemDropdown';
import { globalPaths } from 'utils/dataClient';

import generalStyles from 'styles/generalStyles.module.scss';
import styles from './CollectionAndTourLanding.module.scss';
import { TourLoadingComponent } from '../tourLoading/TourLoadingComponent';
import customerServiceImage from './landingImages/customerService.jpg';


// Import custom styles for the button
import { customLandingButtonStyles } from '../../globalStyling';

// Define styles for the enabled and disabled states
const enabledButtonStyles = {
  ...customLandingButtonStyles,
  // Add custom styles for the active state here
  root: {
    borderRadius: '10px',
    backgroundColor: '#0a1b46',
    color: 'white',
    marginTop: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    border: 'none',
	textDecoration: 'none',
    padding: '24px 20px',
  },
  rootHovered: {
    backgroundColor: '#1b56ec',
    textDecoration: 'none !important',
  },
};
const disabledButtonStyles = {
	...customLandingButtonStyles,
	rootDisabled: {
	  padding: '24px 20px',
	  marginTop: "20px",
	  color: '#a4a4a4',
	  borderColor: '#a4a4a4',
	  borderWidth: "2px",
	  borderStyle: "solid", 
	  backgroundColor: 'white',
	  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
	  textDecoration: 'none',
	},
};

const itemDropdownStyles = {
  root: {
    borderRadius: '7px',
  },
};

export const CollectionAndTourLandingComponent = (
	props: ICollectionAndTourStateWithDispatch
) => {
	const {
		projectState,
		localeState,
		collectionState,
		match,
		tourState,
		sectionState,
		stepState,
	} = props;
	const selectedLocale = match.params.selectedLocale
		? match.params.selectedLocale.toLowerCase()
		: '';
	const { project, projectLoaded, isLanding } = projectState;
	const {
		collections,
		selectedCollection,
		secondaryCollection,
		secondaryCollections,
	} = collectionState;
	const { selectedTour, tours } = tourState;
	const { selectedSection } = sectionState;
	const { selectedStep } = stepState;
	const { localeHexCode, isRTLCulture, fallbackLocale } = localeState;
	const [redirect, setRedirect] = useState(false);

	const imgRef = useRef<HTMLImageElement>(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [orientation, setOrientation] = useState('horizontal');
	const [image, setImage] = useState<Image>({ path: '', alt: '' });

	let isNativeLongForm = props.isNativeLongForm || false;

	useEffect(() => {
		const img = imgRef.current;
		const handleLoad = () => {
		  if (img) {
			const isHorizontal = img.naturalWidth > img.naturalHeight;
			setOrientation(isHorizontal ? 'horizontal' : 'vertical');
			setIsLoaded(true);
		  }
		};
	  
		if (img) {
		  img.addEventListener('load', handleLoad);
		  if (img.complete) {
			handleLoad(); // For cached images
		  }
		}
	  
		return () => img?.removeEventListener('load', handleLoad);
	  }, [image.path]);	  

	const getShowcasedSection = () => {
		if (selectedSection && selectedSection.sectionNumber != 1) {
			return selectedSection;
		}

		return selectedTour!.sections[1];
	};

	const getShowcasedStep = (section: TourSection) => {
		if (section == selectedSection) {
			return selectedStep;
		}

		return section.steps[0];
	};

	let nameSpaces = [projectNamespace];
	for (const collection of collections) {
		nameSpaces.push(`collections/${collection.id}`);
	}
	if (secondaryCollections.length > 0) {
		for (const collection of secondaryCollections) {
			nameSpaces.push(`collections/${collection.id}`);
		}
	}

	let namespaceString = isNativeLongForm ? 'tours' : 'scenarios';
	if (selectedCollection) {
		for (const id of selectedCollection.tourIds) {
			nameSpaces.push(`${namespaceString}/${id}`);
		}
	}

	const { t } = useTranslation(nameSpaces);

	useEffect(() => {
		setRedirect(false);
	}, [redirect]);

	useEffect(() => {
		let imagePath = '';
		let imageAlt = '';

		if (selectedTour) {
			const currentSection = getShowcasedSection();
			const currentStep = getShowcasedStep(currentSection);
			imagePath = `/${namespaceString}/${selectedTour.id}/${currentSection.sectionNumber}.${currentStep.stepNumber}.png`;
			imageAlt = t(`${namespaceString}/${selectedTour.id}:${currentSection.sectionName}`);
		} else if (selectedCollection) {
			imagePath = `/collections/${selectedCollection.id}/landing.jpg`;
			imageAlt = t(`collections/${selectedCollection.id}:${selectedCollection.title}`);
		} else {
			imagePath = `/assets/landing.jpg`;
			imageAlt = t(`${project.title}`);
		}

		setImage({ path: imagePath, alt: imageAlt });
	}, [selectedTour, selectedCollection, t, namespaceString, project.title]);

	if (!localeState.selectedLocale || localeState.isLoading) {
		return (
			<LocaleComponentContainer
				locale={selectedLocale}
			></LocaleComponentContainer>
		);
	}

	if (!projectLoaded) {
		return (
			<ProjectComponentContainer
				locale={selectedLocale}
				isNativeLongForm={isNativeLongForm}
			></ProjectComponentContainer>
		);
	}

	let heading: string;
	let description: string;
	if (!selectedCollection) {
		heading = t(`${projectNamespace}:${project.heading}`);
		description = t(`${projectNamespace}:${project.description}`);
	} else {
		heading = t(
			`collections/${selectedCollection.id}:${selectedCollection.heading}`
		);
		description = t(
			`collections/${selectedCollection.id}:${selectedCollection.description}`
		);
	}

	const selectedCollectionId = selectedCollection ? selectedCollection.id : '';
	const selectedTourId = selectedTour ? selectedTour.id : '';

	if (redirect && selectedCollection !== null && selectedTour !== null) {
		return (
			<Redirect
				push
				to={{
					pathname: `/${selectedLocale}/guidedtour/${selectedCollectionId}/${selectedTourId}/1/1`,
				}}
			/>
		);
	}

	const startTestDrive = () => {
		appInsights.trackEvent({
			name: 'Start testdrive',
			properties: { selectedCollectionId },
		});
		appInsights.trackEvent({
			name: 'Start testdrive',
			properties: { selectedTourId },
		});
		setRedirect(true);
	};

	const launchText = t(project.launchText);
	const dropdownPlaceholder = t(project.collectionDropdown);

	const secondaryDropdownPlaceholder = t(project.secondaryCollectionDropdown);
	const tourDropdownPlaceholder = t(project.tourDropdown);

	let dropdownTours =
		selectedCollection != null && tours.length > 0
			? tours.filter((s) => selectedCollection.tourIds.indexOf(s.id) > -1)
			: [];



	return (
		<div
			className={styles.page}
			role="main"
			aria-label={t(project.sectionLandmarkLabel)}
		>
			{collections.map(
				(collection, index) =>
					!collection.loaded && (
						<CollectionLoadingComponentContainer
							key={index}
							collectionId={collection.id}
							locale={selectedLocale}
							isNativeLongForm={isNativeLongForm}
							isBatchLoading={true}
						></CollectionLoadingComponentContainer>
					)
			)}
			{secondaryCollections.length > 0 &&
				secondaryCollections.map(
					(collection, index) =>
						!collection.loaded && (
							<CollectionLoadingComponentContainer
								key={index}
								collectionId={collection.id}
								locale={selectedLocale}
								isNativeLongForm={isNativeLongForm}
								isBatchLoading={true}
							></CollectionLoadingComponentContainer>
						)
				)}
			{selectedCollection != null &&
				selectedCollection.tourIds.map((id, index) => {
					return (
						<TourLoadingComponent
							set={false}
							key={index}
							tourId={id}
							isNativeLongForm={isNativeLongForm}
							tourState={tourState}
							loadTour={() => {
								props.loadTour(id, isNativeLongForm, localeHexCode);
							}}
							setTour={(tourId: string) => {
								props.setTour(tourId);
							}}
							localeHexCode={localeHexCode}
						></TourLoadingComponent>
					);
				})}
			<div
				className={styles.pageContent}
				dir={isRTLCulture ? 'rtl' : 'ltr'}
				lang={fallbackLocale}
			>
				<div className={`${styles.imageContainer} ${styles[orientation]}`}>
				{
				!isLoaded && <div className={styles.loadingContainer}><Spinner /></div>
				}
				<img
				ref={imgRef}
				className={styles.image}
				src={selectedTour && selectedTour.id === 'customer-service' ? customerServiceImage : `${globalPaths.baseUrl}/${image.path}`}
				alt={image.alt}
				onLoad={() => setIsLoaded(true)}
				onError={() => setIsLoaded(false)}
				style={{ display: isLoaded ? 'block' : 'none' }}
				/>
				</div>
				<div className={styles.landingPageContentContainer}>
					<div
						className={styles.landingPageContent}
						role="main"
						aria-label={t(project.landingLandmarkLabel)}
					>
						<h2 role="heading" aira-level={2} className={styles.heading}>{heading}</h2>
						<p className={styles.description}>{description}</p>
						<ItemDropdown
              styles={itemDropdownStyles}
							large={false}
							selectedItemId={
								secondaryCollection
									? secondaryCollection.id
									: selectedCollection
									? selectedCollection.id
									: ''
							}
							items={collections}
							sortItems={false}
							placeholder={dropdownPlaceholder}
							itemType={'collections'}
							selectFocusable={true}
							setItem={(item: Collection) => {
								if (item.isCollectionCollection) {
									props.setCollection(null);
									props.setSecondaryCollection(item);
								} else {
									props.setSecondaryCollection(null);
									props.setCollection(item);
								}
							}}
						/>
						{secondaryCollection != null && (
							<div className={styles.secondaryCollection}>
								<ItemDropdown
									large={false}
									selectedItemId={
										selectedCollection ? selectedCollection.id : ''
									}
									items={secondaryCollections}
									placeholder={secondaryDropdownPlaceholder}
									itemType={'collections'}
									selectFocusable={true}
									setItem={(item: Collection) => {
										props.setCollection(item);
									}}
								/>
							</div>
						)}
						{selectedCollection != null && selectedCollectionId != '' && (
							<div className={styles.tourDropdownDiv}>
								<ItemDropdown
									large={false}
									selectedItemId={selectedTour ? selectedTour.id : ''}
									items={dropdownTours}
									itemType={namespaceString}
									placeholder={tourDropdownPlaceholder}
									selectFocusable={true}
									setItem={(tour: Tour) => {
										props.setTourObj(tour);
									}}
								/>
							</div>
						)}

						<PrimaryButton
							id={'landing-launch'}
							name={launchText}
							target="_blank"
							tabIndex={0}
							styles={selectedCollection === null || selectedTour === null ? disabledButtonStyles : enabledButtonStyles}
							disabled={selectedCollection === null || selectedTour === null}
							className={generalStyles.button}
							theme={getTheme()}
							onClick={() => {
								startTestDrive();
							}}
							text={launchText}
						>
							<Icon
								iconName={
									isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'
								}
								className={generalStyles.ChevronStyle}
							></Icon>
						</PrimaryButton>
					</div>
				</div>
			</div>
		</div>
	);
};
