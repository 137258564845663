import { connect } from 'react-redux';
import { IAppState } from './../../types/appState.type';
import { Layout, ILayoutDispatch, } from './Layout';
import { withRouter } from 'react-router-dom';


const mapStateToProps = (state: IAppState,): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any): ILayoutDispatch => {
	return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
