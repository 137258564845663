import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localesJson from './utils/locale-mapping.json';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { globalPaths } from 'utils/dataClient';

export const projectNamespace = 'project/project';

const languageDetectorOptions = {
	// order and from where user language should be detected
	order: ['path', 'cookie', 'localStorage', 'navigator'],

	// keys or params to lookup language from
	lookupQuerystring: 'lng',
	lookupCookie: 'i18next',
	lookupLocalStorage: 'i18nextLng',
	lookupSessionStorage: 'i18nextLng',
	lookupFromPathIndex: 0,
	lookupFromSubdomainIndex: 0,

	// cache user language on
	caches: ['localStorage', 'cookie'],
	excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

	// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
	cookieOptions: { path: '/', sameSite: 'strict' },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: localesJson.locales,
    debug: true,
    detection: languageDetectorOptions,
    interpolation: {
      escapeValue: false,
    },
    load: 'currentOnly',
    lowerCaseLng: true,
    keySeparator: false,
    defaultNS: projectNamespace,
    ns: [projectNamespace],
    backend: {
		// this is a concatenation
      loadPath: globalPaths.translationBaseUrl + '/{{ns}}/{{lng}}',
      allowMultiLoading: false,
      crossDomain: true,
      withCredentials: false,
      overrideMimeType: false,
    },
  }, (err, t) => {
    if (err) console.log('i18n init error:', err);
    console.log('Detected language:', i18n.language);
    console.log('Resource bundle:', i18n.getResourceBundle(i18n.language, projectNamespace));
  });

export default i18n;

// '/{{ns}}/{{lng}}'
// '/FooterTranslations/{{lng}}/translation.json'