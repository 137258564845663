import React from 'react';
import { IAppState } from 'types/appState.type';
import { RectangleEdge } from '@fluentui/react';
import { TFunction } from 'i18next';
import { String } from 'typescript-string-operations';
import imageStyles from 'components/rightpanel/RightPanel.module.scss';
import { globalPaths } from './dataClient';


export const needsStepDetailsSection = (state: IAppState): boolean => {

    const { selectedStep } = state.stepState;

    //Avoid any null refs.
    if (!selectedStep || !selectedStep.stepList || !selectedStep.stepDetails) {
        return false;
    }

    if (selectedStep.stepList.length > 0 || selectedStep.stepDetails.length > 0) {
        return true;
    }

    return false;
};

export const getCurrentTourBaseUrl = (pathName: string): string => {

    //Get the string right before section and step i.e.(right before /1/1)
	var secondLastIndex = pathName.lastIndexOf(
		'/',
		pathName.lastIndexOf('/') - 1
	);

    //If we don't have it - then we should already be at root?
    if (secondLastIndex == -1) {
        return pathName;
    }

    //Return everything before the second to last slash.
    return pathName.substring(0, secondLastIndex);
};

export const getUrlForSectionAndStep = (
	pathName: string,
	newSection: number,
	newStep: number
): string => {
    //console.log(String.Format("Section: {0} \n Step: {1} \n String: {2}", newSection, newStep, String.Format('{0}/{1}/{2}', this.getCurrentTourBaseUrl(), newSection, newStep)));

	return String.Format(
		'{0}/{1}/{2}',
		getCurrentTourBaseUrl(pathName),
		newSection,
		newStep
	);
};

export const renderBulletListToListItem = (
	listItemStrings: string[],
	t?: TFunction,
	namespace?: string
): any[] => {
    let listItems: any[] = [];
    var index = 0;
    listItemStrings.map((currentString: string) => {

        let bulletString = currentString;
        if (t) {
			bulletString = t(`${namespace}:${bulletString}`);
        }

		listItems.push(<li key={index}> {bulletString} </li>);
        index++;
    });

    return listItems;
};

export const renderMultilineToParagraphs = (
	multilineString: string[],
	t?: TFunction,
	namespace?: string
): any[] => {
    let paragraphs: any[] = [];
    var index = 0;
    multilineString.map((currentString: string) => {
        let paraString = currentString;
        if (t) {
			paraString = t(`${namespace}:${paraString}`);
        }

		paragraphs.push(
			<p key={index} dangerouslySetInnerHTML={{ __html: paraString }} />
		);
        index++;
    });

    return paragraphs;
};

//Used to determine if Next button should be shown.
export const canAdvanceNext = (state: IAppState): boolean => {

    
    const { selectedTour } = state.tourState;
    const { selectedSection } = state.sectionState;
    const { selectedStep } = state.stepState;
    const { selectedCollection } = state.collectionState;

    //Handle Tour not set.
    if (!selectedTour) {
        return false;
    }

    let currentStepNumber = selectedStep.stepNumber;

    if (currentStepNumber >= selectedSection.stepCount) {
        let currentSectionNumber = selectedSection.sectionNumber;
        if (currentSectionNumber < selectedTour.sectionCount) {
            return true;
        }

        if (selectedTour.showDemoEndCTA || !selectedCollection?.disableDemoEnd) {
            return true;
        }

        return false;
    }

    return true;
};

//Used to determine if Prev button should be shown.
export const canNavigatePrevious = (state: IAppState): boolean => {

    const { selectedSection } = state.sectionState;
    const { selectedStep } = state.stepState;

    let currentStepNumber = selectedStep.stepNumber;

	if (currentStepNumber - 2 < 0) {
		//Since we subtract 2 (for zero indexing) - need to make sure we stay above 0.

        let currentSectionNumber = selectedSection.sectionNumber;
		if (currentSectionNumber >= 2) {
			//Since we subtract 2 - need to make sure we stay above 0.
            return true;
        }
        return false;
    }

    return true;
};


export const hasNextButtonCoords = (state: IAppState) => {
    const { selectedStep } = state.stepState;

    var coords = selectedStep.coachmarkCoordinates;
    if (coords[0] === -1 && coords[1] === -1) {
        return true;
    }

    return false;
};


export const getCurrentStepBeakDirection = (
	state: IAppState
): RectangleEdge => {
    const { selectedStep } = state.stepState;
	const { selectedTourId = 'virtual-appointments'} = state.tourState;
	const { buttonsState } = state;
	const { fullscreenMode } = buttonsState.currentState

    if (selectedStep.beakDirection === undefined) return RectangleEdge.top;

    //If we are VA tour - use bottom beak direction for custom NextButton positioning.
    if (selectedTourId == 'virtual-appointments' && hasNextButtonCoords(state) && fullscreenMode) {
        return RectangleEdge.bottom;
    }
	  
    var directionStr = selectedStep.beakDirection;

    switch (directionStr.toLowerCase()) {

        default:
		case 'bottom': {
            return RectangleEdge.bottom;
        }
		case 'top': {
            return RectangleEdge.top;
        }
		case 'left': {
            return RectangleEdge.left;
        }
		case 'right': {
            return RectangleEdge.right;
        }
    }
};

export const getNativeLongFormState = (state: IAppState): boolean => {

    const { tourState, collectionState, projectState, router } = state;
    const { selectedTour } = tourState;
    const { selectedCollection } = collectionState;  
    const { location } = router;

    if (selectedTour) {
        return selectedTour.isNativeLongForm || false;
    }

    if (selectedCollection) {
        return selectedCollection.isNativeLongForm || false;
    }

	if (location && location.pathname.includes('lfguidedtour')) {
        return true;
    }

    return false;
};

export const fixTourTitle = (tourTitle: string): string => {

	if (!tourTitle)
		return tourTitle;

	//ensure we lower-case, strip spaces and commas used.
	return tourTitle.toLowerCase()
		.replace(/[,]/g, '')
		.replace(/\s+/g, '-');

}

export const bulkLoadImagesForTour = (
	state: IAppState,
	fixedTourTitle: string
): boolean => {
    const { tourState } = state;
    const { selectedTour } = tourState;
    
	if (!selectedTour) return false;

    for (let section = 0; section < selectedTour.sections.length; ++section) {

        var currentSection = selectedTour.sections[section];

        for (let step = 0; step < currentSection.steps.length; ++step) {

            var currentStep = currentSection.steps[step];

			var imagePath = '';
            if (selectedTour.isNativeLongForm) {
				imagePath = `${globalPaths.baseUrl}/tours/${fixedTourTitle}/${currentSection.sectionNumber}_${
					currentStep.stepNumber
				}.PNG`;
			} else {
				imagePath = `${globalPaths.baseUrl}/scenarios/${fixedTourTitle}/${currentSection.sectionNumber}.${
					currentStep.stepNumber
				}.PNG`;
            }

            let img = new Image();
			img.crossOrigin = 'anonymous';
            img.src = imagePath;
        }
    }

    return true;
};
