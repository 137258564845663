import React, { useEffect, useState } from 'react';
//import { css, StyleSheet } from 'aphrodite/no-important';
import { IAppState } from 'types/appState.type';
import { getTheme, Icon, DefaultButton } from '@fluentui/react';
import { BaseButtonStyling } from '../../../globalStyling'
import { TourEnd, Tour } from 'types/schema.type';
import { useTranslation } from 'react-i18next';
import ItemDropdown from 'components/common/ItemDropdown';
import { projectNamespace } from 'i18n';
import { ICanLoadTour } from 'types/tour.type';
import generalStyles from 'styles/generalStyles.module.scss';

import styles from './AnotherTour.module.scss';

//const styles = StyleSheet.create({
//  anotherDemoContainer: {
//    display: 'flex',
//    flexDirection: 'column',
//    justifyContent: 'flex-start',
//    alignItems: 'center',
//    color: 'white',
//    backgroundColor: '#3C3C41',
//  },
//  title: {
//    marginTop: '0',
//    marginBottom: '30px',
//  },
//  label: {
//    marginBottom: '8px',
//  },
//  button: {
//    backgroundColor: '#FFFFFF',
//    border: '1px solid #000000',
//    boxShadow:
//      '0px 0px 1px rgba(0, 0, 0, 0.18), 0px 2px 4px rgba(0, 0, 0, 0.12)',
//    borderRadius: '2px',
//    marginTop: '48px',
//    marginBottom: '93px',
//  },
//});

export interface IAnotherTour extends ICanLoadTour, IAppState {
	tourId: string;
	tours: Tour[];
}

export const AnotherTourComponent = (props: IAnotherTour) => {
	const {
		projectState,
		localeState,
		collectionState,
		tourState,
		history,
		setTour,
	} = props;
	const { project } = projectState;
	const { selectedCollection } = collectionState;
	const { selectedLocale, isRTLCulture } = localeState;
	const [gotoDemo, setGotoDemo] = useState(false);

	const selectedTourId: string = props.tourId
		? props.tourId
		: tourState.selectedTourId;
	const [tourId, setTourId] = useState(selectedTourId);
	const { t } = useTranslation([projectNamespace, `scenarios/${tourId}`]);

	useEffect(() => {
		if (selectedCollection && tourId && gotoDemo) {
			history.push(
				`/${selectedLocale}/guidedtour/${selectedCollection!.id}/${
					tourState.selectedTourId
				}/1/1`
			);
		}
	}, [
		gotoDemo,
		history,
		selectedCollection,
		selectedLocale,
		tourId,
		tourState.selectedTourId,
	]);

	if (!selectedCollection || !tourId) {
		return <></>;
	}

	const tourEnd: TourEnd = selectedCollection
		? selectedCollection.tourEnd
		: new TourEnd();

	//Only use Tour-specific end strings if they exist. Otherwise, fallback to use Collection strings.
	const tourLocTitleCheck = t(`scenarios/${tourId}:${tourEnd.title}`);
	const tourLocDropdownCheck = t(
		`scenarios/${tourId}:${tourEnd.tourDropdownLabel}`
	);

	const title =
		tourLocTitleCheck != 'tourEnd.title'
			? tourLocTitleCheck
			: t(`collections/${selectedCollection.id}:${tourEnd.title}`);
	const dropdownLabel =
		tourLocDropdownCheck != 'tourEnd.tourDropdownLabel'
			? tourLocDropdownCheck
			: t(`collections/${selectedCollection.id}:${tourEnd.tourDropdownLabel}`);
	const launchText = t(project.launchText);
	const dropdownPlaceholder = t(`${projectNamespace}:${project.tourDropdown}`);

	return (
		<div className={styles.anotherDemoContainer}>
			<h1 className={styles.title}>{title}</h1>
			<div className={styles.label}>{dropdownLabel}</div>
			<ItemDropdown
				selectedItemId={tourId || ''}
				placeholder={dropdownPlaceholder}
				items={props.tours}
				itemType={'scenarios'}
				large={true}
				selectFocusable={true}
				setItem={(tour: Tour) => {
					setTourId(tour.id);
					setTour(tour.id);
				}}
			/>
			<DefaultButton
				className={styles.endButton}
				id={'demoend-launch'}
				data-bi-name={launchText}
				target="_blank"
				tabIndex={0}
				disabled={!tourId}
				theme={getTheme()}
				onClick={() => {
					setGotoDemo(true);
				}}
				text={launchText}
			>
				<Icon
					iconName={isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'}
					className={generalStyles.ChevronStyle}
				></Icon>
			</DefaultButton>
		</div>
	);
};
