import { connect } from 'react-redux';
import { CollectionAndTourLandingComponent } from './CollectionAndTourLandingComponent';
import { IAppState } from 'types/appState.type';
import { Collection, Tour } from 'types/schema.type';
import { withRouter } from 'react-router-dom';
import {
	setCollection,
	setSecondaryCollection,
} from 'actions/collection.action';
import { setTour, loadTour } from 'actions/tour.action';
import { setIsLanding } from 'actions/project.action';
import {
	ICollectionLandingInlineProps,
	ICollectionLandingProps,
	ICollectionAndTourStateWithDispatch,
} from 'types/collection.type';
import { setSection } from 'actions/section.action';
import { setStep } from 'actions/step.action';

const mapStateToProps = (
	state: IAppState,
	props: ICollectionLandingInlineProps
): ICollectionLandingProps => {
	return {
		...state,
		...props,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		setCollection: (selectedCollection: Collection | null) => {
			dispatch(setCollection(selectedCollection));
		},
		setIsLanding: (isLanding: boolean) => {
			dispatch(setIsLanding(isLanding));
		},
		setTour: (selectedTour: string) => {
			dispatch(setTour(selectedTour));
		},
		setSecondaryCollection: (selectedCollection: Collection | null) => {
			dispatch(setSecondaryCollection(selectedCollection));
		},
		loadTour: (
			tourId: string,
			isNativeLongForm: boolean,
			localeHexCode: string
		) => {
			dispatch(loadTour(tourId, isNativeLongForm, localeHexCode));
		},
		setTourObj: (selectedTour: Tour) => {
			dispatch(setTour(selectedTour.id));
			dispatch(setSection(selectedTour.sections[0]));
			dispatch(setStep(selectedTour.sections[0].steps[0]));
		},
	};
};

//Expicitly avoiding type checking to pass in inline param...
export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CollectionAndTourLandingComponent)
);
