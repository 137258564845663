import React from 'react';
import { Redirect } from 'react-router';
import { IAppState } from 'types/appState.type';
//import { GetQueryParameters } from 'utils/queryUtils';

const fmt = '^([A-Z]|[a-z]){2}-([A-Z]|[a-z]){2}$';

export const LegacyComponent = (props: IAppState) => {
	const { router } = props;

	if (router.location && router.location.hash.length > 0) {
		// extract the language from the URL; ideally this is the source of truth
		let urlLng = router.location.pathname.split('/')[1];

		const hash = router.location.hash;
		const parts = hash.split('/');

		// extract the language from the deep link; alternative source of truth
		let hashLng = parts.length > 2 ? parts[2] : '';

		// decide which lng to use; prefer urlLng (if valid), but use hashLng otherwise (if valid), default 'en-US'
		const lng = urlLng.match(fmt)
			? urlLng
			: hashLng.match(fmt)
			? hashLng
			: navigator.language
			? navigator.language
			: 'en-US';

		let scenario = parts.length > 4 ? parts[4] : '';
		scenario = scenario.replace('%26', 'and');
		const task =
			parts.length > 6 ? (parseInt(parts[6], 10) + 1).toString() : '1';
		const step =
			parts.length > 8 ? (parseInt(parts[8], 10) + 1).toString() : '1';
		let collection = 'dynamics';
		switch (scenario) {
			case 'Empower-Health-Team-Collaboration':
				collection = 'healthcare';
				break;
			case 'Enhance-Patient-Engagement':
				collection = 'healthcare';
				break;
			case 'Return-to-the-Workplace':
				collection = 'power-platform';
				break;
			default:
				collection = 'dynamics';
				break;
		}

		let newUrl =
			scenario !== ''
				? `/${lng}/guidedtour/${collection}/${scenario}/${task}/${step}`
				: `/${lng}/guidedtour/landing`;
		const queryParams = null; //GetQueryParameters(hash);

		if (queryParams) {
			const existingQuery = new URLSearchParams(queryParams).toString();
			newUrl += `?${existingQuery}`;
		}

		return (
			<>
				<div>
					<h1>Legacy Component</h1>
					<p>{hash}</p>
					<p>language: {lng}</p>
					<p>scenario: {scenario}</p>
					<p>task: {task}</p>
					<p>step: {step}</p>
				</div>
				<Redirect to={newUrl} />
			</>
		);
	} else {
		return <Redirect to={`/${navigator.language}/guidedtour/landing`} />;
	}
};

export default LegacyComponent;
