import { connect } from 'react-redux';
import { IAppState } from 'types/appState.type';
import { PopupComponent, IPopupProps } from './PopupComponent';
import { setShowCallToAction, setIsLanding } from 'actions/project.action';
import { setTour } from 'actions/tour.action';
import { setFullscreenMode, setHideButtonUIMode } from 'actions/buttons.action';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state: IAppState) => ({ ...state });

const mapDispatchToProps = (dispatch: any): IPopupProps => {
	return {
		setShowCallToAction: (showCallToAction: boolean) => {
			dispatch(setShowCallToAction(showCallToAction));
		},
		clearTour: () => {
			dispatch(setTour(''));
		},
		setIsLanding: (isLanding: boolean) => {
			dispatch(setIsLanding(isLanding));
		},
		setFullscreenMode: (fullscreenMode: boolean) => {
			dispatch(setFullscreenMode(fullscreenMode));
		},
		setHideButtonUIMode: (hideButtonUI: boolean) => {
			dispatch(setHideButtonUIMode(hideButtonUI));
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PopupComponent)
);
