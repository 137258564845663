import React, { useState, useEffect } from 'react';
import StepNavigatorContainer from 'components/shortComponents/stepNavigator/StepNavigatorContainer';
import StepIndicatorContainer from 'components/shortComponents/stepIndicator/StepIndicatorContainer';
import { IAppState } from 'types/appState.type';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import { renderMultilineToParagraphs, getNativeLongFormState } from 'utils/renderHelpers';
import styles from './TaskContent.module.scss';
import { TourStep } from 'types/schema.type';

export const TaskContent = (props: IAppState & { chevronClass?: string }) => {
	const [open, setOpen] = useState(false);
	const { stepState, tourState, sectionState, chevronClass } = props;
	const { selectedTourId } = tourState;
	const { selectedSection } = sectionState;

	const selectedStep = Object.assign(new TourStep(), stepState.selectedStep);
	const selectedStepId = selectedStep.stepNumber;

	const isNativeLongForm = getNativeLongFormState(props);

	const namespaceString = isNativeLongForm ? 'tours' : 'scenarios';
	const tourNamespace = `${namespaceString}/${selectedTourId}`;

	const { t } = useTranslation([projectNamespace, tourNamespace]);

	useEffect(() => {
		setOpen(true);
	}, []);	

	const title = t(
		`${namespaceString}/${selectedTourId}:${selectedSection.sectionName}`
	);

	return (
		<div className={`${styles.taskContainer}`}>
			<div className={styles.wrapper}>

				{/* Vertical Bar */}
				<div className={styles.verticalBar}></div>

				{/* Content Container */}
				<div className={styles.contentContainer}>

					{/* Title Container */}
					<div className={styles.titleContainer}>
						<h2 className={`${styles.title}`}>{title}</h2>
						<span className={`${styles.chevron} ${chevronClass}`} />
					</div>

					<div className={`${styles.stepContainer} ${styles.fadeIn}`}>
						{selectedSection.steps.map((step, index) => (
							<div key={index + 1}>
								<div className={`${styles.content} ${open ? styles.contentOpen : styles.contentClosed}
									${ index + 1 === selectedStepId
									? styles.stepSelected
									: index + 1 < selectedStepId
									? styles.stepPrev
									: styles.stepNext
									}`}
								>
									{selectedStep.stepDetails.length > 0 &&
										renderMultilineToParagraphs(
											selectedStep.stepDetails,
											t,
											tourNamespace
										)}
								</div>
								<div
									className={`${styles.content} ${styles.contentHidden} ${
										index + 1 < selectedStepId
											? styles.contentHiddenPrev
											: index + 1 > selectedStepId
											? styles.contentHiddenNext
											: ''
									}`}
								>
									{selectedStep.stepDetails.length > 0 &&
										renderMultilineToParagraphs(
											selectedStep.stepDetails,
											t,
											tourNamespace
										)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			<>
				<StepNavigatorContainer />
				{selectedStep.isDefaultCoordinates() && <StepIndicatorContainer />}
			</>
		</div>
	);
};
