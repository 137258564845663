import React from 'react';
import { RectangleEdge, TeachingBubbleBase } from '@fluentui/react';

export var COACHMARK_PROXIMITY_OFFSET = 60;
export var TEACHINGBUBBLE_PROXIMITY_OFFSET = 80;

export interface ICoachmarkProps  {
	beakDirection: RectangleEdge;
	getTargetButton: Function;
	renderState: boolean;
}

export interface IMouseProximityState {
	currentProximityState: boolean;
	lastProximityState: boolean;
	coachmarkDotRef: React.RefObject<HTMLAnchorElement>;
	teachingBubbleDivRef: React.RefObject<TeachingBubbleBase>;
	targetElementRect?: DOMRect;
	mouseProximityOffset: number;
	timeoutIds: any[];
	mouseMovementFunc: any;
}

export const initProximityState: IMouseProximityState = {
    currentProximityState: false,
    lastProximityState: false,
    coachmarkDotRef: React.createRef<HTMLAnchorElement>(),
    teachingBubbleDivRef: React.createRef<TeachingBubbleBase>(),
    targetElementRect: undefined,
    mouseProximityOffset: 30,
    timeoutIds: [],
    mouseMovementFunc: null,
};

export interface ICoachmarkState {
	showBubbleClick: boolean;
	showBubbleHover: boolean;
    nextNavButton: React.RefObject<HTMLButtonElement>;
    prevNavButton: React.RefObject<HTMLButtonElement>;
    mouseProximityState: IMouseProximityState;
}

