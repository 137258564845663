import { createAction } from 'typesafe-actions';
import { IFocusElement } from 'types/project.type';
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DataClient } from 'utils/dataClient';

export const loadProjectStart = createAction('loadProject/start');

export const loadProjectFail = createAction(
  'loadProject/Fail',
  (resolve: any) => {
    return (error: any) => {
      return resolve({ error });
    };
  }
);

export const loadProjectSuccess = createAction(
  'loadProjectData/Success',
  (resolve: any) => {
    // parse results
		return (results: any, isNativeLongForm: boolean) =>
			resolve({
				results: JSON.parse(results),
				isNativeLongForm: isNativeLongForm,
			});
  }
);

export const loadProject: ActionCreator<
	ThunkAction<Promise<any>, any, boolean, any>
> = (isNativeLongForm: boolean) => {
  return async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      // API call
      const client = new DataClient();
      const result: any = await client.getFile('project', 'project');
        dispatch(loadProjectStart());
        return dispatch(loadProjectSuccess(result, isNativeLongForm));
    } catch (e) {
      return dispatch(loadProjectFail(e));
    }
  };
};

export const setShowCallToAction = createAction(
  'setShowCallToAction',
  (action) => {
    return (showCallToAction: boolean) => action({ showCallToAction });
  }
);

export const setShowProactiveChat = createAction(
	'setShowProactiveChat',
	(action) => {
		return (showProactiveChat: boolean) => action({ showProactiveChat });
	}
);

export const setFocusElementAction = createAction(
  'setFocusElementAction',
  (action) => {
    return (focusElement: IFocusElement) => action({ focusElement });
  }
);

export const setHeaderMode = createAction('setHeaderModeAction', (action) => {
  return (switchToDarkMode: boolean) => action({ switchToDarkMode });
});

export const setIsLanding = createAction('setIsLanding', (action) => {
  return (isLanding: boolean) => action({ isLanding });
});
