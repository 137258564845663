import React, { useEffect, useRef, useState } from 'react';
import StepIndicatorContainer from '../stepIndicator/StepIndicatorContainer';
import { IAppState } from 'types/appState.type';
import { Image } from 'types/schema.type';

import styles from './ImageComponent.module.scss';

export interface IImageProps {
  image: Image;
  hideDot: boolean;
  onError?: () => void; // Optional onError callback
}

export interface IImageWithDispatch extends IImageProps, IAppState {}

const defaultOnError = () => {
  console.error('Image failed to load.');
};

export const ImageComponent: React.FC<IImageWithDispatch> = React.memo((props) => {
  const { stepState, image, hideDot, onError = defaultOnError } = props;
  const { selectedStep } = stepState;

  const imgRef = useRef<HTMLImageElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>('horizontal');

  useEffect(() => {
    const img = imgRef.current;

    const handleLoad = () => {
      if (img) {
        if (img.naturalWidth > img.naturalHeight) {
          setOrientation('horizontal');
        } else {
          setOrientation('vertical');
        }
        setIsLoaded(true);
      }
    };

    if (img) {
      img.addEventListener('load', handleLoad);
      // Check if the image is already loaded (for cached images)
      if (img.complete) {
        handleLoad();
      }
    }

    // Cleanup event listener on unmount or image change
    return () => {
      if (img) {
        img.removeEventListener('load', handleLoad);
      }
      setIsLoaded(false); // Reset load state when image path changes
    };
  }, [image.path]);

  const showIndicator = !hideDot && !selectedStep.isDefaultCoordinates();

  return (
    <div className={`${styles.imageContainer} ${isLoaded ? styles[orientation] : ''}`}>
      <div className={styles.imageFrame}>
        <img
          ref={imgRef}
          className={styles.image}
          alt={image.alt}
          src={image.path}
          onError={onError}
          style={{ display: isLoaded ? 'block' : 'none' }}
        />
        {!isLoaded && <div className={styles.placeholder}>Loading...</div>}
      </div>
      {showIndicator && <StepIndicatorContainer />}
    </div>
  );
});

ImageComponent.defaultProps = {
  onError: defaultOnError,
};
