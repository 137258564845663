import React from 'react';
//import { css, StyleSheet } from 'aphrodite/no-important';
import { IAppState } from 'types/appState.type';
import { Icon, DefaultButton, getTheme } from '@fluentui/react';
import generalStyles from 'styles/generalStyles.module.scss';
import { DarkButtonStyling } from '../../../globalStyling'
import { globalPaths } from 'utils/dataClient';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';

import styles from './TourEndStory.module.scss';

//const styles = StyleSheet.create({
//    endDemoStoryContainer: {
//        backgroundColor: '#EBEBEB',
//        display: 'flex',
//        flexDirection: 'column',
//        justifyContent: 'center',
//        padding: '80px 84px',
//        alignItems: 'stretch',
//    },
//    endDemoStoryTile: {
//        flex: 1,
//        alignSelf: 'center',
//        width: '1280px',
//        display: 'flex',
//        flexDirection: 'row',
//        justifyContent: 'space-between',
//        alignItems: 'stretch',
//        backgroundColor: '#3C3C41',
//        borderRadius: '5px',
//        overflow: 'hidden',
//        color: 'white',
//        height: '430px',
//    },
//    image: {
//        height: '430px',
//        flex: 1,
//    },
//    text: {
//        flex: 1,
//        width: '420px',
//        marginLeft: '94px',
//        paddingRight: '34px',
//        display: 'flex',
//        flexDirection: 'column',
//        justifyContent: 'center',
//        alignItems: 'flex-start',
//    },
//    heading: {
//        fontSize: '24px',
//        lineHeight: '29px',
//        marginTop: '0',
//    },
//    button: {
//        marginTop: '24px',
//    },
//});

export interface ITourEndStory extends IAppState {
	tourId: string;
}

export const TourEndStoryComponent = (props: ITourEndStory) => {
	const { collectionState, localeState } = props;
	const { selectedCollection } = collectionState;
	const { isRTLCulture } = localeState;

	const collectionNamespace = `collections/${selectedCollection!.id}`;
	const { t } = useTranslation([projectNamespace, collectionNamespace]);

	const launchCta = (action: string) => {
		window.open(action, '_blank');
	};

	const tourEnd = selectedCollection!.tourEnd;
	const storyTitle = t(`${collectionNamespace}:${tourEnd.storyCta.title}`);
	const storyCta = t(`${collectionNamespace}:${tourEnd.storyCta.cta}`);

	return (
		<div className={styles.endDemoStoryContainer}>
			<div className={styles.endDemoStoryTile}>
				<img
					className={styles.image}
					src={`${globalPaths.baseUrl}/assets/demoEnd.png`}
					alt={storyTitle}
				/>
				<div className={styles.text}>
					<h2 className={styles.heading}>{storyTitle}</h2>
					<DefaultButton
						id={storyCta}
						data-bi-name={storyCta}
						className={styles.button}
						target="_blank"
						theme={getTheme()}
						onClick={() => {
							launchCta(tourEnd.ctaAction);
						}}
						text={storyCta}
					>
						<Icon
							iconName={isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'}
							className={generalStyles.ChevronStyle}
						></Icon>
					</DefaultButton>
				</div>
			</div>
		</div>
	);
};
