import React, { useRef, useEffect } from 'react';
//import { css, StyleSheet } from 'aphrodite/no-important';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from 'react-router-dom';
import { IAppState } from 'types/appState.type';
import { IFocusElement } from 'types/project.type';
import { GetJsllIdPrefix } from 'utils/jsllTaggingUtils';
import { Tour } from 'types/schema.type';
import { useTranslation } from 'react-i18next';
import { String } from 'typescript-string-operations';
import { projectNamespace } from 'i18n';

import styles from './StepNavigator.module.scss';

export interface IStepNavigatorProps {
	setShowCallToAction(showCallToAction: boolean): void;
	setFocusElementAction(focusElementAction: IFocusElement): void;
}

export interface IStepNavigatorWithProps
	extends IStepNavigatorProps,
		IAppState {}

export const StepNavigator = (props: IStepNavigatorWithProps) => {
	const nextLinkRef: React.MutableRefObject<any> = useRef(null);
	const prevLinkRef: React.MutableRefObject<any> = useRef(null);

	const {
		projectState,
		localeState,
		collectionState,
		tourState,
		stepState,
		sectionState,
		setShowCallToAction,
		setFocusElementAction,
	} = props;

	const { selectedLocale, isRTLCulture } = localeState;
	const { selectedCollection } = collectionState;
	const { project, focusElement } = projectState;
	const { selectedSection } = sectionState;
	const { selectedStep } = stepState;
	const selectedSectionId = selectedSection.sectionNumber;
	const selectedStepId = selectedStep.stepNumber;
	const { selectedTourId } = tourState;
	const { t } = useTranslation([
		projectNamespace,
		`scenarios/${selectedTourId}`,
	]);

	// Determine if the StepIndicator is in default position
	const isDefaultPosition =
		(selectedStep.coachmarkCoordinates[0] === 0 &&
			selectedStep.coachmarkCoordinates[1] === 0) ||
		(selectedStep.coachmarkCoordinates[0] === -1 &&
			selectedStep.coachmarkCoordinates[1] === -1);

	useEffect(() => {
				if (nextLinkRef.current) {
					if (isDefaultPosition) {
						nextLinkRef.current.classList.add(styles.blinkingDot); // Apply blinkingDot class
					} else {
						nextLinkRef.current.classList.remove(styles.blinkingDot); // Remove blinkingDot class
					}
				}
			}, [isDefaultPosition]); // Dependency on isDefaultPosition
			

	if (selectedStepId < 1 || selectedSectionId < 1) {
		return <></>;
	}

	const selectedTour = tourState.selectedTour
		? tourState.selectedTour
		: new Tour();

	const totalSections = selectedTour.sections.length;
	const totalSteps = selectedSection.steps.length;

	const nextSectionId = selectedSectionId + 1;
	const prevSectionId = selectedSectionId - 1;
	const nextStepId = selectedStepId + 1;
	const prevStepId = selectedStepId - 1;

	const sections = selectedTour.sections;
	const prevSection = sections[prevSectionId - 1];
	const nextSection = sections[nextSectionId - 1];
	const currentSection = sections[selectedSectionId - 1];

	let prevSectionLastStepId = 1;
	if (selectedStepId === 1 && selectedSectionId > 1) {
		prevSectionLastStepId = prevSection.steps.length;
	}

	const isLastStep = selectedStepId >= totalSteps;
	const isLastSection = selectedSectionId >= totalSections;

	const isFirstStep = selectedStepId === 1;

	const isNextStepDotInStep = !(isLastSection && isLastStep)
		? isLastStep
			? nextSection.steps[0].isDefaultCoordinates()
			: currentSection.steps[nextStepId - 1].isDefaultCoordinates()
		: false;

	function setFocus(focusElement: IFocusElement) {
		if (isLastStep && isLastSection) {
			setFocusElementAction(IFocusElement.None);
			return;
		}
		if (focusElement === IFocusElement.Next && isNextStepDotInStep) {
			setFocusElementAction(IFocusElement.Bubble);
			return;
		}

		setFocusElementAction(focusElement);
	}

	const prevLinkSectionId = isFirstStep ? prevSectionId : selectedSectionId;
	const prevLinkStepId = isFirstStep ? prevSectionLastStepId : prevStepId;

	const jsllIdPrefix = GetJsllIdPrefix(props, t);
	const prevLinkId = `${jsllIdPrefix}-prevNav`;
	const prevLink =
		prevSectionId >= 1 || selectedStepId !== 1 ? (
			<Link
				id={prevLinkId}
				data-bi-name={prevLinkId}
				ref={prevLinkRef}
				aria-label={project.stepNavLabelPrev}
				to={`/${selectedLocale}/guidedtour/${
					selectedCollection!.id
				}/${selectedTourId}/${prevLinkSectionId}/${prevLinkStepId}`}
				className={`${styles.link} ${styles.prevButton}`}
				onClick={() => {
					setFocus(IFocusElement.Prev);
				}}
			>
				<Icon
					iconName={isRTLCulture ? 'ChevronRight' : 'ChevronLeft'}
					styles={{ root: { fontSize: 18 } }}
				/>
			</Link>
		) : (
			<div className={`${styles.empty}`} />
		);

	const nextLinkSectionId = isLastStep ? nextSectionId : selectedSectionId;
	const nextLinkStepId = isLastStep ? 1 : nextStepId;

	const nextLinkClassName = `${styles.link} ${
		isDefaultPosition ? styles.blinkingDot : styles.nextButton
	}`;		  

	const nextLinkId = `${jsllIdPrefix}-nextNav`;
	let nextLink = !(isLastSection && isLastStep) ? (
		<Link
			id={nextLinkId}
			data-bi-name={nextLinkId}
			ref={nextLinkRef}
			aria-label={t(project.stepNavLabelNext)}
			to={`/${selectedLocale}/guidedtour/${
				selectedCollection!.id
			}/${selectedTourId}/${nextLinkSectionId}/${nextLinkStepId}`}
			className={nextLinkClassName}
			onClick={() => {
				setFocus(IFocusElement.Next);
			}}
		>
		{/* Render the chevron only if not in default position */}
		{!isDefaultPosition && (
			<Icon
				iconName={isRTLCulture ? 'ChevronLeft' : 'ChevronRight'}
				styles={{ root: { fontSize: 18 } }}
			/>
		)}
		</Link>
	) : (
		<div
			className={nextLinkClassName}
			onClick={() => {
				setShowCallToAction(true);
			}}
		>
		{/* Render the chevron only if not in the default position */}
		{!isDefaultPosition && (
			<Icon
				iconName={isRTLCulture ? 'ChevronLeft' : 'ChevronRight'}
				styles={{ root: { fontSize: 18 } }}
			/>
		)}
		</div>
	);

	const pagination = (
		<div className={`${styles.link} ${styles.pagination}`}>
			{String.Format(t(project.stepProgress), selectedStepId, totalSteps)}
		</div>
	);

	setTimeout(() => {
		if (focusElement === IFocusElement.Prev && prevLinkRef.current) {
			prevLinkRef.current.focus();
		}
		if (focusElement === IFocusElement.Next && nextLinkRef.current) {
			nextLinkRef.current.focus();
		}
	}, 1);

	return (
		<div>
			<div className={`${styles.linkRow}`}>
				<div>{prevLink}</div>
				{pagination}
				<div>{nextLink}</div>
			</div>
		</div>
	);
};
