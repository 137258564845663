import { getType } from 'typesafe-actions';
import { IButtonsState, initButtonsState } from 'types/buttons.type';
import {
    setFullscreenMode,
    setHiddenButtonMode,
    setCoachmarkClickedMode,
	setHideButtonUIMode,
} from 'actions/buttons.action';

export default (
	state: IButtonsState = initButtonsState,
	action: any
): IButtonsState => {
  switch (action.type) {
		case getType(setFullscreenMode): {
              let buttonState = state.currentState;
              buttonState.fullscreenMode = action.payload.fullscreenModeState;

              //Never allow Demo-Mode to persist outside of fullscreen mode.
              if (!buttonState.fullscreenMode) {
                  buttonState.hiddenButtonMode = false;
              }

              return {
                  ...state,
                  currentState: buttonState,
              };
          }
		case getType(setHiddenButtonMode): {
              let buttonState = state.currentState;
              buttonState.hiddenButtonMode = action.payload.hiddenModeState;
              return {
                  ...state,
                  currentState: buttonState,
              };
          }
		case getType(setCoachmarkClickedMode): {
              let buttonState = state.currentState;
              buttonState.coachmarkClicked = action.payload.coachmarkClicked;
              return {
                  ...state,
                  currentState: buttonState,
              };
          }
		case getType(setHideButtonUIMode): {
              let buttonState = state.currentState;
              buttonState.hideButtonUI = action.payload.hideButtonUI;
              return {
                  ...state,
                  currentState: buttonState,
              };
          }
  }
  return state;
};
