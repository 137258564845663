import { Project } from './schema.type';
import { IAppState, IPropsWithChildren } from './appState.type';
import { ReactNode } from 'react';

export interface IProjectState {
    isLoading: boolean;
    isLanding: boolean;
    projectLoadFailed: boolean;
    projectLoaded: boolean;
    project: Project;
    errorMessage: string;
    showCallToAction: boolean;
	showProactiveChat: boolean;
    focusElement: IFocusElement;
    showOverlay: boolean;
    overlayContent: ReactNode;
    darkMode: boolean;
    useNativeLongFormCollection: boolean;
}

export enum IFocusElement {
    None,
    Prev,
    Next,
    Bubble,
}

export const initProjectState: IProjectState = {
    isLoading: false,
    isLanding: false,
    projectLoadFailed: false,
    projectLoaded: false,
    project: new Project(),
    errorMessage: '',
    showCallToAction: false,
	showProactiveChat: false,
    showOverlay: false,
    focusElement: IFocusElement.None,
    overlayContent: null,
    darkMode: false,
    useNativeLongFormCollection: false,
};

export interface IProjectProps {
    locale?: string;
    isNativeLongForm?: boolean;
}

export interface IProjectStateProps extends IAppState { }

export interface IProjectDispatchProps {
    loadProject(projectTypeLongForm: boolean): void;
}

export interface IProjectPropsWithDispatch
    extends IPropsWithChildren,
    IProjectProps,
    IProjectStateProps,
    IProjectDispatchProps { }
