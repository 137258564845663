import { connect } from 'react-redux';
import { IAppState } from 'types/appState.type';
import { Dispatch } from 'react';
import { withRouter } from 'react-router-dom';
import { CookieConsentBanner } from './CookieConsentBanner';
import {
	ICookieBannerDispatchProps,
	IConsentValues,
	ICookieBannerProps,
	ICookieBannerStateProps,
} from 'types/cookieBanner.type';
import { setConsentValues } from 'actions/cookieBanner.action';

const mapStateToProps = (
	state: IAppState,
	props: ICookieBannerProps
): ICookieBannerStateProps => {
	return {
		...state,
		...props,
	};
};

const mapDispatchToProps = (
	dispatch: Dispatch<any>
): ICookieBannerDispatchProps => {
	return {
		setConsentValues: (consentValues: IConsentValues) => {
			dispatch(setConsentValues(consentValues));
		},
	};
};

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(CookieConsentBanner)
);
