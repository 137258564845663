import { defaultLocale } from 'index';
import React from 'react';
import { IProjectPropsWithDispatch } from 'types/project.type';

export const ProjectComponent = (props: IProjectPropsWithDispatch) => {
	const { projectState, match } = props;
	const { projectLoaded, isLoading, projectLoadFailed } = projectState;
	let selectedLocale = props.locale || defaultLocale;
	let projectTypeLongForm = props.isNativeLongForm || false;

	if (match && match.params && match.params.selectedLocale) {
		selectedLocale = match.params.selectedLocale.toLowerCase();
	}

	if (isLoading) {
		return <></>;
	}
	if (projectLoadFailed) {
		return <>Error loading project</>;
	}
	if (!projectLoaded) {
		props.loadProject(projectTypeLongForm);
		return <></>;
	}

	return <></>;
};
