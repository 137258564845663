import { IButtonStyles } from '@fluentui/react';

export const BaseButtonStyling: Partial<IButtonStyles> = {
	root: {
		borderRadius: '10px',
		minHeight: '44px',
    color: 'white',
    background: '#0a1b46',
	},
	rootFocused: {
		outline: 'solid rgb(41, 128, 196) 3px !important',
	},
	labelHovered: {
		textDecoration: 'none',
	},
	label: {
		fontSize: '16px',
	},
};
export const PrimaryButtonStyling: Partial<IButtonStyles> = {
	...BaseButtonStyling,
	root: {
		borderRadius: '10px',
		minHeight: '44px',
    color: 'white',
		backgroundColor: '#0a1b46',
	},
	rootHovered: {
		color: 'white !important',
		backgroundColor: 'black !important',
		textDecoration: 'none !important',
	},
};
export const DarkButtonStyling: Partial<IButtonStyles> = {
	...BaseButtonStyling,
	root: {
		borderRadius: '2px',
		border: 'solid white 1px !important',
		color: 'white !important',
		minHeight: '44px',
		backgroundColor: '#3C3C41',
	},
	rootFocused: {
		outline: 'solid rgb(41, 128, 196) 3px !important',
	},
	rootHovered: {
		color: 'white !important',
		backgroundColor: '#3C3C41 !important',
		textDecoration: 'none !important',
	},
};
export const LandingButtonStyling: Partial<IButtonStyles> = {
	...PrimaryButtonStyling,
	root: {
		borderRadius: '10px',
		minHeight: '44px',
    color: 'white',
		backgroundColor: '#0a1b46',
	},
	rootHovered: {
		color: 'white !important',
		backgroundColor: 'black !important',
		textDecoration: 'none !important',
	},

	label: {
		fontSize: '14px !important',
	},
};
export const customLandingButtonStyles: Partial<IButtonStyles> = {
	...LandingButtonStyling,
	rootPressed: {
	  backgroundColor: '#0a1b46', // Customize the background color for the active state
	  // Add other styles for the active state as needed
	},
	rootDisabled: {
	  backgroundColor: 'white', // Customize the background color for the disabled state
	  // Add other styles for the disabled state as needed
	},
	rootHovered: {
		backgroundColor: '#1b56ec',
		textDecoration: 'none !important',
	  },
  };
