import { connect } from 'react-redux';
import { Coachmark, ICoachmarkDispatch } from './Coachmark';
import { IAppState } from 'types/appState.type';
import { TourSection, TourStep } from 'types/schema.type';
import { setTour } from 'actions/tour.action';
import { setSection } from 'actions/section.action';
import { setStep } from 'actions/step.action';
import {
	setCoachmarkClickedMode,
	setHideButtonUIMode,
} from 'actions/buttons.action';

const mapStateToProps = (state: IAppState): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any): ICoachmarkDispatch => {
	return {
		setTour: (selectedTour: string) => {
			dispatch(setTour(selectedTour));
		},
		setSection: (newSection: TourSection) => {
			dispatch(setSection(newSection));
		},
		setStep: (selectedStep: TourStep) => {
			dispatch(setStep(selectedStep));
		},
		setCoachmarkClicked: (coachmarkClicked: boolean) => {
			dispatch(setCoachmarkClickedMode(coachmarkClicked));
		},
		setHideButtonUIMode: (hideButtonUI: boolean) => {
			dispatch(setHideButtonUIMode(hideButtonUI));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Coachmark);
