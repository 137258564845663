import React, { useState, useEffect, useCallback } from 'react';
import { IAppState } from 'types/appState.type';
import { Collection, Tour, TourStep, TourSection } from 'types/schema.type';
import { GetJsllIdPrefix } from 'utils/jsllTaggingUtils';
import { useTranslation } from 'react-i18next';
import { projectNamespace } from 'i18n';
import { globalPaths } from 'utils/dataClient';
import CoachmarkContainer from 'components/coachmark/CoachmarkContainer';
import { ITourNavigation } from 'types/tour.type';
import { appInsights } from 'store/appStore';
import {
	getCurrentStepBeakDirection,
	bulkLoadImagesForTour,
} from 'utils/renderHelpers';

import styles from './RightPanel.module.scss';

export interface IRightPanelDispatch {
	setTour(selectedTour: string): void;
	setTourImagesLoaded(selectedTour: string): void;
	setStep(selectedStep: TourStep): void;
	setSection(selectedSection: TourSection): void;
	setHideButtonUIMode(hideButtonUI: boolean): void;
}

export interface IRightPanelProps
	extends IRightPanelDispatch,
		ITourNavigation,
		IAppState {}

export const RightPanel = (props: IRightPanelProps) => {
	const {
		collectionState,
		tourState,
		sectionState,
		stepState,
		buttonsState,
		updateHistoryForNavigationState,
		incrementStep,
		decrementStep,
	} = props;

	const {
		fullscreenMode,
		hiddenButtonMode,
		imageButton,
		imageRef,
		markerButton,
		nextButton,
	} = buttonsState.currentState;
	const { selectedSection } = sectionState;
	const { selectedStep } = stepState;
	const [firstRender, setFirstRender] = useState(false);

	const selectedCollection = collectionState.selectedCollection
		? collectionState.selectedCollection
		: new Collection();

	const selectedTour = tourState.selectedTour
		? tourState.selectedTour
		: new Tour();

	const selectedTourId = selectedTour.id;

	const collectionNamespace = `collections/${selectedCollection.id}`;

	let nameSpaces = [projectNamespace, collectionNamespace];

	let tourNamespace = '';
	if (selectedTour && selectedTour.isNativeLongForm) {
		tourNamespace = `tours/${selectedTourId}`;
		nameSpaces.push(tourNamespace);
	} else {
		tourNamespace = `scenarios/${selectedTourId}`;
		nameSpaces.push(tourNamespace);
	}

	const { t } = useTranslation(nameSpaces);
	const jsllIdPrefix = GetJsllIdPrefix(props, t);

	const tourId = selectedTour.id;

	const getImageSource = (): string => {
		if (selectedTour.isNativeLongForm) {
			return `${globalPaths.baseUrl}/tours/${tourId
				.toLowerCase()
				.replace(/\s+/g, '-')}/${selectedSection.sectionNumber}_${
				selectedStep.stepNumber
			}.PNG`;
		} else {
			return `${globalPaths.baseUrl}/scenarios/${tourId
				.toLowerCase()
				.replace(/\s+/g, '-')}/${selectedSection.sectionNumber}.${
				selectedStep.stepNumber
			}.png`;
		}
	};

	useEffect(() => {
		setFirstRender(true);
	}, []);

	const handleKeyPress = useCallback(
		(ev: KeyboardEvent): any => {
			//If left arrow/ A key - decrement step.
			if (ev.keyCode == 37 || ev.keyCode == 65) {
				props.decrementStep();
			} //Otherwise, if right arrow/ D key - increment step
			else if (ev.keyCode == 39 || ev.keyCode == 68) {
				props.incrementStep();
			}
		},
		[props]
	); //Method changes if underlying loc changes due to button state differences. (not checking loc directly since it changes on each step/section).

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress, false);
		return () => {
			document.removeEventListener('keydown', handleKeyPress, false);
		};
	}, [handleKeyPress]); //Ensure we change listener if method changes.

	const getPositionContainerStyle = (): any => {

        //Use custom Next coords for VA tour when fullscreen.
		if (selectedTourId == "virtual-appointments" && fullscreenMode && hasNextButtonCoords()) {
			return {
				left: '100%',
                top: '98.5%',
			};
		}
        else if (hasNextButtonCoords() && fullscreenMode) { //Adjust nextButton coords for FullScreen.
			return {
				left: '0%',
				top: '3%',
			};
		} else {
			//otherwise - use normal coords.
			return {
				// Convert JSON values to percentages for positioning
				left: selectedStep.coachmarkCoordinates[0] + '%',
				top: selectedStep.coachmarkCoordinates[1] + '%',
			};
		}
	};

	const hasNextButtonCoords = () => {
		var coords = selectedStep.coachmarkCoordinates;
		if (coords[0] === -1 && coords[1] === -1) {
			return true;
		}

		return false;
	};

	const getTargetButton = () => {
		if (hasNextButtonCoords() && !fullscreenMode) {
			if (nextButton.current) return nextButton.current;

			return undefined;
		}

		if (markerButton.current) {
			return markerButton.current;
		}

		return undefined;
	};

	const hiddenButtonClick = (e: any) => {
		if (hiddenButtonMode) {
			appInsights.trackEvent({
				name: 'DemoModeClick',
			});
			props.incrementStep();
		}

		e.preventDefault();
	};

	const chooseAnchorClickHandler = (e: any) => {
		if (hiddenButtonMode) {
			hiddenButtonClick(e);
		} else {
			preventDefaultAnchorClick(e);
		}
	};

	const preventDefaultAnchorClick = (e: any) => {
		e.preventDefault();
	};

	const chooseAnchorClassname = () => {
		if (hiddenButtonMode) {
			return styles.clickableAnchor;
		}

		return styles.hiddenAnchor;
	};

	//Force an initial render so Coachmark can be positioned.
	if (firstRender) {
		setFirstRender(false);
	}

	setTimeout(() => {
		//If we haven't bulk downloaded tour images, do so and then set flag on tour.
		if (!selectedTour.imagesLoaded) {
			if (bulkLoadImagesForTour(props, tourId)) {
				props.setTourImagesLoaded(selectedTour.id);
			}
		}
	}, 1);

	return (
		<>
			<div
				className={`${styles.rightPane} ${
					fullscreenMode ? styles.fullScreenImageWrapper : ''
				}`}
			>
				<div
					className={styles.stepImageWrapper}
					onMouseEnter={() => {
						if (fullscreenMode) {
							props.setHideButtonUIMode(false);
						}
					}}
				>
					<a
						id={jsllIdPrefix + '-demoMode-imageClick'}
						href="/"
						onClick={(e) => chooseAnchorClickHandler(e)}
						ref={imageButton}
						data-bi-name={'DemoModeClick'}
						className={chooseAnchorClassname()}
					>
						<img
							src={getImageSource()}
							alt={t(`${tourNamespace}:${selectedSection.sectionName}`)}
							className={`${styles.stepImage} ${
								fullscreenMode
									? styles.fullScreenImage
									: styles.standardScreenImage
							} `}
							ref={imageRef}
							onMouseEnter={() => {
								if (fullscreenMode) {
									props.setHideButtonUIMode(true);
								}
							}}
						/>
					</a>

					{/*Hidden by transparency, just used for coachmark positioning/target*/}
					<div
						className={styles.buttonContainer}
						id="markerButton"
						ref={markerButton}
						style={getPositionContainerStyle()}
					></div>

					{/*Coachmark & Teaching Bubble for current step*/}
					<CoachmarkContainer
						renderState={firstRender}
						getTargetButton={getTargetButton}
						beakDirection={getCurrentStepBeakDirection(props)}
						incrementStep={incrementStep}
						decrementStep={decrementStep}
						updateHistoryForNavigationState={updateHistoryForNavigationState}
					/>
				</div>
			</div>
		</>
	);
};
