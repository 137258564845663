import React, { useEffect } from 'react';
import { ITourLoaderStateWithDispatch } from 'types/tour.type';
import { IsTourLoadedFor } from 'utils/tourUtil';

export const TourLoadingComponent = (props: ITourLoaderStateWithDispatch) => {
	const { tourState, localeHexCode, isNativeLongForm } = props;
	const { tours, loadingTours, isLoading, loadingError } = tourState;

	useEffect(() => {
		if (!isLoading && !loadingError) {
			const tourLoaded = IsTourLoadedFor(props.tourId, tours);

			if (!tourLoaded) {
				props.loadTour(props.tourId, isNativeLongForm, localeHexCode);
			}

			if (tourLoaded && props.set) {
				props.setTour(props.tourId);
			}
		}
	}, [props, isLoading, loadingError, tours, isNativeLongForm, localeHexCode]);

	if (isLoading || loadingTours.indexOf(props.tourId) >= 0) {
		return <></>;
	}

	if (loadingError) {
		return <>error loading tour</>;
	}

	return <></>;
};
