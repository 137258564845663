import { ConsentValues } from './schema.type';
import { IAppState, IPropsWithChildren } from './appState.type';

export interface IConsentValues {
  Required: Boolean;
  Analytics: Boolean;
  SocialMedia: Boolean;
  Advertising: Boolean;
  isConsentRequired : Boolean;
}

export interface ICookieBannerState {
  consentValues: IConsentValues;
}

export const initStepState: ICookieBannerState = {
	consentValues: new ConsentValues(),
};

export interface ICookieBannerStateProps extends IAppState { }

export interface ICookieBannerProps {
    locale: string;
}

export interface ICookieBannerDispatchProps {
    setConsentValues(consentValues: IConsentValues): void;
}


export interface ICookieBannerStateWithDispatch
    extends IPropsWithChildren,
    ICookieBannerProps,
    ICookieBannerDispatchProps,
    ICookieBannerStateProps { }



