import i18n from 'i18n';
import React, { useEffect } from 'react';
import { ILocaleStateWithDispatch } from 'types/locale.type';

export const LocaleComponent = (props: ILocaleStateWithDispatch) => {
	const { localeState, selectLocale } = props;
	const { selectedLocale } = localeState;

	useEffect(() => {
		// console.log(i18n.language);
		if (!selectedLocale && i18n.language) {
			props.selectLocale(i18n.language);
		}
	}, [props, selectedLocale]);

	if (localeState.isLoading) {
		return <></>;
	}

	if (localeState.loadingError) {
		return <>error loading project locale</>;
	}

	if (!selectedLocale && i18n.language) {
		// console.log('Setting default locale: ', i18n.language);
		//props.selectLocale(i18n.language);
		return <></>;
	}

	return <></>;
};
