import { ContactRailSetting } from "../types/schema.type";


export const ContactRailConfig: Array<ContactRailSetting> = [
	{
	  "Slug": "en-us",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "18552700615",
	  "ChatLeadgen": "dynamics-leadgen-en-us",
	  "ChatMarketImage": "north-america"
	},
  
	{
	  "Slug": "ar-sa",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "da-dk",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "80-60-32-45",
	  "ChatLeadgen": "dynamics-leadgen-da-dk",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "de-ch",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "0800 001090",
	  "ChatLeadgen": "dynamics-leadgen-de-ch",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "de-de",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "08005891597",
	  "ChatLeadgen": "dynamics-leadgen-de-de",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "en-au",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "1-800-234-085",
	  "ChatLeadgen": "dynamics-leadgen-en-au",
	  "ChatMarketImage": "asia-china"
	},
  
	{
	  "Slug": "en-ca",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "18552700615",
	  "ChatLeadgen": "dynamics-leadgen-en-ca",
	  "ChatMarketImage": "north-america"
	},
  
	{
	  "Slug": "en-cy",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "en-gb",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "08000283409",
	  "ChatLeadgen": "dynamics-leadgen-en-gb",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "en-ie",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "en-in",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "000-800-440-2008",
	  "ChatLeadgen": "dynamics-leadgen-en-in",
	  "ChatMarketImage": "asia-china"
	},
  
	{
	  "Slug": "en-sa",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "1-888-477-7989",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "en-sg",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "en-za",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "es-es",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "900812186",
	  "ChatLeadgen": "dynamics-leadgen-es-es",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "es-mx",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "dynamics-leadgen-es-mx",
	  "ChatMarketImage": "latam"
	},
  
	{
	  "Slug": "fi-fi",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "de-at",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "fr-be",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "fr-ca",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "18552700615",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "fr-ch",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "0800 001090",
	  "ChatLeadgen": "dynamics-leadgen-fr-ch",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "fr-fr",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "0157324218",
	  "ChatLeadgen": "dynamics-leadgen-fr-fr",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "he-il",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "it-it",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "800915993",
	  "ChatLeadgen": "dynamics-leadgen-it-it",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "ja-jp",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "0120-167-400",
	  "ChatLeadgen": "dynamics-leadgen-ja-jp",
	  "ChatMarketImage": "asia-china"
	},
  
	{
	  "Slug": "ko-kr",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "nb-no",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "nl-be",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "nl-nl",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "08000203536",
	  "ChatLeadgen": "dynamics-leadgen-nl-nl",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "pl-pl",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "pt-br",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "dynamics-leadgen-pt-br",
	  "ChatMarketImage": "latam"
	},
  
	{
	  "Slug": "pt-pt",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "ru-ru",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "7 499 926-99-00",
	  "ChatLeadgen": "dynamics-leadgen-ru-ru",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "sv-se",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "dynamics-leadgen-sv-se",
	  "ChatMarketImage": "emea"
	},
  
	{
	  "Slug": "tr-tr",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "zh-tw",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "0800-1128-000",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "en-nz",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "0508 526 917",
	  "ChatLeadgen": "dynamics-leadgen-en-nz",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "zh-cn",
	  "IsChatModeEnabled": true,
	  "IsCallModeEnabled": true,
	  "IsRequestModeEnabled": false,
	  "IsFreeTrialModeEnabled": true,
	  "PhoneNo": "400-8866134",
	  "ChatLeadgen": "dynamics-leadgen-zh-cn",
	  "ChatMarketImage": "asia-china"
	},
  
	{
	  "Slug": "en-my",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	},
  
	{
	  "Slug": "xx-yy",
	  "IsChatModeEnabled": false,
	  "IsCallModeEnabled": false,
	  "IsRequestModeEnabled": true,
	  "IsFreeTrialModeEnabled": false,
	  "PhoneNo": "",
	  "ChatLeadgen": "",
	  "ChatMarketImage": ""
	}
  ];
