import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import projectReducer from './projectReducer';
import tourReducer from './tourReducer';
import sectionReducer from './sectionReducer';
import stepReducer from './stepReducer';
import cookieBannerReducer from './cookieBannerReducer';
import localeReducer from './localeReducer';
import collectionReducer from './collectionReducer';
import buttonStateReducer from './buttonsReducer';

const createAppReducers = (history: History<any>): any => {
    return combineReducers({
        router: connectRouter(history),
        localeState: localeReducer,
        projectState: projectReducer,
        collectionState: collectionReducer,
        tourState: tourReducer,
        sectionState: sectionReducer,
        stepState: stepReducer,
        cookieBannerState: cookieBannerReducer,
        buttonsState: buttonStateReducer,
    });
};

export default createAppReducers;
