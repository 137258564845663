import { getType } from 'typesafe-actions';
import { ProjectDto } from 'types/schema.type';
import { IProjectState, initProjectState } from 'types/project.type';
import {
    setShowCallToAction,
    setFocusElementAction,
    loadProjectStart,
    loadProjectFail,
    setHeaderMode,
    setIsLanding,
    setShowProactiveChat,
} from 'actions/project.action';
import { loadProjectSuccess } from 'actions/project.action';
import { buildProject } from 'utils/projectUtil';


export default (
  state: IProjectState = initProjectState,
  action: any
): IProjectState => {
  switch (action.type) {
    case getType(loadProjectStart):
      return {
        ...state,
        projectLoaded: false,
        projectLoadFailed: false,
        isLoading: true,
      };
    case getType(loadProjectFail):
      return {
        ...state,
        isLoading: false,
        projectLoaded: false,
        projectLoadFailed: true,
        errorMessage: 'failed to load project',
      };
    case getType(loadProjectSuccess):
      return {
        ...state,
        isLoading: false,
        projectLoaded: true,
        project: buildProject(action.payload.results as ProjectDto),
        errorMessage: '',
      };
    case getType(setShowCallToAction):
      return {
        ...state,
        isLoading: false,
        showCallToAction: action.payload.showCallToAction,
        errorMessage: '',
      };
		case getType(setShowProactiveChat):
			return {
				...state,
				isLoading: false,
				showProactiveChat: action.payload.showProactiveChat,
				errorMessage: '',
			};
    case getType(setIsLanding):
      return {
        ...state,
        isLanding: action.payload.isLanding,
        errorMessage: '',
      };
    case getType(setFocusElementAction):
      return {
        ...state,
        focusElement: action.payload.focusElement,
      };
    case getType(setHeaderMode):
      return {
        ...state,
        darkMode: action.payload.switchToDarkMode,
      };
  }
  return state;
};
