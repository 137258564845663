import { connect } from 'react-redux';
import { RightPanel, IRightPanelDispatch } from './RightPanel';
import { IAppState } from 'types/appState.type';
import { TourSection, TourStep } from 'types/schema.type';
import { setSection } from 'actions/section.action';
import { setStep } from 'actions/step.action';
import { setHideButtonUIMode } from 'actions/buttons.action';
import { setTour, setTourImagesLoaded } from 'actions/tour.action';

const mapStateToProps = (state: IAppState): IAppState => {
	return { ...state };
};

const mapDispatchToProps = (dispatch: any): IRightPanelDispatch => {
	return {
		setSection: (newSection: TourSection) => {
			dispatch(setSection(newSection));
		},
		setStep: (selectedStep: TourStep) => {
			dispatch(setStep(selectedStep));
		},
		setTour: (selectedTour: string) => {
			dispatch(setTour(selectedTour));
		},
		setTourImagesLoaded: (selectedTour: string) => {
			dispatch(setTourImagesLoaded(selectedTour));
		},
		setHideButtonUIMode: (hideButtonUI: boolean) => {
			dispatch(setHideButtonUIMode(hideButtonUI));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
